const TAB_NAME = 'Informasi Perusahaan';

const COMPANY_NAME_INPUT_LABEL = 'Nama Perusahaan';
const ALIAS_INPUT_LABEL = 'Alias / Inisial';
const COMPANY_ADDRESS_INPUT_LABEL = 'Alamat Perusahaan';
const BILLING_ADDRESS_INPUT_LABEL = 'Alamat Penagihan';
const SAME_AS_COMPANY_ADDRESS_CHECKBOX_LABEL = 'sama dengan alamat perusahaan';
const NPWP_INPUT_LABEL = 'No NPWP';
const NPWP_ADDRESS_INPUT_LABEL = 'Alamat NPWP';
const PKP_NO_INPUT_LABEL = 'No PKP';
const COMPANY_PRESIDENT_NAME_INPUT_LABEL = 'Nama Pimpinan';
const POSITION_INPUT_LABEL = 'Jabatan';
const SIUP_NO_INPUT_LABEL = 'No SIUP';
const TDP_NO_INPUT_LABEL = 'NO TDP';
const COMPANY_EMAIL_INPUT_LABEL = 'Email Perusahaan';
const COMPANY_PHONE_NO_INPUT_LABEL = 'No. Telp Perusahaan';
const OFFICE_INPUT_LABEL = 'Kantor';
const FAX_INPUT_LABEL = 'Fax';
const COMPANY_SERVICE_INPUT_LABEL = 'Produk / Jasa Perusahaan';
const COMPANY_ESTABLISHED_YEAR_INPUT_LABEL = 'Tahun Berdiri Perusahaan';
const COMPANY_ADDITIONAL_INFO_INPUT_LABEL = 'Informasi Tambahan';

const AGREEMENT_LINK_LABEL = 'Persyaratan Perjanjian';

export default {
  TAB_NAME,
  COMPANY_NAME_INPUT_LABEL,
  ALIAS_INPUT_LABEL,
  COMPANY_ADDRESS_INPUT_LABEL,
  BILLING_ADDRESS_INPUT_LABEL,
  SAME_AS_COMPANY_ADDRESS_CHECKBOX_LABEL,
  NPWP_INPUT_LABEL,
  NPWP_ADDRESS_INPUT_LABEL,
  PKP_NO_INPUT_LABEL,
  COMPANY_PRESIDENT_NAME_INPUT_LABEL,
  POSITION_INPUT_LABEL,
  SIUP_NO_INPUT_LABEL,
  TDP_NO_INPUT_LABEL,
  COMPANY_EMAIL_INPUT_LABEL,
  COMPANY_PHONE_NO_INPUT_LABEL,
  OFFICE_INPUT_LABEL,
  FAX_INPUT_LABEL,
  COMPANY_SERVICE_INPUT_LABEL,
  COMPANY_ESTABLISHED_YEAR_INPUT_LABEL,
  COMPANY_ADDITIONAL_INFO_INPUT_LABEL,
  AGREEMENT_LINK_LABEL,
};
