<template>
  <v-dialog
    v-model="isOpen"
    width="800"
  >
    <v-card class="overflow-hidden">
      <v-toolbar-items class="btn-close">
        <v-btn
          icon
          color="black"
          dark
          @click="isOpen = false"
        >
          <v-icon>mdi-close-thick</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-card-text class="justify-center d-flex">
        <v-img
          contain
          max-height="500"
          max-width="750"
          :src="imageSelected"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogViewImage',
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    imageUrl: {
      type: String,
      default: '',
    },
  },
  watch: {
    isOpen(newVal) {
      if (!newVal) this.$emit('setDialog', 'dialogViewImage');
    },
    visible(newVal) {
      this.isOpen = newVal;
    },
  },
  computed: {
    imageSelected() {
      return this.imageUrl;
    },
  },
};
</script>

<style scoped lang="scss">
.v-card__text {
  margin-top: 50px !important;
}
.btn-close {
  position:absolute;
  right: 10px;
  top: 15px;
}
</style>
