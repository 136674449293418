export default {
  headerNomor: 'Term Of Payment (Hari)',
  headerStatus: 'Status',
  headerAksi: 'Aksi',
  pageName: 'Daftar Term Of Payment',
  simpan: 'Simpan',
  batal: 'batal',
  statusAktif: 'Aktif',
  statusNotAktif: 'Tidak Aktif',
  searchText: 'Cari Term Of Payment',
  successCreate: 'Berhasil membuat TOP baru',
  successEdit: 'Berhasil merubah TOP',
  buttonAdd: 'Tambah Term Of Payment',
};
