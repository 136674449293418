import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const topList = async (filters) => {
  try {
    const res = await axios.get(`${urls.getTopList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const topCreateEdit = async (form, topId) => {
  try {
    let res;
    if (!topId) {
      res = await axios.post(urls.topDetail, form);
    } else {
      res = await axios.put(`${urls.topCreate(topId)}`, form);
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const topDetail = async (id) => {
  try {
    const res = await axios.get(`${urls.topDetail}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const topDelete = async (topId) => {
  try {
    const res = await axios.delete(`${urls.topDetail}/${topId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export {
  topList,
  topCreateEdit,
  topDetail,
  topDelete,
};
