import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const transportTypes = async (filters, source) => {
  try {
    const res = await axios.get(`${urls.transportTypeList}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListTransporter = async (companyName = '', source) => {
  try {
    const filters = {
      page: 0,
      size: 10,
      sort: 'companyName,asc',
    };
    if (companyName) {
      filters.companyName = companyName;
    }
    const res = await axios.get(`${urls.listTransporter}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListShipper = async (companyName = '', source) => {
  try {
    const filters = {
      page: 0,
      size: 10,
      sort: 'companyName,asc',
    };
    if (companyName) {
      filters.companyName = companyName;
    }
    const res = await axios.get(`${urls.listShipper}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const listReports = async () => {
  try {
    const res = await axios.get(`${urls.reports}/type/FLI`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const exportReportToCSV = async (id, filters) => {
  try {
    const res = await axios.get(`${urls.reports}/export/CSV/${id}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const previewReport = async (id, filters) => {
  try {
    const res = await axios.get(`${urls.reports}/show/CSV/${id}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getPreviewReport = async ({
  page,
  sort,
  size,
  id,
  fromDate,
  toDate,
  transporterId,
}) => {
  try {
    const filters = {
      fromDate, toDate, transporterId, page, size, sort,
    };
    if (transporterId) {
      // filters.transporterId = `${transporterId} '' `;
      filters.transporterId = transporterId;
    } else {
      delete filters.transporterId;
    }
    if (fromDate) {
      filters.fromDate = fromDate;
    } else {
      delete filters.fromDate;
    }
    if (toDate) {
      filters.toDate = toDate;
    } else {
      delete filters.toDate;
    }
    if (sort) {
      filters.sort = sort;
    } else {
      delete filters.sort;
    }
    const res = await axios.get(`${urls.getReportPreview}/${id}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  transportTypes,
  getListTransporter,
  getListShipper,
  previewReport,
  listReports,
  exportReportToCSV,
};
