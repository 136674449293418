/* eslint-disable import/no-cycle */
import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const getListMasterSales = async (filters) => {
  try {
    const res = await axios.get(`${urls.masterSalesList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const addMasterSales = async (companyId, name) => {
  try {
    const res = await axios.patch(`${urls.detailMasterShipper}/${companyId}?abbreviation=${name}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getListMasterSales, addMasterSales,
};
