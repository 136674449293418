export default {
  // auth
  auth: '/api/v3/auth/management/login',
  logout: '/api/v3/auth/logout',
  emailVerification: '/api/v3/fli/register/validation',
  accountActivation: '/api/v3/fli/register/activation/sub-account',
  ownUserInfo: '/api/v3/auth/whoami',
  role: '/api/v3/auth/role',
  register: '/api/v3/fli/register',
  decodeToken: '/api/v3/fli/register/activation',
  setResetPassword: '/api/v3/auth/management/reset-password',
  setChangePassword: '/api/v3/auth/user/management/reset-password',

  // parameter
  parameterList: '/api/v3/master/parameters',
  parameterGroup: '/api/v3/master/parameters/filter',
  selectionInfo: '/api/v3/master/reason',
  selectionDetail: (reasonId) => `/api/v3/master/reason/${reasonId}`,

  // uploads
  uploadImage: '/api/v3/file-upload',
  // download
  downloadImage: '/api/v3/file-upload',
  // delete
  deleteFile: '/api/v3/file-upload/delete',
  // uploadCsv: '/api/v1/orders/upload/csv',
  uploadedCsvStatus: '/api/v3/master/upload',
  uploadedCsvDetail: 'api/v1/orders/upload/detail',
  csvTemplateData: '/api/v1/orders/upload/template',
  uploadCsvReason: '/api/v3/master/reason/upload',
  csvTemplateDataReason: '/api/v3/master/reason/template',
  reasonList: '/api/v3/master/reason/filter',
  uploadCsvMouShipper: '/api/v3/shipper/mou/upload',
  csvTemplateMouShipper: '/api/v3/shipper/mou/template',
  uploadCsvMouTransporter: '/api/v3/transporter/mou/upload',
  csvTemplateMouTransporter: '/api/v3/transporter/mou/template',

  // category
  categoryList: '/api/v3/master/category/filter',
  categoryCreate: '/api/v3/master/category',
  categoryEdit: '/api/v3/master/category',
  categoryById: '/api/v3/master/category',
  uploadCsvCategories: '/api/v3/master/category/upload',
  csvTemplateDataCategories: '/api/v3/master/category/template',
  categoryDelete: '/api/v3/master/category',

  // sub category
  subCategoryList: (categoryId) => `/api/v3/master/category/${categoryId}/sub/filter`,
  subCategoryCreate: (categoryId) => `/api/v3/master/category/${categoryId}/sub`,
  subCategoryEdit: (categoryId, subCategoryId) => `/api/v3/master/category/${categoryId}/sub/${subCategoryId}`,

  // locations
  locationList: '/api/v3/master/location/filter',
  locationDetail: '/api/v3/master/location',
  provinceList: '/api/v3/master/location/province/filter',
  cityList: (provinceId) => `/api/v3/master/location/province/${provinceId}/city/filter`,
  districtList: (cityId) => `/api/v3/master/location/city/${cityId}/district/filter`,
  subDistrictList: (districtId) => `/api/v3/master/location/district/${districtId}/sub-district/filter`,
  downloadLocation: '/api/v3/master/location/export',
  csvTemplateDataLocation: '/api/v3/master/location/template',
  uploadCsvLocation: '/api/v3/master/location/upload',

  // user admin
  userAdminList: '/api/v3/auth/user/filter',
  userAdminCreate: '/api/v3/auth/user',
  userAdminDetail: (companyUserId) => `/api/v3/auth/user/${companyUserId}`,
  userAdminReset: (companyUserId) => `/api/v3/auth/user/management/reset-password/${companyUserId}`,
  userAdminResend: (companyUserId) => `/api/v3/auth/user/${companyUserId}/resend-activation`,
  userAdminDelete: (companyUserId) => `/api/v3/auth/user/${companyUserId}`,
  roleList: '/api/v3/auth/role/filter?appType=web_management',

  // Transport Types
  transportTypeList: '/api/v3/master/transport-type/filter',
  transportTypeDetail: '/api/v3/master/transport-type',
  csvTemplateDataTransportType: '/api/v3/master/transport-type/template',
  uploadCsvTransportType: '/api/v3/master/transport-type/upload',

  // Legal Documents
  legalDocumentList: '/api/v3/management/company/register/legal',
  legalAttachmentList: (documentSupportId) => `/api/v3/management/company/register/legal/attachment/${documentSupportId}`,
  legalAttachmentSave: (companyId) => `/api/v3/management/company/register/legal/${companyId}/attachment`,
  legalAttachmentDelete: (companyId, fileAttachmentId) => `/api/v3/shipper/company/register/document/${companyId}/attachment/${fileAttachmentId}`,

  // orders
  shipmentOrder: '/api/v3/shipment/order',
  managementShipment: '/api/v3/management/shipment',
  waitingShipment: '/api/v3/shipment/fli/show-sign',
  shipmentCargo: '/api/v3/shipment/cargo/fli',
  listShipments: '/api/v3/shipment/fli',
  listTransport: '/api/v3/master/transport-type/filter',
  orderById: '/api/v3/example/order',
  subOrderList: '/api/v3/example/subOrderList',
  shipmentSummary: '/api/v3/shipment/summary',
  wantCombine: '/api/v3/shipment/fli/want-combine',
  combined: '/api/v3/shipment/fli/combined',
  submitCombineLcl: '/api/v3/shipment/fli/combine',
  route: '/api/v3/shipment/fli/route',
  topListTransporters: '/api/v3/rating/transporter',
  shipmentDocumentAdditional: (shipmentsId) => `/api/v3/shipment/fli/${shipmentsId}/document-pod/upload`,
  saveSelectedTransporter: '/api/v3/shipment/cargo/fli/assign',
  getRoute: (shipmentGroupId) => `/api/v3/shipment/fli/${shipmentGroupId}/route`,
  deleteRoute: (shipmentGroupId, shipmentCargoId) => `/api/v3/shipment/fli/${shipmentGroupId}/route/${shipmentCargoId}`,
  mainCost: (shipmentId) => `/api/v3/management/transaction/${shipmentId}/main-cost`,
  unitPrice: (shipmentId) => `/api/v3/management/shipment/${shipmentId}/quantity/unit-price`,
  shipmentQty: (id, unit) => `/api/v3/management/shipment/${id}/quantity/${unit}`,
  shipmentQtyDelivery: (id, unit) => `/api/v3/management/shipment/${id}/quantity/delivery/${unit}`,
  orderTransaction: '/api/v3/management/transaction',

  // manual order
  getCategory: '/api/v3/master/category/filter',
  getServiceType: (companyId) => `/api/v3/management/mou/${companyId}/service`,
  getTransportType: (companyId, serviceType) => `/api/v3/management/mou/${companyId}/service/${serviceType}/transport-type`,
  getRouteManualOrder: (companyId, serviceType) => `/api/v3/management/mou/${companyId}/route/${serviceType}`,
  getSubCategory: (idCategories) => `/api/v3/master/category/${idCategories}/sub/filter`,
  getMultishipper: '/api/v3/shipment/fli/multi',
  getBackhauling: (companyId, backhaulingId) => `/api/v3/management/mou/${companyId}/backhauling/${backhaulingId}`,
  createShipment: '/api/v3/shipment/fli',

  // Contract
  contractShipperList: '/api/v3/contract/shipper/filter',
  contract3PLList: '/api/v3/contract/transporter/filter',
  contractAddendumList: '/api/v3/contract/addendum/filter',
  contractTerminationList: '/api/v3/contract/termination/filter',
  contractCompanyList: '/api/v3/master/company/filter',
  contractMouShipperId: (companyId) => `/api/v3/shipper/mou/company/${companyId}`,
  contractMou3PLId: (companyId) => `/api/v3/transporter/mou/company/${companyId}`,
  contractByCompanyId: (companyId) => `api/v3/contract/company/${companyId}`,
  submitContract: '/api/v3/contract',
  submitTemplateCreation: '/api/v3/contract/template/creation',
  contractHistory: '/api/v3/workflow/history',
  contractDetail: '/api/v3/contract',
  contractCompanyById: '/api/v3/contract/company',
  submitTermination: (contractId) => `/api/v3/contract/${contractId}/closure`,
  submitAffirmation: (contractId) => `/api/v3/contract/${contractId}/affirmation`,
  submitAddendum: (contractId) => `/api/v3/contract/${contractId}/addendum`,
  detailWorkflowContract: (contractId) => `/api/v3/contract/company/workflow/${contractId}`,
  inboxAppoveContract: '/api/v3/contract/company/workflow/action/approve',
  inboxRejectContract: '/api/v3/contract/company/workflow/action/reject',
  inboxReviseContract: '/api/v3/contract/company/workflow/action/revise',
  reviseContract: (workflowId, workflowDetailId) => `/api/v3/contract/company/workflow/revise/${workflowId}/${workflowDetailId}`,
  getDocumentContract: (id) => `/api/v3/contract/${id}/affirmation`,
  contractTemplateAddendum: '/api/v3/contract/template/addendum',
  approveContract: '/api/v3/contract/company/workflow/action',

  // Additional Cost
  additionalCost: '/api/v3/master/reason',
  listOracleCost: '/api/v3/master/oracle-cost/filter',

  // Approval
  listInboxApproval: '/api/v3/workflow/page/inbox',
  listCompletedApproval: '/api/v3/workflow/page/completed',
  listApprovalInProcess: '/api/v3/workflow/page/progress',
  listHistoryApproval: '/api/v3/workflow/history',
  shipperRegistrationApprove: '/api/v3/shipper/company/register',
  transporterRegistrationApprove: '/api/v3/transporter/company/register',
  actionWorkflow: '/api/v3/contract/company/workflow',
  getWorkflow: '/api/v3/transporter/company/register/document',

  // company registration
  listShipperCompanyRegistration: '/api/v3/shipper/company/register',
  listTransporterCompanyRegistration: '/api/v3/transporter/company/register',

  // mou
  mou: '/api/v3/mou',
  mouTransporter: '/api/v3/transporter/mou',
  mouShipper: '/api/v3/shipper/mou',
  mouOldTranportTypeLimit: (mouBy, mouId) => `/api/v3/mou/${mouBy}/${mouId}/transport-type-limit/old`,
  mouTransporterRoute: '/api/v3/mou/transporter',
  mouShipperRoute: '/api/v3/mou/shipper',
  listMouShipper: '/api/v3/shipper/mou/filter',
  listMouTransporter: '/api/v3/transporter/mou/filter',
  detailMouShipper: (mouId) => `/api/v3/mou/shipper/${mouId}`,
  detailMouTransporter: (mouId) => `/api/v3/mou/transporter/${mouId}`,
  detailWorkflowMou: (mouId) => `/api/v3/mou/workflow/${mouId}`,
  inboxAppoveMou: '/api/v3/mou/workflow/action/approve',
  inboxRejectMou: '/api/v3/mou/workflow/action/reject',
  inboxReviseMou: '/api/v3/mou/workflow/action/revise',
  reviseMou: (mouBy, workflowId, workflowDetailId) => `/api/v3/mou/${mouBy}/revise/${workflowId}/${workflowDetailId}/submit`,
  checkMou: (companyId) => `/api/v3/management/mou/company/${companyId}/exists`,
  listCompanyAlreadyMou: '/api/v3/master/company/filter/mou',
  downloadMouShipper: (mouId) => `/api/v3/shipper/mou/${mouId}/download/document`,
  downloadMouTransporter: (mouId) => `/api/v3/transporter/mou/${mouId}/download/document`,
  downloadMouTempShipper: (mouId) => `/api/v3/shipper/mou/${mouId}/download`,
  downloadMouTempTransporter: (mouId) => `/api/v3/transporter/mou/${mouId}/download`,

  // invoice
  invoiceList: '/api/v3/master/invoice-data',

  // Eta
  etaList: '/api/v3/master/eta/',
  etaCreateEdit: (etaId) => `/api/v3/master/eta/${etaId}`,
  etaDetail: '/api/v3/master/eta',

  // Shipping Cost
  shippingCostList: '/api/v3/master/shipping-cost-type/filter',
  shippingCostAddEdit: (shippingCostId) => `/api/v3/master/shipping-cost-type/${shippingCostId}`,
  shippingCostListCreateEdit: '/api/v3/master/shipping-cost-type',

  // EMAIL
  emailList: '/api/v3/master/email-customer/filter',
  emailAddEdit: (emailId) => `/api/v3/master/email-customer/${emailId}`,
  emailAddEditList: '/api/v3/master/email-customer',
  deleteEmail: (emailId) => `/api/v3/master/email-customer/${emailId}`,

  // Dashboard
  dashboardList: '/api/v3/shipment/fli/summary/list',
  customerSummary: '/api/v3/shipment/fli/summary',
  dashboardDetail: '/api/v3/shipment/fli/summary/list',

  // Master Cutomer
  masterCustomerList: '/api/v3/master/company/shipper/sent-to-oracle',
  resendToOracle: (id) => `/api/v3/master/company/shipper/sent-to-oracle/${id}`,
  getPayload: (id) => `/api/v3/master/company/shipper/sent-to-oracle/${id}/payload`,

  // Master Shipper
  masterShipperList: '/api/v3/master/company/shipper/filter',
  categoryShipperList: '/api/v3/master/shipper-category/filter',
  typeShipperList: '/api/v3/master/shipper-group/filter',
  detailMasterShipper: '/api/v3/master/company/shipper',
  fetchDetailCustomer: (companyId) => `/api/v3/master/company/shipper/${companyId}`,
  customerHistory: '/api/v3/auth/user/shipper/company',
  fetchAdminDetail: '/api/v3/auth/user/shipper/company',
  fetchDetailMasterShipper: '/api/v3/shipper/mou',

  // Master Partner
  masterPartnerList: '/api/v3/master/company/transporter/filter',
  detailMasterPartner: '/api/v3/master/company/transporter',
  fetchDetailMasterRekanan: '/api/v3/transporter/mou',
  fetchAdminDetailRekanan: '/api/v3/auth/user/transporter/company',
  fetchDetailMasterPartner: '/api/v3/transporter/mou',
  rekananHistory: '/api/v3/auth/user/transporter/company',

  // Master Sales
  masterSalesList: '/api/v3/master/sales/filter',

  // Vaksin
  vaccineList: '/api/v3/master/vaccines',
  vaccineDetail: (vaccineId) => `/api/v3/master/vaccines/${vaccineId}`,

  // Kode Area
  areaCodeList: '/api/v3/master/area-code/filter',
  areaCode: '/api/v3/master/area-code',
  areaCodeDetail: (areaCodeId) => `/api/v3/master/area-code/${areaCodeId}`,

  // Dokumen POD
  dokumenPodList: '/api/v3/shipment/cargo/fli/document-pod',
  dokumenPodDetail: '/api/v3/shipment/cargo/fli/document-pod',
  dokumenPodDetail2: (cargoId) => `/api/v3/shipment/cargo/fli/document-pod/${cargoId}`,
  typeDokumenPodDetail: '/api/v3/shipment/cargo/fli/document-pod',
  typeDokumenPodEdit: (cargoId, documentId) => `/api/v3/shipment/cargo/fli/document-pod/${cargoId}/detail/${documentId}`,

  // Shipping Problem
  accident: '/api/v3/management/shipment/report/accident',
  shippingProblemList: '/api/v3/management/shipment/report/accident/filter',
  shippingProblemProsesDetail: '/api/v3/management/shipment/report/accident',
  shippingProblemProses: (accidentId) => `/api/v3/management/shipment/report/accident/${accidentId}/process`,
  shippingProblemResolved: (accidentId) => `/api/v3/management/shipment/report/accident/${accidentId}/resolved`,

  // Schedule
  listTransporter: '/api/v3/master/company/transporter/filter',
  listShipper: '/api/v3/master/company/shipper/filter',
  listCalender: '/api/v3/management/calendar',

  // InvoiceOrder
  invoiceGetList: '/api/v3/management/invoice/filter',
  invoiceCreateOrder: '/api/v3/management/transaction/filter',
  invoiceCreateShipper: '/api/v3/management/invoice',
  invoiceDetail: (invoiceId) => `/api/v3/management/invoice/${invoiceId}`,
  invoiceExport: '/api/v3/management/invoice/export',
  invoiceExportProforma: '/api/v3/management/invoice/export/proforma',
  emailListInvoice: '/api/v3/master/email-customer/management/filter',

  // Invoice Customer Cancel
  invoiceCustomerCancel: '/api/v3/management/invoice/canceled',

  // Invoice Fixed Price
  invoiceFixedPrice: '/api/v3/management/invoice/fixed-price',

  // Damage and Missing
  baspList: '/api/v3/management/shipment/report/basp',
  baspDetail: '/api/v3/management/shipment/report/basp',

  // Notificatioin
  notification: 'notification/v1/notifications/web',

  // AccountInfo
  getAccountInfo: (companyUserId) => `/api/v3/user/${companyUserId}/profile`,

  // Term Of Payment
  getTopList: '/api/v3/master/parameters/top/filter',
  topCreate: (topId) => `/api/v3/master/parameters/top/${topId}`,
  topDetail: '/api/v3/master/parameters/top',

  getListShipper: '/api/v3/master/company/shipper/filter',
  getListTransporter: '/api/v3/master/company/transporter/filter',

  // Report
  reports: '/reports',

  // DRIVER
  transport: '/api/v3/master/transport',

  // Wording
  fetchWordingList: '/api/v3/master/parameters/wording/filter',
  fetchDetailWording: (wordingId) => `/api/v3/master/parameters/wording/${wordingId}`,
  fetchDetailByCodeWording: '/api/v3/master/parameters/wording/code',

  // TAX
  fetchTexList: '/api/v3/management/invoice/tax/filter',
  exportTax: '/api/v3/management/invoice/export/tax',

  // P2P LENDING
  p2pLending: '/api/v3/management/p2plending',

  // Open API
  openApi: '/api/v3/openapi/auth/generate',
  masterShipper: '/api/v3/master/company/shipper',
  masterTransporter: '/api/v3/master/company/transporter',

  // Holiday
  holiday: '/api/v3/master/holiday',
  csvTemplateDataHoliday: '/api/v3/master/holiday/template',
  uploadCsvHoliday: '/api/v3/master/holiday/upload',

  // Close Periode
  closePeriod: '/api/v3/master/close-period',

  // oracle data
  oracle: '/api/v3/oracle',
  oracleInvoice: '/api/v3/invoice/log',
  accrualGl: '/api/v3/accrual',
  accrualOracle: '/api/v3/accrual-oracle',

  // location oracle
  csvTemplateDataLocationOracle: '/api/v3/master/oracle-location/template',
  uploadCsvLocationOracle: '/api/v3/master/oracle-location/upload',

  master: '/api/v3/master',

  // master menu
  manageMenu: '/api/v3/auth/menu',

  // driver
  driverList: '/api/v3/master/driver/admin',
  activedDriver: (driverId) => `/api/v3/master/driver/admin/${driverId}`,
  driverCreateEdit: '/api/v3/master/driver/admin',
  driverDetail: (driverId) => `/api/v3/master/driver/${driverId}`,
  deleteDriver: (driverId, companyId) => `/api/v3/master/driver/admin/${driverId}?companyId=${companyId}`,
  resetPassword: (driverId) => `/api/v3/master/driver/${driverId}/reset-password`,
  csvTemplateDataDriver: '/api/v3/master/driver/admin/template',
  uploadCsvDriver: '/api/v3/master/driver/admin/upload',
  listVaksinDriver: '/api/v3/master/vaccines',

  // transport
  transportList: '/api/v3/master/transport/admin',
  transportCreate: '/api/v3/master/transport/admin',
  transportDetail: (transportId) => `/api/v3/master/transport/${transportId}`,

  // driver transporter
  driverTransport: '/api/v3/master/driver/admin?size=20&isActive=true',
  driver: '/api/v3/master/driver/transport',
  transportType: '/api/v3/master/transport-type/filter',
  csvTemplateTransport: '/api/v3/master/transport/template',
  csvUploadTransport: '/api/v3/master/transport/admin/upload',

  // Shipper Shipment Upload
  shipmentShipper: '/api/v3/shipper/shipment',
};
