import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

/**
 * get category list
 * @param {Object} filter list parameters
 * @param {Number} filter.page page number
 * @param {Number} filter.size page size
 */
const fetchList = async ({
  page, name, size, sort,
}) => {
  try {
    const filter = {
      page, size, sort,
    };
    if (name) filter.name = name;
    const res = await axios.get(`${urls.categoryList}?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get category by category id
 * @param {Number} categoryId category id
 */
const getCategoryById = async (categoryId) => {
  try {
    const res = await axios.get(`${urls.categoryById}/${categoryId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * create new category
 * @param {String} name new category name
 */
const create = async (data) => {
  try {
    const res = await axios.post(urls.categoryCreate, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * edit a category
 * @param {String} name category name
 */
const edit = async (data, categoryId) => {
  try {
    const res = await axios.patch(`${urls.categoryEdit}/${categoryId}`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get sub category list
 * @param {Number} categoryId category id
 * @param {Object} filter list parameters
 * @param {Number} filter.page page number
 * @param {Number} filter.size page size
 */
const fetchSubCategoryList = async (categoryId, filter) => {
  try {
    const res = await axios.get(`${urls.subCategoryList(categoryId)}?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * create new sub category
 * @param {String} name new sub category name
 * @param {Number} categoryId category id
 */
const createSubCategory = async (name, categoryId) => {
  try {
    const data = {
      categoryId,
      name,
    };
    const res = await axios.post(urls.subCategoryCreate(categoryId), data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * edit a sub category
 * @param {String} name sub category name
 * @param {Number} categoryId category id
 * @param {Number} id sub category id to be edited
 */
const editSubCategory = async (name, categoryId, id) => {
  try {
    const data = {
      id,
      categoryId,
      name,
    };
    const res = await axios.put(urls.subCategoryEdit(categoryId, id), data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteSubCategory = async (categoryId, id) => {
  try {
    const data = {
      id,
      categoryId,
    };
    const res = await axios.delete(urls.subCategoryEdit(categoryId, id), data);
    // const res = await axios.delete(`${urls.subCategoryEdit}/${categoryId}/sub/${id}`);

    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * fetch csv template for category
 * @returns response data
 */
const fetchCsvTemplateData = async () => {
  try {
    const res = await axios.get(`${urls.csvTemplateDataCategories}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * upload csv for category
 * @param {FormData} formData csv data
 */
const uploadCsv = async (formData, type) => {
  try {
    const res = await axios.post(
      urls.uploadCsvCategories,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteCategory = async (categoryId) => {
  try {
    const res = await axios.delete(`${urls.categoryDelete}/${categoryId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getCategoryItems = async (filters) => {
  try {
    const res = await axios.get(`${urls.getCategory}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getSubCategoryItems = async (filters, { categoryId }) => {
  try {
    const res = await axios.get(
      `${urls.getSubCategory(categoryId)}?${querystring.stringify(filters)}`,
    );
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchList,
  getCategoryById,
  create,
  edit,
  fetchSubCategoryList,
  createSubCategory,
  editSubCategory,
  fetchCsvTemplateData,
  uploadCsv,
  deleteCategory,
  deleteSubCategory,
  getCategoryItems,
  getSubCategoryItems,
};
