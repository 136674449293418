<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="time"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          style="position:absolute;opacity:0"
          v-model="displayValue"
          label="Picker in menu"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
        <v-text-field
          ref="time-input"
          v-model="displayValue"
          @input="changeValue"
          :rules="[($event) => rules.time($event)]"
          v-bind="{...$attrs}"
          v-mask="{'alias': 'time', rightAlign: false}"
          @click:prepend-inner="menu = true"
          :class="bindClass"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="menu"
        v-model="tempTime"
        format="24hr"
        full-width
        @click:minute="saveTimeOnDialog"
      ></v-time-picker>
    </v-menu>
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    bindClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    displayValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
  data() {
    return {
      tempTime: '',
      time: null,
      menu: false,
      show1: false,
      rules: {
        time: (value) => {
          if (!value) return this.$_strings.messages.validation.REQUIRED('Jam');
          const pattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
          if (!pattern.test(value)) return 'Format jam salah';
          return true;
        },
      },
    };
  },
  methods: {
    changeValue(val) {
      const pattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
      if (pattern.test(val)) this.tempTime = val;
    },
    saveTimeOnDialog() {
      this.$emit('input', this.tempTime);
      this.menu = false;
    },
  },
};
</script>
