import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

/**
 * fetch Location list
 * @param {Object} filters
 *  @param {Number} page Page number
 *  @param {Number} size list size
 */
const fetchWordingList = async ({
  page, size, sort,
}) => {
  try {
    const filters = {
      page, size,
    };
    if (sort) {
      filters.sort = sort;
    } else {
      filters.sort = 'createdAt,desc';
    }
    const res = await axios.get(`${urls.fetchWordingList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const editWording = async (form, wordingId) => {
  try {
    const res = await axios.patch(urls.fetchDetailWording(wordingId), form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const aboutInfo = async (data) => {
  try {
    const res = await axios.get(`${urls.fetchDetailByCodeWording}/WOR_TENTANG_KAMI`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const detailInfo = async (wordingId) => {
  try {
    const res = await axios.get(urls.fetchDetailWording(wordingId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const contactInfo = async (data) => {
  try {
    const res = await axios.get(`${urls.fetchDetailByCodeWording}/WOR_HUBUNGI_KAMI`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const termsInfo = async (data) => {
  try {
    const res = await axios.get(`${urls.fetchDetailByCodeWording}/WOR_SYARAT_DAN_KETENTUAN`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const privacyPolicy = async (data) => {
  try {
    const res = await axios.get(`${urls.fetchDetailByCodeWording}/WOR_KEBIJAKAN_PRIVASI`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchWordingList,
  editWording,
  aboutInfo,
  detailInfo,
  contactInfo,
  termsInfo,
  privacyPolicy,
};
