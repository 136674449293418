export default {
  masterShipperDatabase: 'Shipper',
  searchText: 'Cari Nama Shipper',
  photo: 'Foto',
  createDriver: 'Tambah Sopir',
  editDriver: 'Ubah Sopir',
  contractNo: 'Nomor Kontrak',
  companyName: 'Nama Perusahaan',
  abbreviation: 'Alias/Inisial',
  companyAddress: 'Alamat',
  mouPeriodMonths: 'Durasi MoU',
  createdBy: 'Dibuat Oleh',
  updatedBy: 'Diubah Oleh',
  licenseNo: 'Nomor Sim',
  licenseExpDate: 'Masa Berlaku Sim',
  status: 'Status',
  deleteDriverConfirmMsg: 'Apakah Anda yakin ingin menghapus akun sopir ini?',
  successDeleteDriverMsg: 'Berhasil menghapus akun',
  editDriverConfirmMsg: 'Berhasil mengubah akun driver',
  successUpdateDriverMsg: 'Berhasil menambahkan akun driver',
  resetPasswordButtonLabel: 'Reset Password',
  resetPasswordConfirmMsg: 'Apakah Anda yakin ingin me-reset password akun ini?',
  resetPasswordSuccessMsg: 'Berhasil me-reset password akun',
  statusDriver: 'Semua Status',
  driverNameInputLabel: 'Driver',
  PAGE_NAME: 'Daftar Sopir',
  nameSopirLabel: 'Nama Sopir',
  emailLabel: 'Email',
  phoneLabel: 'Nomor Telepon',
  licenseNoLabel: 'Nomor Sim',
  imageUrlLabel: 'Gambar',
  toDateFormatLabel: 'Masa Berlaku Sim',
  deleteUserConfirmMessage: 'Apakah anda yakin ingin menghapus user ini?',
  activeLabel: 'Aktif',
  inActiveLabel: 'Tidak Aktif',
  pageName: 'Daftar Shipper',
  headerTitleDialog: 'Daftar User',
  headerCustomer: {
    NO: 'No',
    NAMA: 'Nama',
    EMAIL: 'Email',
    ROLE: 'Role',
    STATUS: 'Status',
  },
  statusActivated: [
    { key: true, value: 'Aktif' },
    { key: false, value: 'Tidak Aktif' },
  ],
  statusGroup: [
    { key: true, value: 'Internal' },
    { key: false, value: 'Eksternal' },
  ],
};
