import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const fetchList = async (filter) => {
  try {
    const res = await axios.get(`${urls.invoiceCustomerCancel}/filter?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchListDetail = async (invoiceId, filter) => {
  try {
    const res = await axios.get(`${urls.invoiceCustomerCancel}/${invoiceId}?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateInvoice = async (invoiceId) => {
  try {
    const res = await axios.patch(`${urls.invoiceCustomerCancel}/${invoiceId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteInvoice = async (invoiceId, shipmentId) => {
  try {
    const res = await axios.delete(`${urls.invoiceCustomerCancel}/${invoiceId}/${shipmentId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const addOrder = async (invoiceId, form) => {
  try {
    const res = await axios.put(`${urls.invoiceCustomerCancel}/${invoiceId}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const saveCost = async (invoiceId, form) => {
  try {
    const res = await axios.put(`${urls.invoiceCustomerCancel}/${invoiceId}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchList,
  fetchListDetail,
  updateInvoice,
  deleteInvoice,
  addOrder,
  saveCost,
};
