import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const getListOracleCost = async (filters, source = {}) => {
  try {
    const res = await axios.get(`${urls.listOracleCost}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListOracleCostItems = async (filters) => {
  try {
    if (filters.name) {
      filters = {
        ...filters,
        name: `qLike(${filters.name})`,
      };
    }
    const res = await axios.get(`${urls.listOracleCost}?isActive=true&${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getListOracleCost,
  getListOracleCostItems,
};
