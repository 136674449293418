export default {
  searchTypeCompany: 'Masukkan Tipe Perusahaan',
  searchCompany: 'Masukkan Nama Perusahaan',
  searchServiceType: 'Masukkan Jenis Pengiriman',
  searchTypeCustomer: 'Masukkan Tipe Customer',
  searchCustomer: 'Masukkan Nama Customer',
  searchTransporter: 'Masukkan Nama Vendor',
  searchTypeKendaraan: 'Masukkan Nama Tipe Kendaraan',
  searchAlias: 'Masukkan Alias Customer',
  checkboxTypeCompany: 'Tipe Perusahaan',
  checkboxCompany: 'Nama Perusahaan',
  checkboxServiceType: 'Jenis Pengiriman',
  checkboxTypeCustomer: 'Tipe Customer',
  checkboxCustomer: 'Customer',
  checkboxTransporter: 'Transporter',
  checkboxTypeTransport: 'Tipe Kendaraan',
  checkboxAlias: 'Alias',
  labelTanggal: 'Tanggal',
  download: 'Download',
  selectLaporan: 'Pilih Jenis Laporan',
  preview: 'Tampilkan',
  REPORT_LIST: 'Daftar Laporan',
  SELECT_THE_REPORT: 'Pilih laporan yang ingin anda lihat',
  NEXT: 'Selanjutnya',
  REPORT_TRANSPORTER: {
    pageName: 'Daftar Transporter',
    nama: 'Nama',
    pic: 'Penanggung Jawab (PIC)',
    address: 'Alamat',
    handphone: 'Telepon',
  },
  REPORT_BASP: {
    customer: 'Customer',
    noShipment: 'Nomor Kiriman',
    noOrder: 'Nomor Pesanan',
    dateOrder: 'Tanggal Pesanan',
    datePickup: 'Tanggal Pickup',
    originalLocation: 'Lokasi Asal',
    destinationLocation: 'Lokasi Tujuan',
    transport: 'Transport',
    noVehicle: 'No.Kendaraan',
    typeVehicle: 'Tipe Kendaraan',
    driver: 'Sopir',
    datePOD: 'Tanggal POD',
    statusRecept: 'Status Penerimaan',
    reasonOfDifference: 'Alasan Selisih',
    descriptionItem: 'Keterangan Item',
    actionPlanShipper: 'Action Plan Shipper',
    pageName: 'Daftar Basp',
  },
  REPORT_CUSTOMER: {
    title: 'Laporan Customer',
    nama: 'Nama',
    pic: 'Penanggung Jawab (PIC)',
    address: 'Alamat',
    handphone: 'Telepon',
    pageName: 'Daftar Customer',
  },
  REPORT_CATEGORY: {
    title: 'Laporan Kategori',
    namaCategory: 'Nama Kategori',
    totalOrder: 'Jumlah Pemesanan',
    pageName: 'Daftar Kategori',
  },
  REPORT_RITASE: {
    title: 'Laporan Pesanan Berdasarkan Ritase',
    transporter: 'Transporter',
    typeVehicle: 'Jumlah Pemesanan',
    noVehicle: 'Daftar Kategori',
    totalRitase: 'Jumlah Ritase',
    pageName: 'Daftar Transporter',
  },
  REPORT_SHIPPING_SHIPPER: {
    title: 'Laporan Pesanan Shipper Per Status',
    nameShipper: 'Nama Shipper',
    noPost: 'Jumlah Pemesanan',
    noOrder: 'Daftar Kategori',
    originalLocation: 'Jumlah Ritase',
    destinationLocation: 'Jumlah Ritase',
    partner: '3PL',
    noVehicle: 'Jumlah Ritase',
    typeVehicle: 'Tipe Kendaraan',
    driver: 'Sopir',
    dateOrderMade: 'Tanggal Pesanan Dibuat',
    dateAutoAssign: 'Tanggal Auto Assign Ke Driver',
    dateOrderConfirmation: 'Tanggal Pesanan Telah Terkonfirmasi',
    dateOrderPickUp: 'Tanggal Pesanan Penjemputan',
    dateFinishOrderPickUp: 'Tanggal Sampai Lokasi Penjemputan',
    dateDelivery: 'Tanggal Pengiriman',
    dateArrivalDestination: 'Tanggal Sampai Lokasi Tujuan',
    datePOD: 'Tanggal POD',
    status: 'Status',
    solutionMethod: 'Metode Penyelesaian',
    manualPodReason: 'Alasan POD Manual',
    pageName: 'Daftar Laporan Pesanan',
  },
  REPORT_DATA_ARMADA: {
    title: 'Laporan Data Armada',
    namePartner: 'Transporter',
    typeVehicle: 'Tipe Kendaraan',
    merkVehicle: 'Merk Kendaraan',
    noVehicle: 'No Kendaraan',
    dimension: 'Dimensi',
    year: 'Tahun',
    validityPeriodKir: 'Masa Berlaku KIR',
    validityPeriodStnk: 'Masa Berlaku STNK',
    driver: 'Sopir',
    notes: 'Catatan',
    status: 'Status',
    pageName: 'Daftar Laporan Data Armada',
  },
  REPORT_BILLING_CUSTOMER: {
    title: 'Laporan Tagihan Customer',
    nameCustomer: 'Nama Customer',
    noBill: 'No Tagihan',
    dateBill: 'Tanggal Tagihan',
    dateBillFrom: 'Tanggal Pengiriman Dari',
    dateBillUntill: 'Tanggal Pengiriman Sampai',
    datePODFromDriver: 'Tanggal Terima Fisik POD Dari Sopir',
    datePODToShipper: 'Tanggal Kirim Fisik POD ke Shipper',
    dateInvoice: 'Tanggal Invoice',
    dueDate: 'Tanggal Jatuh Tempo',
    payDate: 'Tanggal Bayar',
    feeType: 'Jenis Biaya',
    currency: 'Mata Uang',
    total: 'Jumlah(Net)',
    pageName: 'Daftar Tagihan',
  },
  REPORT_AGING_PIUTANG_SHIPPER: {
    title: 'Laporan Aging Piutang Shipper',
    nameShipper: 'Nama Shipper',
    noInvoice: 'No Invoice',
    dateInvoiceCreated: 'Tanggal Invoice Dibuat',
    dateInvoiceAccepted: 'Tanggal Invoice Diterima',
    top: 'TOP',
    datePiutang: 'Tanggal Piutang Jatuh Tempo',
    aging: 'Aging',
    typeCost: 'Jenis Biaya',
    current: 'Current',
    date15: '1-15 Hari',
    date30: '16-30 Hari',
    date60: '31-60 Hari',
    date90: '61-90 Hari',
    date90plus: '>90 Hari',
    total: 'Jumlah(net)',
    pageName: 'Daftar Laporan Aging',
  },
  REPORT_INCOME: {
    title: 'Laporan Pendapatan',
    nameShipper: 'Nama Shipper',
    nameCustomer: 'Nama Customer',
    alias: 'Alias',
    noBill: 'No Tagihan',
    dateBill: 'Tanggal Tagihan',
    deliveryDateFrom: 'Tanggal Pengiriman Dari',
    deliveryDateTo: 'Tanggal Pengiriman Sampai',
    feeType: 'Jenis Biaya',
    currency: 'Mata Uang',
    noInvoice: 'No Invoice',
    dateInvoiceCreated: 'Tanggal Invoice Dibuat',
    typeCost: 'Jenis Biaya',
    total: 'Jumlah (Net)',
    ppn: 'PPN',
    total2: 'Total',
    pageName: 'Daftar Laporan Aging',
  },
  REPORT_BILLING_TRANSPORTER: {
    title: 'Laporan Tagihan Transporter',
    rekanan: 'Rekanan 3PL',
    typeBill: 'Tipe Tagihan',
    nameCustomer: 'Nama Customer',
    noBill: 'No Tagihan',
    dateBill: 'Tanggal Tagihan',
    dateBillFrom: 'Tanggal Pengiriman Dari',
    dateBillUntill: 'Tanggal Tagihan Sampai',
    datePODFromDriver: 'Tanggal Terima Fisik POD Dari Sopir',
    datePODToShipper: 'Tanggal Kirim Fisik POD ke Shipper',
    dateInvoice: 'Tanggal Invoice',
    dueDate: 'Tanggal Jatuh Tempo',
    payDate: 'Tanggal Bayar',
    feeType: 'Jenis Biaya',
    currency: 'Mata Uang',
    total: 'Jumlah(Net)',
    pageName: 'Daftar Tagihan',
  },
  REPORT_AGING_HUTANG_3PL: {
    title: 'Laporan Aging Hutang ke 3PL',
    nameTransporter: 'Nama 3PL',
    noInvoice: 'No Invoice',
    dateInvoiceCreated: 'Tanggal Invoice Dibuat',
    dateInvoiceAccepted: 'Tanggal Invoice Diterima',
    top: 'TOP',
    datePiutang: 'Tanggal Piutang Jatuh Tempo',
    aging: 'Aging',
    typeCost: 'Jenis Biaya',
    current: 'Current',
    date15: '1-15 Hari',
    date30: '16-30 Hari',
    date60: '31-60 Hari',
    date90: '61-90 Hari',
    date90plus: '>90 Hari',
    total: 'Jumlah(net)',
    pageName: 'Laporan Aging ke 3PL',
  },
  REPORT_AGING_POD: {
    title: 'Laporan Aging POD',
    nameRekanan: 'Nama Rekanan',
    noVehicle: 'Nomor Kendaraan',
    typeVehicle: 'Tipe Kendaraan',
    driver: 'Driver',
    nameShipper: 'Nama Shipper',
    noOrder: 'Nomor Pesanan',
    travelLetterNumber: 'Nomor Surat Jalan',
    originDestination: 'Lokasi Asal',
    destinationLocation: 'Lokasi Tujuan',
    dateOrder: 'Tanggal Pesanan',
    datePickUp: 'Tanggal Pickup',
    deliveryDate: 'Tanggal Serah Terima Barang',
    dateReceiptPOD: 'Tanggal Penerimaan Fisik POD',
    leadtime: 'Leadtime POD (hari)',
    target: 'Target',
    noted: 'Keterangan',
    pageName: 'Laporan Aging POD',
  },
  REPORT_REJECT_ORDER: {
    title: 'Laporan Penolakan Pesanan oleh 3PL & Driver',
    noOrder: 'Nomor Pesanan',
    dateOrder: 'Tanggal Pesanan',
    datePickUp: 'Tanggal Pickup',
    originDestination: 'Lokasi Asal',
    destinationLocation: 'Lokasi Tujuan',
    nameRekanan: 'Nama Rekanan',
    dateAssignTo3PL: 'Tanggal Assign Ke 3PL',
    dateAssignToDriver: 'Tanggal Assign Ke Driver',
    dateRejectOrder: 'Tanggal Penolakan Pesanan',
    driver: 'Sopri',
    noVehicle: 'Nomor Kendaraan',
    typeVehicle: 'Tipe Kendaraan',
    pageName: 'Laporan Penolakan Pesanan oleh 3PL & Driver',
  },
  REPORT_REGISTRASI: {
    title: 'Laporan Registrasi Shipper & 3PL',
    company: 'Shipper/Rekanan',
    nameCompany: 'Nama Perusahaan',
    dateRegister: 'Tanggal Pendaftaraan',
    status: 'Status',
    position: 'Posisi',
    dateUpadatedStatus: 'Tanggal Update Status',
    noted: 'Keterangan',
    pageName: 'Laporan Registrasi Shipper & 3PL',
  },
  REPORT_DELIVERY_TONASE_KUBIKASI: {
    title: 'Laporan Pengiriman by Kubikasi & Tonase',
    nameShipper: 'Nama Shipper',
    noOrder: 'Nomor Pesanan',
    noShipment: 'Nomor Kiriman',
    dateOrder: 'Tanggal Pesanan',
    datePickUp: 'Tanggal Pick Up',
    originalLocation: 'Lokasi Asal',
    destinationLocation: 'Lokasi Tujuan',
    typeVehicle: 'Tipe Kendaraan',
    rekanan: 'Nama Rekanan',
    noVehicle: 'Nomor Kendaraan',
    driver: 'Sopir',
    totalKubikasi: 'Jumlah Kubikasi(m3)',
    totalTonase: 'Jumlah Tonase(ton)',
    pageName: 'Laporan Pengiriman by Kubikasi & Tonase',
  },
  REPORT_PROBLEM_ARMADA_DELIVERY: {
    title: 'Laporan Masalah Armada Pengiriman',
    noShipment: 'Nomor Kiriman',
    noOrder: 'Nomor Pesanan',
    dateOrder: 'Tanggal Pesanan',
    datePickup: 'Tanggal Pickup',
    originalLocation: 'Lokasi Asal',
    destinationLocation: 'Lokasi Tujuan',
    noVehicle: 'Nomor Kendaraan',
    typeVehicle: 'Tipe Kendaraan',
    merkVehicle: 'Merk Kendaraan',
    driver: 'Driver',
    problem: 'Masalah',
    dateReporting: 'Tanggal Pelaporan',
    noted: 'Catatan',
    location: 'Lokasi (Koordinat)',
    solution: 'Solusi',
    dateCompleted: 'Tanggal Penyelesaian',
    status: 'Status',
    pic: 'PIC yang menangani',
    pageName: 'Laporan Masalah Armada Pengiriman',
  },
  REPORT_PERFOMANCE: {
    title: 'Laporan Performance 3PL & Driver',
    rekanan: 'Rekanan',
    competitivePrice: 'Competitiv Price',
    truckAvailability: 'Truck Availability',
    onTimeDelivery: 'On Time Delivery',
    basp: 'BASP',
    onTimeDeparture: 'On Time Departure',
    onTimeFisikPod: 'On Time Fisik POD',
    totalScore: 'Total Score',
    pageName: 'Laporan Performance 3PL & Driver',
  },
  REPORT_COLLECTION: {
    title: 'Laporan Collection',
    nameShipper: 'Nama Shipper',
    noInvoice: 'No Invoice',
    dateInvoiceCreated: 'Tanggal Invoice Dibuat',
    top: 'TOP (Hari)',
    datePiutang: 'Tanggal Piutang Jatuh',
    typeCost: 'Jenis Biaya',
    nominalInvoice: 'Nominal Invoice',
    paymentDate: 'Tanggal Pembayaran',
    nominal: 'Nominal',
    remainderPiutang: 'Sisa Piutang',
    status: 'Status',
    pageName: 'Laporan Collection',
  },
  REPORT_TAX: {
    title: 'Laporan Pajak',
    nameFaktur: 'Nama Faktur',
    noKuitansi: 'No Kuitansi',
    dateInvoice: 'Tanggal Invoice',
    currency: 'Mata Uang',
    dpp: 'DPP',
    ppn: 'PPN',
    pph: 'PPH Ps 23',
    total: 'Total (DPP+PPN+PPH Ps 23',
    noted: 'Keterangan Invoice',
    pageName: 'Laporan Pajak',
  },
  REPORT_DATA_DRIVER: {
    title: 'Laporan Data Driver Per Status 3PL',
    nameTransporter: 'Nama 3PL',
    pageName: 'Daftar Laporan Data Driver',
    driverName: 'Nama Sopir',
    email: 'Alamat Email',
    noTelp: 'Nomor Telepon',
    noSIM: 'Nomor SIM',
    simExpired: 'Masa Berlaku SIM',
    dateVaccine1: 'Tanggal Vaksin Pertama',
    dateVaccine2: 'Tanggal Vaksin Kedua',
    typeVaccine: 'Jenis Vaksin',
    status: 'Status',
  },

};
