<template>
  <section>
    <!-- v-on="$listeners" -->
    <v-data-table
      v-bind="$attrs"
      v-on="!isMounted ? $listeners : null"
      :hide-default-header="!isMobile"
    >
      <template v-slot:header="{ props }">
        <thead v-if="!isMobile">
          <tr>
            <th v-for="head in props.headers" :key="head.text" :class="head.class">
              <span>{{ head.text }}</span>
              <div class="wrapper-row-icon">
                <div class="row-icon" v-if="head.sortable !== false ">
                  <v-icon
                    :color="props.options.sortBy[0] === head.value && ((props.options.sortDesc.length && props.options.sortDesc[0] === false) || !props.options.sortDesc.length) ? 'white' : ''"
                    @click="handleSorting(head.value, 'ASC')"
                  >
                    mdi-menu-up
                  </v-icon>
                  <v-icon
                    :color="props.options.sortBy[0] === head.value && (props.options.sortDesc.length && props.options.sortDesc[0] === true) ? 'white' : ''"
                    @click="handleSorting(head.value, 'DESC')"
                    class="mdi-menu-down">
                    mdi-menu-down
                  </v-icon>
                </div>
              </div>
            </th>
          </tr>
        </thead>
      </template>
      <!-- pass through scoped slots -->
      <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>

      <!-- pass through normal slots -->
      <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
        <slot :name="slotName" />
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  name: 'common-table',
  props: {
    paginations: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      isMounted: false,
    };
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    handleSorting(value, sortBy) {
      if (sortBy === 'ASC') {
        if (this.$attrs.options.sortBy.length && !this.$attrs.options.sortDesc.length) {
          this.$attrs.options.sortBy = [];
          return;
        }
        this.$parent.pagination = {
          ...this.$parent.pagination,
          sortDesc: [],
          sortBy: [value],
        };
        return;
      }
      if (this.$attrs.options.sortBy.length && this.$attrs.options.sortDesc.length) {
        this.$parent.pagination = {
          ...this.$parent.pagination,
          sortBy: [],
          sortDesc: [],
        };
        return;
      }
      this.$parent.pagination = {
        ...this.$parent.pagination,
        sortBy: [value],
        sortDesc: [true],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper-row-icon {
  display: inline-block;
  position: relative;
  .row-icon {
    top: 0;
    align-content: flex-end;
    display: flex;
    flex-direction: column;
    .v-icon.mdi-menu-down {
      margin-top: -12px;
    }
  }
}
</style>
