import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const getListShippingCost = async (filters) => {
  try {
    const res = await axios.get(`${urls.shippingCostList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const shippingCostAddEdit = async (form, shippingCostId) => {
  try {
    let res;
    const {
      name,
      isActive,
    } = form;
    const data = {
      name,
      isActive,
    };
    if (shippingCostId) {
      res = await axios.put(`${urls.shippingCostAddEdit(shippingCostId)}`, data);
      return res.data;
    }
    res = await axios.post(`${urls.shippingCostListCreateEdit}`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchShippingCost = async (shippingCostId) => {
  try {
    const res = await axios.get(`${urls.shippingCostAddEdit(shippingCostId)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getListShippingCost,
  shippingCostAddEdit,
  fetchShippingCost,
};
