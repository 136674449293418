import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const createUpdateHoliday = async (form, holidayId) => {
  try {
    if (holidayId) {
      const res = await axios.patch(`${urls.holiday}/${holidayId}`, form);
      return res.data;
    }
    const res = await axios.post(urls.holiday, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const holidayList = async (filters, source = {}) => {
  try {
    const res = await axios.get(`${urls.holiday}/filter?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteHoliday = async (id) => {
  try {
    const res = await axios.delete(`${urls.holiday}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchCsvTemplateData = async () => {
  try {
    const res = await axios.get(`${urls.csvTemplateDataHoliday}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const uploadCsv = async (formData, type) => {
  try {
    const res = await axios.post(
      urls.uploadCsvHoliday,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  createUpdateHoliday,
  holidayList,
  deleteHoliday,
  fetchCsvTemplateData,
  uploadCsv,
};
