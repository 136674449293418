/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

/**
 * fetch transport type list
 * @param {Object} filters
 *  @param {Number} page Page number
 *  @param {Number} size list size
 *  @param {String} name search text
 *  @param {String} sort sorting format: field;ASC|DESC
 */
const fetchTransportTypes = async (filters) => {
  try {
    const res = await axios.get(`${urls.transportTypeList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const listTransportTypes = async (filters) => {
  try {
    if (filters.name) filters.name = `qlike(${filters.name})`;
    const res = await axios.get(`${urls.transportTypeList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * fetch the detail of a transport type
 * @param {Number} id Transport Type id
 */
const fetchTransportTypesDetail = async (id) => {
  try {
    const res = await axios.get(`${urls.transportTypeDetail}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * create and edit transport Type
 * @param {Object} form submitted form
 * @param {String} form.name transportType name
 * @param {Number} form.length transportType length
 * @param {Number} form.width transportType width
 * @param {Number} form.height transportType height
 * @param {Number} form.volume transportType volume
 * @param {Number} form.maxWeight transportType maxWeight
 * @param {String} form.imageUrl transportType imageUrl
 * @param {Number} [transportTypeId] Transport Type Id
 */
const createEditTransportType = async (form, transportTypeId) => {
  try {
    let res;
    if (!transportTypeId) {
      res = await axios.post(urls.transportTypeDetail, form);
    } else {
      res = await axios.put(`${urls.transportTypeDetail}/${transportTypeId}`, form);
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * fetch csv template for transport types
 * @returns response data
 */
const fetchCsvTemplateData = async () => {
  try {
    const res = await axios.get(`${urls.csvTemplateDataTransportType}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * upload csv for transport types
 * @param {FormData} formData csv data
 */
const uploadCsv = async (formData, type) => {
  try {
    const res = await axios.post(
      urls.uploadCsvTransportType,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchTransportTypes,
  fetchTransportTypesDetail,
  createEditTransportType,
  fetchCsvTemplateData,
  uploadCsv,
  listTransportTypes,
};
