import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const updateClosePeriodeDefault = async (interval) => {
  try {
    const res = await axios.patch(`${urls.closePeriod}/default?interval=${interval}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updatePeriodManual = async (id) => {
  try {
    const res = await axios.patch(`${urls.closePeriod}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const closePeriodeDefault = async () => {
  try {
    const res = await axios.get(`${urls.closePeriod}/default`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const closePeriodList = async (filters, source = {}) => {
  try {
    const res = await axios.get(`${urls.closePeriod}/filter?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  closePeriodList, updateClosePeriodeDefault, updatePeriodManual, closePeriodeDefault,
};
