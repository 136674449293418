/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const fetchAddendumList = async ({
  page, size, name, sort,
}) => {
  try {
    const filters = {
      page, size,
    };
    if (name) filters.name = name;
    if (sort) filters.sort = sort;
    const res = await axios.get(`${urls.addendumList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const downloadImage = async (url) => {
  try {
    const res = await axios
      .get(url, {
        responseType: 'arraybuffer',
      });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchAddendumList,
  downloadImage,
};
