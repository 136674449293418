/* eslint-disable import/prefer-default-export */

import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const fetchTerminationContract = async ({
  page, size, companyName, sort,
}) => {
  try {
    const filters = {
      page, size, companyName,
    };
    if (sort) filters.sort = sort;
    if (companyName) filters.companyName = companyName;
    const res = await axios.get(`${urls.terminationContract}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchContractCompanyById = async ({ id }) => {
  try {
    const res = await axios.get(`${urls.contractCompanyById}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchReasonList = async () => {
  try {
    const filters = {
      page: 0, size: 10, type: 'qin(contract_end)',
    };
    const res = await axios.get(`${urls.reasonList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const submitTermination = async ({ id, payload }) => {
  try {
    const res = await axios.put(`${urls.submitTermination(id)}`, payload);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchTerminationContract,
  fetchContractCompanyById,
  fetchReasonList,
  submitTermination,
};
