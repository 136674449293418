import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';
/**
 * fetch selection list
 * @param {Object} filters selection list filter
 *  @param {Number} filters.page list pagination page number
 *  @param {Number} filters.size list pagination entry count
 *  @param {String} filters.value list pagination entry count
 */
const getListAdditionalCost = async (filters) => {
  try {
    const res = await axios.get(`${urls.additionalCost}/filter?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const postAddtionalCost = async ({
  page, size, value,
}) => {
  try {
    const filter = {
      page, size,
    };
    const res = await axios.get(`${urls.additionalCost}/filter?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getListAdditionalCost,
  postAddtionalCost,
};
