export default {
  masterShipperDatabase: 'Transporter',
  searchText: 'Cari Nama Transporter',
  photo: 'Foto',
  mouNo: 'Nomor MoU',
  contractNo: 'Nomor Kontrak',
  companyName: 'Nama Perusahaan',
  abbreviation: 'Alias/Inisial',
  companyAddress: 'Alamat',
  mouPeriodMonths: 'Durasi MoU',
  createdBy: 'Dibuat Oleh',
  updatedBy: 'Diubah Oleh',
  status: 'Status',
  pageName: 'Daftar Transporter',
};
