import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const getDashboardList = async (filters, source) => {
  try {
    const res = await axios.get(`${urls.dashboardList}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getShipmentSummary = async (source) => {
  try {
    const res = await axios.get(urls.customerSummary, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListDashboardDetail = async (id) => {
  try {
    const res = await axios.get(`${urls.dashboardDetail}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getDashboardList,
  getShipmentSummary,
  getListDashboardDetail,
};
