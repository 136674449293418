export default {
  PAGE_NAME: 'Hari Libur',
  HOLIDAY_NAME: 'Nama Hari Libur',
  START_DATE: 'Tanggal Mulai',
  END_DATE: 'Tanggal Berakhir',
  ADD_HOLIDAY: 'Tambah Hari Libur',
  SEARCH_HOLIDAY: 'Cari Hari Libur',
  DELETE_HOLIDAY_CONFIRM: 'Anda yakin akan menghapus hari libur',
  SUCCESS_EDIT_HOLIDAY: 'Berhasil memperbarui hari libur',
  SUCCESS_CREATE_HOLIDAY: 'Berhasil menambah hari libur',
};
