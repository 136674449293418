import common from './common';

const uploadCsvButtonLabel = 'Upload Csv';
const downloadCsvTemplateButtonLabel = 'Template Csv';
const uploadCsvLabelDataTypeCustomerDetail = 'Customer detail';
const uploadCsvLabelDataTypeCustomerPriceCustom = 'Customer custom price';
const uploadCsvLabelDataTypeCustomerPriceFix = 'Customer fix price';
const uploadCsvLabelDataTypeTransporterDetail = 'Rekanan 3PL detail';
const uploadCsvLabelDataTypeTransporterPriceCustom = 'Rekanan 3PL custom price';
const uploadCsvLabelDataTypeTransporterPriceFix = 'Rekanan 3PL fix price';
const uploadCsvLabelDataTypeCategory = 'Kategori';
const uploadCsvLabelDataTypeTransportType = 'Tipe Kendaraan';
const uploadCsvLabelDataTypeCostOption = 'Ba Penerimaan';
const uploadCsvLabelDataTypeMouFixedPriced = 'Fixed Price';
const uploadCsvLabelDataTypeMouFcl = 'FCL';
const uploadCsvLabelDataTypeMouFclBackhauling = 'FCL Backhauling';
const uploadCsvLabelDataTypeMouLcl = 'LCL';
const uploadCsvLabelDataTypeMouMultiLoc = 'MULTIPICK/MULTIDROP';
// const uploadCsvLabelDataTypeOriginOption = 'Keterangan lokasi asal'
// const uploadCsvLabelDataTypeDestinationOption = 'Keterangan lokasi tujuan'
const uploadCsvLabelDataTypeCancelOption = 'Alasan pembatalan';
const uploadCsvLabelDataTypeAccidentOption = 'Keterangan kecelakaan';
const uploadCsvLabelDataTypeLocation = 'Lokasi';
const uploadedCsvDataStatusListButton = 'Status data yang diupload';
const uploadedCsvDataStatusListDialogTitle = 'Status data yang telah diunggah';
const uploadedCsvDataDetailListDialogTitle = 'Detail data yang telah diunggah';
const uploadCsvSuccessMessage = 'Berhasil meng-unggah file, data akan segera di proses';
const csvListTableUploaderColumnLabel = 'Diunggah oleh';
const csvListTableUploadedAtColumnLabel = 'Diunggah pada';
const csvListTableStatusColumnLabel = 'Status proses';
const csvListTableSuccessRecordCountColumnLabel = 'Sukses';
const csvListTableFailedRecordCountColumnLabel = 'Gagal';
const csvListTablePagination = (page, length, size, totalEntry) => common.commonTablePagination(page, length, size, totalEntry);
const csvDataTypeOptions = [
  {
    label: uploadCsvLabelDataTypeCustomerDetail,
    dataType: 'customer_detail',
    databaseType: 'customer',
  },
  {
    label: uploadCsvLabelDataTypeCustomerPriceCustom,
    dataType: 'customer_price_custom',
    databaseType: 'customer',
  },
  {
    label: uploadCsvLabelDataTypeCustomerPriceFix,
    dataType: 'customer_price_fix',
    databaseType: 'customer',
  },
  {
    label: uploadCsvLabelDataTypeTransporterDetail,
    dataType: 'transporter_detail',
    databaseType: 'transporter',
  },
  {
    label: uploadCsvLabelDataTypeTransporterPriceCustom,
    dataType: 'transporter_price_custom',
    databaseType: 'transporter',
  },
  {
    label: uploadCsvLabelDataTypeTransporterPriceFix,
    dataType: 'transporter_price_fix',
    databaseType: 'transporter',
  },
  {
    label: uploadCsvLabelDataTypeCostOption,
    dataType: 'basp',
    databaseType: 'selection_info',
  },
  {
    label: uploadCsvLabelDataTypeCancelOption,
    dataType: 'cancellation',
    databaseType: 'selection_info',
  },
  {
    label: uploadCsvLabelDataTypeAccidentOption,
    dataType: 'accident',
    databaseType: 'selection_info',
  },
  {
    label: uploadCsvLabelDataTypeMouFixedPriced,
    dataType: 'mou_fixed_price',
    databaseType: 'mou',
  },
  {
    label: uploadCsvLabelDataTypeMouFcl,
    dataType: 'mou_fcl',
    databaseType: 'mou',
  },
  {
    label: uploadCsvLabelDataTypeMouFclBackhauling,
    dataType: 'mou_fcl_backhauling',
    databaseType: 'mou',
  },
  {
    label: uploadCsvLabelDataTypeMouLcl,
    dataType: 'mou_lcl',
    databaseType: 'mou',
  },
  {
    label: uploadCsvLabelDataTypeMouMultiLoc,
    dataType: 'mou_multi_pickup_drop',
    databaseType: 'mou',
  },
];

export default {
  uploadCsvButtonLabel,
  downloadCsvTemplateButtonLabel,
  uploadCsvLabelDataTypeCustomerDetail,
  uploadCsvLabelDataTypeCustomerPriceCustom,
  uploadCsvLabelDataTypeCustomerPriceFix,
  uploadCsvLabelDataTypeTransporterDetail,
  uploadCsvLabelDataTypeTransporterPriceCustom,
  uploadCsvLabelDataTypeTransporterPriceFix,
  uploadCsvLabelDataTypeCategory,
  uploadCsvLabelDataTypeTransportType,
  uploadCsvLabelDataTypeCostOption,
  // uploadCsvLabelDataTypeOriginOption,
  // uploadCsvLabelDataTypeDestinationOption,
  uploadCsvLabelDataTypeCancelOption,
  uploadCsvLabelDataTypeAccidentOption,
  uploadCsvLabelDataTypeLocation,
  uploadedCsvDataStatusListButton,
  uploadedCsvDataStatusListDialogTitle,
  uploadedCsvDataDetailListDialogTitle,
  uploadCsvSuccessMessage,
  csvListTableUploaderColumnLabel,
  csvListTableUploadedAtColumnLabel,
  csvListTableStatusColumnLabel,
  csvListTablePagination,
  csvDataTypeOptions,
  csvListTableSuccessRecordCountColumnLabel,
  csvListTableFailedRecordCountColumnLabel,
  uploadCsvLabelDataTypeMouFixedPriced,
  uploadCsvLabelDataTypeMouFcl,
  uploadCsvLabelDataTypeMouLcl,
  uploadCsvLabelDataTypeMouMultiLoc,
};
