export default {
  emailDatabase: 'Master Email',
  name: 'Email',
  nameLabel: 'Nama User',
  emailLabel: 'Email',
  pageName: 'Daftar Email',
  createdBy: 'Dibuat Oleh',
  create: 'Email',
  value: 'Tipe Biaya',
  cancel: 'Batal',
  save: 'Simpan',
  nameFormatLabel: 'Tipe Biaya',
  emailFormatLabel: 'Email',
  searchText: 'Cari Email',
  deleteEmailConfirmMsg: 'Apakah Anda yakin ingin menghapus akun email ini?',
  buttonAdd: 'Tambah Email',
};
