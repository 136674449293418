export default {
  shippingCostDatabase: 'Tipe Biaya Ongkos Kirim 3PL',
  name: 'Tipe Biaya Ongkos Kirim',
  pageName: 'Daftar Pesanan',
  createdBy: 'Dibuat Oleh',
  create: 'Tipe Biaya Ongkos Kirim 3PL',
  value: 'Tipe Biaya',
  cancel: 'Batal',
  save: 'Simpan',
  nameFormatLabel: 'Tipe Biaya',
  searchText: 'Cari Tipe Biaya',
  buttonAdd: 'Tambah Tipe Biaya',
  successEditStatus: 'Berhasil Mengubah Status',
};
