/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/no-cycle
import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const fetchContractShipperList = async (filters) => {
  try {
    const res = await axios.get(`${urls.contractShipperList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchCompanyList = async ({
  companyName, type, isReadyMou, status,
}) => {
  try {
    const filters = {
      page: 0, size: 20, isReadyMou,
    };
    if (companyName) {
      filters.name = `qlike(${companyName})`;
    }
    filters.status = `qlike(${status})`;
    if (type) filters.type = `qlike(${type})`;
    const res = await axios.get(`${urls.contractCompanyList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchMouShipperId = async ({ companyId }) => {
  try {
    const res = await axios.get(urls.contractMouShipperId(companyId));
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchMou3PLId = async ({ companyId }) => {
  try {
    const res = await axios.get(urls.contractMou3PLId(companyId));
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchContract3PLList = async (filters) => {
  try {
    const res = await axios.get(`${urls.contract3PLList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// deprecated
const uploadFile = async (payload, config) => {
  try {
    const res = await axios.post(`${urls.uploadImage}`, payload, config);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};
const downloadFile = async (payload) => {
  try {
    const res = await axios.get(`${urls.downloadImage}?${querystring.stringify(payload)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};
const uploadFileNew = async (payload) => {
  try {
    const res = await axios.post(`${urls.uploadImage}`, payload, { headers: 'Content-Type: multipart/form-data' });
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const deleteFile = async (payload) => {
  try {
    const res = await axios.post(`${urls.deleteFile}`, payload);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const submitContract = async (payload) => {
  try {
    const res = await axios.post(`${urls.submitContract}`, payload);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const submitTemplateCreation = async (payload) => {
  try {
    const res = await axios.post(`${urls.submitTemplateCreation}`, payload);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchContractApprovalHistory = async (workflowId) => {
  try {
    const res = await axios.get(`${urls.contractHistory}/${workflowId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchContractDetailById = async (contractID) => {
  try {
    const res = await axios.get(`${urls.contractDetail}/${contractID}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const submitAffirmation = async (contractId, payload) => {
  try {
    const res = await axios.put(urls.submitAffirmation(contractId), payload);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchTerminationList = async (filters) => {
  try {
    const res = await axios.get(`${urls.contractTerminationList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchAddendumList = async (filters) => {
  try {
    const res = await axios.get(`${urls.contractAddendumList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchContractByCompanyId = async (companyId) => {
  try {
    const res = await axios.get(urls.contractByCompanyId(companyId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const submitAddendum = async (payload, contractId) => {
  try {
    const res = await axios.post(urls.submitAddendum(contractId), payload);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const downloadTemplateContract = async (url) => {
  const headers = { 'Access-Control-Allow-Origin': '*' };
  try {
    const res = await axios.get(`${url}`, { responseType: 'blob', headers });
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getDetailWorkflowContract = async (id) => {
  try {
    const res = await axios.get(`${urls.detailWorkflowContract(id)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const approveContract = async (data, status) => {
  try {
    const res = await axios.post(`${urls.approveContract}/${status}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const approveInProcessContract = async (data) => {
  try {
    const res = await axios.post(`${urls.inboxAppoveContract}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const reviseInProcessContract = async (data) => {
  try {
    const res = await axios.post(`${urls.inboxReviseContract}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};
const rejectInProcessContract = async (data) => {
  try {
    const res = await axios.post(`${urls.inboxRejectContract}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const reviseContract = async (data, workflowId, workflowDetailId) => {
  try {
    const res = await axios.post(`${urls.reviseContract(workflowId, workflowDetailId)}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getDocumentContract = async (id) => {
  try {
    const res = await axios.get(`${urls.getDocumentContract(id)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const submitTemplateAddendum = async (payload) => {
  try {
    const res = await axios.post(`${urls.contractTemplateAddendum}`, payload);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchCompanyListExistsContract = async (companyName, companyType) => {
  try {
    const filters = {
      page: 0,
      size: 50,
      sort: 'companyName,asc',
    };
    if (companyName) {
      filters.companyName = companyName;
    }
    const res = await axios.get(`${urls.contractCompanyList}/contract/exists/${companyType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchCompanyListContract = async (companyName, companyType) => {
  try {
    const filters = {
      page: 0,
      size: 50,
      sort: 'companyName,asc',
    };
    if (companyName) {
      filters.companyName = companyName;
    }
    const res = await axios.get(`${urls.contractCompanyList}/contract/${companyType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const deleteDocumentContract = async (workflowId, url) => {
  try {
    const res = await axios.delete(`${urls.detailWorkflowContract(workflowId)}/document`, {
      data: {
        url,
      },
    });
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export {
  approveContract,
  fetchCompanyListExistsContract,
  fetchCompanyListContract,
  fetchContractShipperList,
  fetchCompanyList,
  fetchMouShipperId,
  fetchMou3PLId,
  fetchContract3PLList,
  fetchTerminationList,
  fetchAddendumList,
  uploadFile,
  deleteFile,
  submitContract,
  submitTemplateCreation,
  fetchContractApprovalHistory,
  fetchContractDetailById,
  submitAffirmation,
  submitAddendum,
  fetchContractByCompanyId,
  downloadTemplateContract,
  getDetailWorkflowContract,
  approveInProcessContract,
  rejectInProcessContract,
  reviseInProcessContract,
  uploadFileNew,
  reviseContract,
  getDocumentContract,
  submitTemplateAddendum,
  downloadFile,
  deleteDocumentContract,
};
