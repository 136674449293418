const PAGE_NAME = 'Pengaturan Lokasi';
const CREATE_LOCATION_PAGE_NAME = 'Buat lokasi';
const EDIT_LOCATION_PAGE_NAME = 'Ubah lokasi';

const CREATE_LOCATION_BUTTON_LABEL = 'Tambah lokasi';
const SEARCH_LOCATION_INPUT_PLACEHOLDER = 'Cari nama lokasi';

const LOCATION_NAME_COMMON = 'Nama lokasi';
const ADDRESS_COMMON = 'Alamat';
const PROVINCE_COMMON = 'Provinsi';
const CITY_COMMON = 'Kodya/Kabupaten';
const DISTRICT_COMMON = 'Kecamatan';
const SUBDISTRICT_COMMON = 'Kelurahan';

const LOCATION_NAME_TABLE_HEADER_LABEL = LOCATION_NAME_COMMON;
const ADDRESS_TABLE_HEADER_LABEL = ADDRESS_COMMON;
const PROVINCE_TABLE_HEADER_LABEL = PROVINCE_COMMON;
const CITY_TABLE_HEADER_LABEL = CITY_COMMON;
const DISTRICT_TABLE_HEADER_LABEL = DISTRICT_COMMON;
const SUBDISTRICT_TABLE_HEADER_LABEL = SUBDISTRICT_COMMON;

const LOCATION_NAME_INPUT_LABEL = LOCATION_NAME_COMMON;
const ADDRESS_INPUT_LABEL = ADDRESS_COMMON;
const POSITION_INPUT_LABEL = 'Posisi';
const LATITUDE_INPUT_LABEL = 'Latitude';
const LONGITUDE_INPUT_LABEL = 'Longitude';
const RADIUS_INPUT_LABEL = 'Radius';
const PROVINCE_INPUT_LABEL = PROVINCE_COMMON;
const CITY_INPUT_LABEL = CITY_COMMON;
const DISTRICT_INPUT_LABEL = DISTRICT_COMMON;
const SUBDISTRICT_INPUT_LABEL = SUBDISTRICT_COMMON;
const OPENING_TIME_INPUT_LABEL = 'Jam Buka';
const CLOSING_TIME_INPUT_LABEL = 'Jam Tutup';
const LOCATION_NOTE_INPUT_LABEL = 'Catatan Lokasi';

const DELETE_LOCATION_BUTTON_LABEL = 'Hapus lokasi ini';

const SUCCESS_CREATE_LOCATION_MESSAGE_TEXT = 'Berhasil membuat lokasi baru';
const SUCCESS_EDIT_LOCATION_MESSAGE_TEXT = 'Berhasil mengubah lokasi';
const POSTAL_CODE = 'Kode Pos';

const POSTAL_CODE_INPUT_LABEL = POSTAL_CODE;

const TYPE_LOCATION = 'Tipe Lokasi';
const TYPE_TABLE_HEADER = 'Tipe';
const TYPE_LIST = [
  {
    text: 'Semua Tipe',
    value: '',
  },
  {
    text: 'Laut',
    value: 'SEA',
  },
  {
    text: 'Udara',
    value: 'AIR',
  },
  {
    text: 'Darat',
    value: 'LAND',
  },
];

export default {
  PAGE_NAME,
  CREATE_LOCATION_BUTTON_LABEL,
  SEARCH_LOCATION_INPUT_PLACEHOLDER,

  LOCATION_NAME_TABLE_HEADER_LABEL,
  ADDRESS_TABLE_HEADER_LABEL,
  PROVINCE_TABLE_HEADER_LABEL,
  CITY_TABLE_HEADER_LABEL,
  DISTRICT_TABLE_HEADER_LABEL,
  SUBDISTRICT_TABLE_HEADER_LABEL,

  LOCATION_NAME_INPUT_LABEL,
  ADDRESS_INPUT_LABEL,
  POSITION_INPUT_LABEL,
  LATITUDE_INPUT_LABEL,
  LONGITUDE_INPUT_LABEL,
  RADIUS_INPUT_LABEL,
  PROVINCE_INPUT_LABEL,
  CITY_INPUT_LABEL,
  DISTRICT_INPUT_LABEL,
  SUBDISTRICT_INPUT_LABEL,
  OPENING_TIME_INPUT_LABEL,
  CLOSING_TIME_INPUT_LABEL,
  LOCATION_NOTE_INPUT_LABEL,

  CREATE_LOCATION_PAGE_NAME,
  EDIT_LOCATION_PAGE_NAME,
  DELETE_LOCATION_BUTTON_LABEL,
  SUCCESS_CREATE_LOCATION_MESSAGE_TEXT,
  SUCCESS_EDIT_LOCATION_MESSAGE_TEXT,
  TYPE_LOCATION,
  POSTAL_CODE_INPUT_LABEL,
  TYPE_TABLE_HEADER,
  TYPE_LIST,
};
