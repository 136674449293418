const SEARCHNO = 'Cari Nama Kode Area';
const TITLE_PAGE = 'Master Kode Area';
const ADD_AREACODE = 'Tambah Kode Area';
const AREACODE_NAME = 'Nama Kode Area';
const CODE = 'Kode';
const ACTION = 'Aksi';
const SUCCESS_MESSAGE_TEXT = 'Data berhasil dibuat';
const EDIT_MESSAGE_TEXT = 'Data berhasil diubah';
const KOSONG = '.';
const ADD_TEXT = 'Tambah Kode Area';
const DELETE_CONFIRM_MESSAGE = 'Apakah anda yakin ingin menghapus Kode Area ini?';
const SUCCESS_DELETE_MESSAGE_TEXT = 'Data Kode Area berhasil dihapus';
const EDIT_STATUS_MESSAGE_TEXT = 'Status berhasil diubah';

export default {
  SEARCHNO,
  TITLE_PAGE,
  CODE,
  ACTION,
  SUCCESS_MESSAGE_TEXT,
  EDIT_MESSAGE_TEXT,
  KOSONG,
  ADD_TEXT,
  ADD_AREACODE,
  AREACODE_NAME,
  DELETE_CONFIRM_MESSAGE,
  SUCCESS_DELETE_MESSAGE_TEXT,
  EDIT_STATUS_MESSAGE_TEXT,
};
