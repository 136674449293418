export default {
  DATABASE_NAME: 'Database Parameter',
  DATABASE_UPDATE: 'Database Parameter Update',
  FORCE_UPDATE: 'Force Update Android',
  PARAMETER_FOR: 'Parameter Untuk',
  PLACEHOLDER_PARAMETER_NAME: 'Cari Nama/Keterangan',
  headers: {
    PARAMETER_NAME: 'Nama Parameter',
    DESCRIPTION: 'Keterangan',
    VALUE: 'Value',
    ACTIVE: 'Aktif',
    EXPIRED: 'Kedaluwarsa',
  },
};
