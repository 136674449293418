import axios from 'axios';
import { querystring } from '../../helper/commonHelpers';
import urls from './urls';

const fetchRoles = async ({ filter }) => {
  try {
    const res = await axios.get(`${urls.role}/filter?${querystring(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchDetailRole = async ({ roleId, filter }) => {
  try {
    const res = await axios.get(`${urls.role}/${roleId}/menu/filter?${querystring(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const createRole = async ({ form }) => {
  try {
    const res = await axios.post(`${urls.role}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateRole = async ({ form, roleId }) => {
  try {
    const res = await axios.patch(`${urls.role}/${roleId}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateRoleMenu = async ({ form, roleId }) => {
  try {
    const res = await axios.put(`${urls.role}/${roleId}/menu`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getUsersRole = async ({ companyType, companyId, filter }) => {
  try {
    const res = await axios.get(`${urls.userAdminCreate}/${companyType}/company/${companyId}/filter?${querystring(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchRoles,
  fetchDetailRole,
  createRole,
  updateRole,
  updateRoleMenu,
  getUsersRole,
};
