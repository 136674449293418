export default {
  PAGE_NAME: 'Persetujuan',
  MOU_3PL: 'MoU Rekanan 3PL',
  MOU_SHIPPER: 'MoU Shipper',
  ADD_MOU: 'Tambah MoU',
  SEARCH_3PL_INPUT_PLACEHOLDER: 'Cari nama rekanan 3PL',
  SEARCH_SHIPPER_INPUT_PLACEHOLDER: 'Cari nama shipper',
  MOU_PARENT_NUMBER_TABLE_HEADER_LABEL: 'Nomor MoU Sebelumnya',
  MOU_NUMBER_TABLE_HEADER_LABEL: 'Nomor MoU',
  MOU_EXPIRED_TABLE_HEADER_LABEL: 'Expired MoU',
  COMPANY_NAME_TABLE_HEADER_LABEL: 'Nama Perusahaan',
  ALIAS_INITIAL_TABLE_HEADER_LABEL: 'Alias/Inisial',
  MOU_DURATION_TABLE_HEADER_LABEL: 'Durasi MoU',
  CREATED_BY_TABLE_HEADER_LABEL: 'Dibuat Oleh',
  STATUS_TABLE_HEADER_LABEL: 'Status',
  APPROVAL_STATUS_TABLE_HEADER_LABEL: 'Status Persetujuan',
  ACTION_TABLE_HEADER_LABEL: 'Aksi',
  MOU: 'MoU',
  COOPERATION_INFORMATION: 'Informasi Kerjasama',
  NO_CONTRACT: 'No Kontrak',
  PERIODIC_COOPERATION: 'Periode Kerjasama',
  PERIODE_START_DOC: 'Periode Tanggal Dokumen MoU',
  AUTO_EXTENDOR: 'Perpanjang Otomatis',
  TERM_OF_PAYMENT: 'Term Of Payment',
  PAYMENT_METHOD: 'Metode Pembayaran Via Transfer',
  PAYMENT_PIC: 'Nama PIC Pembayaran',
  PAYMENT_PIC_NUMBER_PHONE: 'No Telepon PIC Pembayaran',
  OPERATIONAL_PIC: 'Nama PIC Opersional',
  POSITION_PIC_OPERATIONAL: 'Jabatan PIC Opersional',
  CLIENTS_USING_INSURANCE: 'Klien Menggunakan Asuransi',
  FINANCIAL_STATEMENTS_KAP: 'Laporan Keuangan diaudit KAP',
  AVERAGE_VALUE_SHIPEMENT: 'Average Value per Shipment',
  TOTAL_ASSET: 'Total Aset',
  STARTUP_CAPITAL: 'Modal Usaha',
  BANK_NAME: 'Nama Bank',
  SHAREHOLDERS: 'Pemegang Saham',
  DISTRIBUTARY: 'Cabang',
  ACCOUNT_OWNER: 'Nama Pemilik Rekening',
  ACCOUNT_NUMBER: 'Nomor Rekening',
  TYPE_OF_SERVICE_USED: 'Jenis Jasa Yang Digunakan',
  TO: 'Sampai',
  EXTEND: 'Extend',
  REASON: 'Alasan',
  LOCATION_DATA: 'Data Lokasi',
  LOCATION: 'Lokasi',
  TRANSPORT_TYPE: 'Tipe Kendaraan',
  TRANSPORTER_NAME: 'Nama Kendaraan',
  DESCRIPTION: 'Deskripsi',
  IMAGE: 'Gambar',
  PRICE_SETTING: 'Pengaturan Harga',
  NUMBER_OF_UNIT: 'Jumlah Unit',
  NUMBER_OF_LIMIT: 'Jumlah Limit / Unit',
  INTERVAL: 'Interval',
  PRICE_INTERVAL: 'Harga Per Interval / unit',
  FIXED_PRICE: 'Fixed Price',
  ORIGIN: 'Lokasi Asal',
  DESTINATION: 'Lokasi Tujuan',
  PRICE: 'Harga',
  A_A: 'AUTO ASSIGN',
  TRANSPORTER: 'Kendaraan',
  APPROVAL_HISTORY: 'Riwayat Persetujuan',
  POSITION: 'Jabatan',
  NAME: 'Nama',
  ACTION: 'Aksi',
  TIME_ACTION: 'Waktu Aksi',
  NOTE: 'Catatan',
  LOCATION_NAME: 'Nama Lokasi',
  ADDRESS: 'Alamat',
  CITY: 'Kota',
  DISTRICT: 'Kabupaten',
  GOOGLE_MAPS: 'Google Maps',
  P2P_LENDING: 'P2P Lending',
  TOP: 'TOP',
  ADMIN_RATE: 'Biaya Admin',
  INTEREST_RATE: 'Biaya Bunga',
  LICENSE_PLATE: 'Nomor kendaraan',
  APPLY: 'Tetapkan',
  LIMIT: 'Limit',
  INPUT_LIMIT: 'Masukkan Limit',
  ACTIVE: 'Aktif',
  NUMBER_OF_RITASE: 'Jumlah Ritase',
  RITASE_OF_LIMIT: 'Ritase Limit',
  PRICE_OF_INTERVAL: 'Harga Interval',
  NUMBER_OF_VEHICLES: 'Jumlah Kendaraan',
  NO_DATA: 'Tidak Ada Data',
  FILTER_FCL: 'Filter FCL',
  FILTER: 'Filter',
  FILTER_FCL_BACKHAULING: 'Filter Backhauling',
  ADDENDUM: 'Addendum',
  SET_VEHICLE_NUMBER: 'Tetapkan No Kendaraan',
  UPLOAD_CSV: 'Upload CSV',
  TEMPLATE_CSV: 'Template CSV',
  UPLOADED_DATA_STATUS: 'Status data yang diupload',
  SELECT_SHIPPER: 'Pilih Shipper',
  THERE_ARE_CHANGES: 'Ada Perubahan',
  PREVIOUS_DATA: 'Data Sebelumnya',
  LATEST_DATA: 'Data Terbaru',
  DATA_HISTORY: 'Histori Data',
};
