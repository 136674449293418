import axios from 'axios';
import { querystring } from '../../helper/commonHelpers';
import urls from './urls';

/**
 * get p2p lending list
 * @param {Object} filter list P2P lending
 */
const fetchP2pLendingList = async (filter) => {
  try {
    const res = await axios.get(`${urls.p2pLending}/filter?${querystring(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get detail p2p lending by id list
 * @param {Number} id p2p lending
 */
const fetchP2pLendingDetail = async (id) => {
  try {
    const res = await axios.get(`${urls.p2pLending}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get document per detail
 * @param {Number} id p2p lending
 * @param {Number} detailId p2p lending
 */
const fetchP2pLendingDocument = async (id, detailId) => {
  try {
    const res = await axios.get(`${urls.p2pLending}/${id}/${detailId}/document`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * update status p2p lending
 * @param {Number} id p2p lending
 * @param {Number} detailId p2p lending
 * @param {String} status p2p lending (APPROVED | REJECTED)
 * @param {String} notes p2p lending
 */
const updateP2pLendingStatus = async (id, detailId, status, notes) => {
  try {
    const res = await axios.patch(`${urls.p2pLending}/${id}/${detailId}/status/${status}?notes=${notes}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * update payment p2p lending
 * @param {Number} id p2p lending
 * @param {Number} detailId p2p lending
 */
const updateP2pLendingPayment = async (id, detailId, form) => {
  try {
    const res = await axios.patch(`${urls.p2pLending}/${id}/${detailId}/payment`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchP2pLendingList,
  fetchP2pLendingDetail,
  fetchP2pLendingDocument,
  updateP2pLendingStatus,
  updateP2pLendingPayment,
};
