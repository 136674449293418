import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const verifyEmail = async function verifyEmail({ email, phoneNo }) {
  try {
    const data = {
      email,
      phoneNo,
    };
    const parameterizedUrl = urls.emailVerification;
    const res = await axios.post(parameterizedUrl, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const activateAccount = async function activateAccount(
  {
    email,
    phoneNo,
    password,
    fullName,
  },
) {
  try {
    const data = {
      fullName,
      email,
      phoneNo,
      password,
    };
    const res = await axios.post(urls.accountActivation, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const decodeToken = async function decodeToken(token) {
  try {
    const res = await axios.get(`${urls.decodeToken}?token=${token}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const activation = async function activation({ email, phoneNo, password }) {
  try {
    const data = {
      email,
      phoneNo,
      password,
    };
    const res = await axios.post(`${urls.register}/activation`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const setResetPassword = async function setResetPassword(
  {
    email,
    password,
  },
) {
  try {
    const data = {
      email,
      password,
    };
    const res = await axios.patch(urls.setResetPassword, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const setChangePassword = async function setChangePassword(
  {
    oldPassword,
    newPassword,
  },
) {
  try {
    const data = {
      oldPassword,
      newPassword,
    };
    const res = await axios.patch(urls.setChangePassword, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  verifyEmail,
  activateAccount,
  decodeToken,
  activation,
  setResetPassword,
  setChangePassword,
};
