import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const fetchList = async (filter) => {
  try {
    const res = await axios.get(`${urls.invoiceCustomerCancel}/fixed-price/filter?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchListDetail = async (invoiceId, filter) => {
  try {
    const res = await axios.get(
      `${urls.invoiceFixedPrice}/detail/filter/${invoiceId}?${querystring.stringify(filter)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateInvoice = async (invoiceId) => {
  try {
    const res = await axios.patch(`${urls.invoiceCustomerCancel}/${invoiceId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchList,
  fetchListDetail,
  updateInvoice,
};
