import axios from 'axios';
import urls from './urls';

const getShipperRegistrationByWorkflowId = async (id) => {
  try {
    const res = await axios.get(`${urls.listShipperCompanyRegistration}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getTransporterRegistrationByWorkflowId = async (id) => {
  try {
    const res = await axios.get(`${urls.listTransporterCompanyRegistration}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getShipperRegistrationByWorkflowId,
  getTransporterRegistrationByWorkflowId,
};
