import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const emailSearchList = async (val, companyId) => {
  try {
    const filters = {
      email: `qlike(${val})`, companyId,
    };
    const res = await axios.get(`${urls.emailListInvoice}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const emailList = async (companyId) => {
  try {
    const filters = {
      companyId,
    };
    const res = await axios.get(`${urls.emailListInvoice}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchInvoice = async ({
  page, name, size, sort, dateFrom, dateTo, invoiceNo, statusInvoice, typeInvoice, billedToCompanyId, companyType,
}) => {
  try {
    const filters = {
      page, size, invoiceNo, dateFrom, dateTo, statusInvoice, typeInvoice, billedToCompanyId,
    };
    if (name) {
      filters.invoiceNo = `qLike(${name})`;
    } else {
      delete filters.invoiceNo;
    }
    if (statusInvoice.length > 0) {
      filters.statusInvoice = `qin(${statusInvoice})`;
    } else {
      delete filters.statusInvoice;
    }
    if (typeInvoice.length > 0) {
      filters.typeInvoice = `qin(${typeInvoice})`;
    } else {
      delete filters.typeInvoice;
    }
    if (billedToCompanyId) {
      filters.billedToCompanyId = billedToCompanyId;
    } else {
      delete filters.billedToCompanyId;
    }
    if (sort) {
      filters.sort = sort;
    } else {
      filters.sort = 'createdAt,desc';
    }
    const res = await axios.get(
      `${urls.invoiceGetList}/${companyType}?${querystring.stringify(filters)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchInvoiceOrder = async (filters) => {
  try {
    const res = await axios.get(
      `${urls.invoiceCreateOrder}?${querystring.stringify(filters)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const companySearch = async (companyName = '') => {
  try {
    const filters = {
      page: 0,
      size: 20,
      sort: 'companyName,asc',
    };
    if (companyName) {
      filters.companyName = companyName;
    }
    const res = await axios.get(`${urls.getListTransporter}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const companySearchShipper = async (companyName = '') => {
  try {
    const filters = {
      page: 0,
      size: 20,
      sort: 'companyName,asc',
    };
    if (companyName) {
      filters.companyName = companyName;
    }
    const res = await axios.get(`${urls.getListShipper}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const createInvoice = async (form) => {
  try {
    const res = await axios.post(`${urls.invoiceCreateShipper}`, { ...form });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const invoiceDetail = async (invoiceId) => {
  try {
    const res = await axios.get(urls.invoiceDetail(invoiceId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateInvoice = async (form) => {
  try {
    const res = await axios.put(`${urls.invoiceCreateShipper}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

// INVOICE FIXED PRICE
const listFixedPriceInvoice = async (filters, companyType = 'SHIPPER') => {
  try {
    const res = await axios.get(
      `${urls.invoiceFixedPrice}/filter/${companyType}?${querystring.stringify(filters)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const listDetailFixedPriceInvoice = async (filters, invoiceId = 0) => {
  try {
    const res = await axios.get(
      `${urls.invoiceFixedPrice}/detail/filter/${invoiceId}?${querystring.stringify(filters)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const listDetailFixedPriceDetailOrderInvoice = async (filters, invoiceId = 0) => {
  try {
    const res = await axios.get(
      `${
        urls.invoiceFixedPrice
      }/limit/filter/${invoiceId}/detail?${querystring.stringify(filters)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateFixedPriceDetailOrderInvoice = async (form) => {
  try {
    const res = await axios.put(`${urls.invoiceFixedPrice}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const listFixedPriceLimitInvoice = async (invoiceId, limitId, companyType) => {
  try {
    const res = await axios.get(
      `${urls.invoiceFixedPrice}/limit/filter/${companyType}/${invoiceId}/${limitId}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const createFixedPriceInvoice = async (form) => {
  try {
    const res = await axios.post(urls.invoiceFixedPrice, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const exportPdfInvoice = async (invoiceId) => {
  try {
    const res = await axios.get(`${urls.invoiceExport}/template/pdf/${invoiceId}`, { responseType: 'blob' });
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

const exportInvoiceProformaDetail = async ({ invoiceId, invoiceType, exportFile }) => {
  try {
    const filters = {
      fileName: 'proforma-invoice-detail',
    };
    const res = await axios.get(`${urls.invoiceExportProforma}/${invoiceType}/${exportFile}/${invoiceId}?${querystring.stringify(filters)}`, { responseType: 'blob' });
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

const exportPdfInvoiceProforma = async ({ invoiceId, invoiceType, exportFile }) => {
  try {
    const filters = {
      fileName: 'proforma-invoice',
    };
    const res = await axios.get(`${urls.invoiceExportProforma}/${invoiceType}/${exportFile}/${invoiceId}?${querystring.stringify(filters)}`, { responseType: 'blob' });
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

const changeStatusInvoice = async (invoiceId, status, dateInvoice) => {
  try {
    const res = await axios.patch(`${urls.invoiceCreateShipper}/${invoiceId}/status/${status}?statusDate=${dateInvoice}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const sendEmail = async (form, invoiceId) => {
  try {
    const res = await axios.post(`${urls.invoiceExport}/email/${invoiceId}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateFixedPriceInvoice = async (form) => {
  try {
    const res = await axios.put(`${urls.invoiceFixedPrice}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  emailSearchList,
  emailList,
  fetchInvoice,
  fetchInvoiceOrder,
  companySearch,
  companySearchShipper,
  createInvoice,
  invoiceDetail,
  updateInvoice,
  listFixedPriceInvoice,
  listDetailFixedPriceInvoice,
  listDetailFixedPriceDetailOrderInvoice,
  updateFixedPriceDetailOrderInvoice,
  listFixedPriceLimitInvoice,
  createFixedPriceInvoice,
  exportPdfInvoice,
  exportInvoiceProformaDetail,
  changeStatusInvoice,
  exportPdfInvoiceProforma,
  sendEmail,
  updateFixedPriceInvoice,
};
