import dayjs from 'dayjs';
import Vue from 'vue';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

/**
 * parse unix timestamp to date dd MMM YYYY
 * @param {Number} t Unix TimeStamp
 */
export const dateFormat = (t) => {
  if (!t) return '-';
  const monthNames = [
    'Jan', 'Feb', 'Mar',
    'Apr', 'Mei', 'Jun',
    'Jul', 'Agu', 'Sep',
    'Okt', 'Nov', 'Des',
  ];
  const date = new Date(t);
  const getDay = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const day = getDay < 10 ? `0${getDay}` : getDay;
  return `${day} ${monthNames[monthIndex]} ${year}`;
};

/**
 * parse date to HH:mm
 * @param {Number} t date
 */
export const timeFormat = (date) => dayjs(date).format('HH:mm');

/**
 * Convert utc timestamp to relative time string
 * @param previous previous time UTC timestamp
 */
export const perseRelativeTime = (previous) => {
  if (!previous) return null;
  const current = new Date();
  previous = new Date(previous);
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + (Math.round(elapsed / 1000) > 1 ? ' detik yang lalu' : ' detik yang lalu');
  } if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + (Math.round(elapsed / msPerMinute) > 1 ? ' menit yang lalu' : ' menit yang lalu');
  } if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + (Math.round(elapsed / msPerHour) > 1 ? ' jam yang lalu' : ' jam yang lalu');
  } if (elapsed < msPerMonth) {
    return `sekitar ${Math.round(elapsed / msPerDay)}${Math.round(elapsed / msPerDay) > 1 ? ' hari yang lalu' : ' hari yang lalu'}`;
  } if (elapsed < msPerYear) {
    return `sekitar ${Math.round(elapsed / msPerMonth)}${Math.round(elapsed / msPerMonth) > 1 ? ' bulan yang lalu' : ' bulan yang lalu'}`;
  }
  return `sekitar ${Math.round(elapsed / msPerYear)}${Math.round(elapsed / msPerYear) > 1 ? ' tahun yang lalu' : ' tahun yang lalu'}`;
};

/**
 * parse unix timestamp to date DD-MMMM-YYYY
 * @param {Number} t Unix TimeStamp
 */
export const getDate = (t) => (t ? dayjs.unix(t / 1000).format('DD MMMM YYYY') : '');

export const statusReport = (status) => {
  switch (status) {
    case 'TIDAK AKTIF':
      return 'Tidak Aktif';
    case 'AKTIF':
      return 'Aktif';
    default:
      return status;
  }
};

/**
 * parse unix timestamp to time HH.MM
 * @param {Number} t Unix TimeStamp
 */
export const getTime = (t) => (t ? dayjs.unix(t / 1000).format('HH.mm') : '');

export const dateDiff = (timestamp) => {
  let d = Math.abs(timestamp); // delta
  const r = {}; // result
  const s = { // structure
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
  };

  Object.keys(s).forEach((key) => {
    r[key] = Math.floor(d / s[key]);
    d -= r[key] * s[key];
  });

  const string = (r.year ? (`${r.year} tahun `) : '')
    + (r.month ? (`${r.month} bulan `) : '')
    + (r.week ? (`${r.week} minggu `) : '')
    + (r.day ? (`${r.day} hari `) : '')
    + (r.hour ? (`${r.hour} jam `) : '')
    + (r.minute ? (`${r.minute} menit `) : '');
  return string;
};

export const getDate4 = (t) => {
  if (!t) return '-';
  const monthNames = [
    'Januari', 'Febuari', 'Maret',
    'April', 'Mei', 'Juni',
    'Juli', 'Agustus', 'September',
    'Oktober', 'November', 'Desember',
  ];
  const date = new Date(t);
  const getDay = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const day = getDay < 10 ? `0${getDay}` : getDay;
  return `${day} ${monthNames[monthIndex]} ${year}`;
};

/**
 * Returns thousand separated number as string
 * @param {String|Number} i number to be formatted
 */
export const thousandSeparated = (i) => {
  if (!i) return 0;
  const string = i.toString();
  const number = Number(string.replace(/[^0-9.-]+/g, '')); // cleanse input to only process number
  const rounded = Math.round(number);
  const formatter = new Intl.NumberFormat('id');
  return formatter.format(rounded);
};

/**
 * parse unix timestamp to ISO date YYYY-MM-DD
 * @param {Number} t unix timestamp
 * @returns iso date string
 */
export const getISODate = (t) => (t ? dayjs.unix(t / 1000).format('YYYY-MM-DD') : '');

/**
 * parse ISO date YYYY-MM-DD to unix timestamp
 * @param {String} s ISO date YYYY-MM-DD
 * @returns unix timestamp
 */
export const getUnixTime = (s) => (s ? dayjs(s, 'YYYY-MM-DD').valueOf() : '');

export const statusActionWorkflow = (status) => {
  switch (status) {
    case 'SUBMIT':
      return 'Menunggu Persetujuan';
    case 'APPROVE':
      return 'Disetujui';
    case 'REJECT':
      return 'Ditolak';
    case 'REVISE':
      return 'Revisi';
    default:
      return '-';
  }
};

export const statusOrder = (type) => {
  switch (type) {
    case 'PESANAN_DIBUAT': return 'Pesanan Dibuat';
    case 'PENJEMPUTAN': return 'Penjemputan';
    case 'DILOKASI_PENJEMPUTAN': return 'Dilokasi Penjemputan';
    case 'PENGIRIMAN': return 'Pengiriman';
    case 'DITERIMA_UTUH': return 'Diterima Utuh';
    case 'TERKONFIRMASI': return 'Terkonfirmasi';
    case 'MENUNGGU_KONFIRMASI_TRANSPORTER': return 'Menunggu Konfirmasi Transporter';
    case 'MENUNGGU_KONFIRMASI': return 'Menunggu Konfirmasi';
    case 'MENUNGGU_KONFIRMASI_DRIVER': return 'Menunggu Konfirmasi Driver';
    case 'DRIVER_WAKTU_HABIS': return 'Driver Waktu Habis';
    case 'DOKUMEN_POD_DIKIRIM': return 'Dokumen POD Dikirim';
    case 'DOKUMEN_POD_LENGKAP': return 'Dokumen POD Lengkap';
    case 'TERIMA_ASSIGNMENT': return 'Terima Assignment';
    case 'BA_PENERIMAAN_KONFIRMASI_SHIPPER': return 'BAP - Konfirmasi Shipper';
    case 'BARANG_DITERIMA_UTUH': return 'Barang Diterima Utuh';
    case 'BARANG_DITERIMA_TIDAK_UTUH': return 'Barang Diterima Tidak Utuh';
    case 'BAP_PENERIMAAN_KONFIRMASI_SHIPPER': return 'BAP - Konfirmasi Shipper';
    case 'BA_PENERIMAAN_DITERIMA_GUDANG': return 'BA Penerimaan - Diterima Gudang';
    case 'BA_PENERIMAAN_KEMBALI_KE_GUDANG_ASAL': return 'BA Penerimaan - Kembali Ke Gudang asal';
    case 'BA_PENERIMAAN_GANTI_LOKASI_TUJUAN': return 'BA Penerimaan - Ganti Lokasi Tujuan';
    case 'BELUM_KIRIM_DOKUMEN': return 'Belum Kirim Dokumen';
    case 'DI_LOKASI_PENJEMPUTAN': return 'Di Lokasi Penjemputan';
    case 'WAKTU_HABIS': return 'Waktu Habis';
    case 'DIBATALKAN': return 'Dibatalkan';
    case 'KEDALUWARSA': return 'Kedaluwarsa';
    case 'DITOLAK': return 'Ditolak';
    case 'DOKUMEN_TERKIRIM': return 'Dokumen Terkirim';
    case 'DI_LOKASI_TUJUAN': return 'Di Lokasi Tujuan';
    // MULTIMODA
    case 'MENUNGGU_MUAT_BARANG': return 'Menunggu Muat Barang';
    case 'MUAT_BARANG': return 'Muat Barang';
    case 'MENYEBRANG_PENGIRIMAN': return 'Menyebrang Pengiriman';
    case 'DALAM_PENERBANGAN': return 'Dalam Penerbangan';
    case 'DALAM_PENYEBRANGAN': return 'Dalam Penyebrangan';
    case 'SAMPAI_BANDARA': return 'Sampai Bandara';
    case 'SAMPAI_PELABUHAN': return 'Sampai Pelabuhan';
    case 'BONGKAR_MUATAN': return 'Bongkar Muatan';
    case 'DOKUMEN_LENGKAP': return 'Dokumen Lengkap';
    case 'DOKUMEN_TIDAK_LENGKAP': return 'Dokumen Tidak Lengkap';
    default: return type;
  }
};

// EX:
// form: {
//   price : 1
// }

export function formatAsCurrency(value, dec) {
  dec = dec || 0;
  if (value === null) {
    return 0;
  }
  return `${value.toFixed(dec).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
}

export const statusPOD = (type) => {
  switch (type) {
    case 'BARANG_DITERIMA_UTUH': return 'Barang Diterima Utuh';
    case 'DI_LOKASI_TUJUAN': return 'Di Lokasi Tujuan';
    case 'DITOLAK': return 'Ditolak';
    default: return '-';
  }
};

export const typeDokumenPOD = (type) => {
  switch (type) {
    case 'FOTO_BONGKAR_BARANG': return 'Foto Bongkar Barang';
    case 'FOTO_MUAT_BARANG': return 'Foto Muat Barang';
    case 'FOTO_SURAT_JALAN': return 'Foto Surat Jalan';
    case 'FOTO_SURAT_SERAH_TERIMA': return 'Foto Surat Serah Terima';
    case 'FOTO_BASP': return 'Foto BASP';
    case 'FOTO_BAST': return 'Foto BAST';
    case 'FOTO_BUKTI_BASP': return 'Foto Bukti BASP';
    case 'FOTO_BUKTI_BAST': return 'Foto Bukti BAST';
    case 'SURAT_PERINTAH_KIRIMAN': return 'Surat Perintah Kiriman';
    default: return 'Foto Dokumen';
  }
};

export const fileUploadValidation = (event, fileSizeMin = 1024000) => {
  const { size, name } = event.target.files[0];
  const fileType = name.split('.').pop().toLowerCase();
  // 1024000 => 1MB
  const fileTypeSupport = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'];
  if (!fileTypeSupport.includes(fileType)) {
    Vue.prototype.$dialog.notify.error('Format file tidak didukung');
    return false;
  }
  if (size > fileSizeMin) {
    Vue.prototype.$dialog.notify.error(`Ukuran file tidak boleh lebih dari ${fileSizeMin / 1024000}MB.`);
    return false;
  }
  return true;
};

export const colourOrder = (type) => {
  switch (type) {
    case 'SCHEDULED': return 'orange';
    case 'PROCESS': return 'blue lighten-1';
    case 'FINISHED': return '#66BB6A';
    case 'CANCELLED': return 'red';
    default: return 'grey';
  }
};

export const colourDashboard = (type) => {
  switch (type) {
    case 'Selesai': return '#66BB6A';
    case 'Dalam Proses': return 'blue lighten-1';
    default: return 'orange';
  }
};

export const typePriceInvoice = (type) => {
  switch (type) {
    case 'FIXED_PRICE': return 'Fixed Price';
    case 'ONCALL': return 'On Call';
    case 'TONNAGE': return 'Tonase';
    case 'CARTON': return 'Karton';
    case 'CUBIC': return 'Kubikasi';
    default: return '-';
  }
};

export const rulesNotes = (v, isRequired, length, label) => {
  if ((!v || v.trim() === '') && isRequired) return `${label} wajib diisi`;
  if (v && v.length > length) return `${label} maksimal ${length} karakter`;
  // REGEX FOR SPECIAL CHARACTER ALLOWED (.,)
  // const patternNotes = /[^A-Za-z0-9,.\n\s]/;
  // if (patternNotes.test(v)) return `${label} tidak boleh menggunakan spesial karakter`;
  return true;
};

export const querystring = (filters) => {
  const strings = '';
  let result = '';
  Object.keys(filters).forEach((filter) => {
    const text = `${filter}=${filters[filter]}`;
    result = strings.concat(`${result}${result ? '&' : ''}`, text);
  });
  return result;
};

export const renamePeriode = (period) => {
  switch (period) {
    case 'FIX_WEEKLY': return 'Mingguan';
    case 'FIX_MONTHLY': return 'Bulanan';
    default: return period;
  }
};

export const convertTextTypeCost = (unitPrice) => {
  switch (unitPrice) {
    case 'FIXED_PRICE': return 'Fixed Price';
    case 'CARTON': return 'Karton';
    case 'TONNAGE': return 'Tonase';
    case 'CUBIC': return 'Kubikasi';
    case 'MASSA': return 'Massa';
    case 'KILO': return 'Kilo';
    case 'BOX': return 'Box';
    case 'ONCALL': return 'Oncall';
    default: return unitPrice;
  }
};

export const convertTypeLocation = (type) => {
  switch (type) {
    case 'SEA': return 'Laut';
    case 'LAND': return 'Darat';
    case 'AIR': return 'Udara';
    default: return type;
  }
};

export const skipEmptyStringObject = (newObject) => {
  const skipEmptyString = {};
  Object.keys(newObject).forEach((filter) => {
    if (newObject[filter]
      || typeof newObject[filter] === 'number'
      || typeof newObject[filter] === 'boolean'
    ) {
      const value = newObject[filter];
      if (typeof value === 'string') value.replace(/\+/, '%2B');
      skipEmptyString[filter] = value;
    }
  });
  return skipEmptyString;
};

export const handleSortBy = ({ defaultValue, sortBy, sortDesc }) => {
  let sort = defaultValue || '';
  if (sortBy.length > 0) {
    const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
    sort = `${sortBy[0]},${isDesc}`;
  }
  return sort;
};

export const filterDuplicateValues = (arr) => {
  const data = [];
  arr.map((a) => JSON.stringify(a)).forEach((b, index) => {
    if (!data.find((d) => b === JSON.stringify(d))) data.push(arr[index]);
  });
  return data;
};

export const getDateTimeTz = () => {
  const date = new Date();
  return date.toString().split('GMT')[1].replace(/0|\D/g, '');
};

export const transportModel = (type) => {
  switch (type) {
    case 'AIR': return 'Udara';
    case 'LAND': return 'Darat';
    case 'SEA': return 'Laut';
    default: return '-';
  }
};

export const replaceNoteToHtml = (notes = '') => {
  if (!notes) return '-';
  let stringNotes = JSON.stringify(notes);
  stringNotes = stringNotes.replace(/^"|"$/g, '');
  stringNotes = stringNotes.replace(/\\n/g, '<br>');
  return stringNotes;
};

export const handlerPagination = (_this, newVal) => {
  const oldQuery = JSON.stringify(_this.$route.query).replace(/"/g, '');
  const newQuery = JSON.stringify(skipEmptyStringObject({
    ..._this.$route.query,
    page: newVal.page,
    itemsPerPage: newVal.itemsPerPage,
    sort: handleSortBy({ sortBy: newVal.sortBy, sortDesc: newVal.sortDesc }),
  })).replace(/"/g, '');
  if (oldQuery !== newQuery) {
    _this.$router.replace({
      query: skipEmptyStringObject({
        ..._this.$route.query,
        page: newVal.page,
        itemsPerPage: newVal.itemsPerPage,
        sort: handleSortBy({ sortBy: newVal.sortBy, sortDesc: newVal.sortDesc }),
      }),
    });
  }
};

export const defaultPagination = () => {
  const search = window.location.search.split('?')[1];
  let parameters = {};
  if (search) {
    const pattern = /\w+=[a-zA-Z0-9,]*/g;
    parameters = JSON.parse(`{"${decodeURI(search)
      .match(pattern)
      .join('&')
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')}"}`);
  }
  return {
    itemsPerPage: +parameters.itemsPerPage || 10,
    page: +parameters.page || 1,
    sortBy: parameters.sort ? [parameters.sort.split(',')[0]] : [],
    sortDesc: parameters.sort
      ? [parameters.sort.split(',')[1] === 'DESC']
      : [],
  };
};

export const formatMoney = (amount) => (amount && `Rp. ${amount.toLocaleString('id-ID')}`) || 0;
