import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const fetchTax = async ({
  page, size, sort, fromDate, toDate, companyId,
}) => {
  try {
    const filters = {
      page, size, fromDate, toDate, companyId,
    };
    if (sort) {
      filters.sort = sort;
    } else {
      delete filters.sort;
    }
    if (companyId) {
      filters.companyId = companyId;
    } else {
      delete filters.companyId;
    }
    const res = await axios.get(
      `${urls.fetchTexList}?${querystring.stringify(filters)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const companySearch = async (companyName = '') => {
  try {
    const filters = {
      page: 0,
      size: 20,
      sort: 'companyName,asc',
    };
    if (companyName) {
      filters.companyName = companyName;
    }
    const res = await axios.get(`${urls.getListTransporter}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const exportTax = async (filters) => {
  try {
    const res = await axios.get(`${urls.exportTax}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchTax,
  companySearch,
  exportTax,
};
