import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const getListEmail = async (filters) => {
  try {
    const res = await axios.get(`${urls.emailList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const emailAddEdit = async (form, emailId) => {
  try {
    let res;
    const {
      picName,
      email,
    } = form;
    const data = {
      picName,
      email,
    };
    if (emailId) {
      res = await axios.put(`${urls.emailAddEdit(emailId)}`, data);
      return res.data;
    }
    res = await axios.post(`${urls.emailAddEditList}`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteEmail = async (emailId) => {
  try {
    const res = await axios.delete(urls.deleteEmail(emailId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchEmail = async (emailId) => {
  try {
    const res = await axios.get(`${urls.emailAddEdit(emailId)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getListEmail,
  emailAddEdit,
  deleteEmail,
  fetchEmail,
};
