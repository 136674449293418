/* eslint-disable import/no-cycle */
import axios from 'axios';
import urls from './urls';
import { getTokenFcm } from '../../helper/fcm';
import { querystring } from '../../helper/commonHelpers';

/**
 * fetch fcm token for notification
 */
const fetchFcmToken = async () => {
  try {
    const currentToken = await getTokenFcm();
    return currentToken;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * fetch user's notification list
 * @param {Number} page notification page number
 */
const fetchNotificationList = async (filters) => {
  try {
    const res = await axios.get(`${urls.notification}?${querystring(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const setNotificationRead = async (notifId) => {
  try {
    const res = await axios.patch(`${urls.notification}/${notifId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const readAllNotifications = async (UserId) => {
  try {
    const res = await axios.patch(`${urls.notification}?userId=${UserId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchNotificationList,
  fetchFcmToken,
  setNotificationRead,
  readAllNotifications,
};
