export default {
  PAGE_NAME: 'Close Periode',
  PERIODE: 'Periode',
  CLOSE_PERIODE_DATE: 'Tanggal Close Periode',
  JEDA_CLOSE_PERIOD: 'Jeda Close Periode',
  PERIODE_SETTING: 'Close Periode Secara',
  STATUS: 'Status',
  CLOSE_MANUAL: 'Close Manual',
  ADD_CLOSE_PERIODE: 'Setting Close Periode',
  TYPE_CLOSE_PERIODE: 'Close Periode Secara',
  JEDA_CLOSE_PERIODE: 'Jeda Periode Secara',
  SEARCH_CLOSE_PERIODE: 'Cari Close Periode',
  SUCCESS_CREATE_CLOSE_PERIODE: 'Berhasil menambah close periode',
};
