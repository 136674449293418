const SEARCHNO = 'Cari Nama Vaksin';
const TITLE_PAGE = 'Master Vaksin';
const ADD_VACCINE = 'Tambah Vaksin';
const VACCINE_NAME = 'Nama Vaksin';
const STATUS = 'Status';
const ACTION = 'Aksi';
const SUCCESS_MESSAGE_TEXT = 'Data berhasil dibuat';
const EDIT_MESSAGE_TEXT = 'Data berhasil diubah';
const KOSONG = '.';
const ADD_TEXT = 'Tambah Vaksin';
const DELETE_CONFIRM_MESSAGE = 'Apakah anda yakin ingin menghapus vaksin ini?';
const SUCCESS_DELETE_MESSAGE_TEXT = 'Data vaksin berhasil dihapus';
const EDIT_STATUS_MESSAGE_TEXT = 'Status berhasil diubah';

export default {
  SEARCHNO,
  TITLE_PAGE,
  STATUS,
  ACTION,
  SUCCESS_MESSAGE_TEXT,
  EDIT_MESSAGE_TEXT,
  KOSONG,
  ADD_TEXT,
  ADD_VACCINE,
  VACCINE_NAME,
  DELETE_CONFIRM_MESSAGE,
  SUCCESS_DELETE_MESSAGE_TEXT,
  EDIT_STATUS_MESSAGE_TEXT,
};
