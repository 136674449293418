/* eslint-disable import/no-cycle */
import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const fetchList = async (filters) => {
  try {
    const res = await axios.get(`${urls.areaCodeList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchDetail = async (id) => {
  try {
    const res = await axios.get(urls.areaCodeDetail(id));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const createEdit = async (form, id) => {
  try {
    let res;
    if (!id) {
      res = await axios.post(urls.areaCode, form);
    } else {
      res = await axios.patch(`${urls.areaCodeDetail(id)}`, form);
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const changeStatus = async (form, id) => {
  try {
    const res = await axios.patch(`${urls.areaCodeDetail(id)}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteData = async (id) => {
  try {
    const res = await axios.delete(urls.areaCodeDetail(id));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const resendEmail = async (user) => {
  try {
    const { companyUserId } = user;
    const res = await axios.post(urls.userAdminResend(companyUserId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchList, fetchDetail, createEdit, deleteData, resendEmail, changeStatus,
};
