const state = {
  isMobile: false,
};

const getters = {
  getIsMobile: (_state) => !!_state.isMobile,
};

const mutations = {
  SET_IS_MOBILE(store, data) {
    store.isMobile = data;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
};
