import axios from 'axios';
import { querystring } from '../../helper/commonHelpers';
import urls from './urls';

/**
 * fetch Location list
 * @param {Object} filters
 *  @param {Number} page Page number
 *  @param {Number} size list size
 */
const fetchParameterListGroup = async (filters) => {
  try {
    const { sort } = filters;
    if (!sort) {
      filters.sort = 'createdAt,desc';
    }
    const res = await axios.get(`${urls.parameterGroup}?${querystring(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchParameterList = async (filters, parameterGroupId) => {
  try {
    const res = await axios.get(`${urls.parameterList}/${parameterGroupId}/detail/filter?${querystring(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchParameterDetail = async ({ parameterGroupId, parameterId }) => {
  try {
    const res = await axios.get(`${urls.parameterList}/${parameterGroupId}/detail/${parameterId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * create or edit a parameter
 * @param {Object} form data form input
 * @param {Number} form.id basp Id for edit
 * @param {Boolean} form.parameterValue basp isActive
 * @param {Boolean} form.active basp isActive
 * @param {Number} form.expiredOn basp createdAt for edit
 */
const updateParameter = async (data) => {
  try {
    const { appParamGroupId, appParamId } = data;
    const body = {
      parameterCode: data.parameterCode,
      parameterName: data.parameterName,
      parameterValue: data.parameterValue,
      expiredOn: data.expiredOn,
      active: data.active,
    };
    const res = await axios.put((`${urls.parameterList}/${appParamGroupId}/detail/${appParamId}`), body);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchParameterListGroup,
  fetchParameterList,
  fetchParameterDetail,
  updateParameter,
};
