const PAGE_NAME = 'Pengaturan Kategori';
const CREATE_CATEGORY_BUTTON_LABEL = 'Tambah Tipe Kategori';
const SEARCH_CATEGORY_INPUT_PLACEHOLDER = 'Cari Nama Kategori';

const CATEGORY_NAME_COMMON = 'Nama kategori';
const SUBCATEGORY_NAME_COMMON = 'Nama sub kategori';

const CATEGORY_COMMON = 'Kategori';
const SUB_CATEGORY_COMMON = 'Sub kategori';

const CATEGORY_NAME_TABLE_HEADER_LABEL = CATEGORY_NAME_COMMON;
const CATEGORY_TYPE_TABLE_HEADER_LABEL = 'Tipe';
const CATEGORY_TYPE_LABEL = CATEGORY_COMMON;
const SUBCATEGORY_TYPE_LABEL = SUB_CATEGORY_COMMON;
const CREATE_CATEGORY_PAGE_NAME = 'Buat Kategori';
const EDIT_CATEGORY_PAGE_NAME = 'Ubah Kategori';

const CATEGORY_NAME_INPUT_LABEL = CATEGORY_NAME_COMMON;
const SUBCATEGORY_NAME_INPUT_LABEL = SUBCATEGORY_NAME_COMMON;
const ADD_SUBCATEGORY_BUTTON_LABEL = 'Tambah sub kategori';
const DELETE_CATEGORY_BUTTON_LABEL = 'Hapus kategori ini';

const SUCCESS_CREATE_CATEGORY_MESSAGE_TEXT = 'Berhasil membuat kategori baru';
const SUCCESS_EDIT_CATEGORY_MESSAGE_TEXT = 'Berhasil mengubah kategori';

const SUCCESS_EDIT_EMAIL_MESSAGE_TEXT = 'Berhasil mengubah email';
const SUCCESS_CREATE_EMAIL_MESSAGE_TEXT = 'Berhasil menambah email';

const SUCCESS_EDIT_SHIPPINGCOST_MESSAGE_TEXT = 'Berhasil mengubah biaya ongkos kirim';
const SUCCESS_CREATE_SHIPPINGCOST_MESSAGE_TEXT = 'Berhasil menambah biaya ongkos kirim';

const DELETE_CATEGORY_CONFIRM_MESSAGE = 'Apakah anda yakin ingin menghapus kategori ini?';
const DELETE_SUB_CATEGORY_CONFIRM_MESSAGE = 'Apakah anda yakin ingin menghapus sub kategori ini?';
const SUCCESS_DELETE_CATEGORY_MESSAGE_TEXT = 'Data kategori berhasil dihapus';

export default {
  PAGE_NAME,
  CREATE_CATEGORY_BUTTON_LABEL,
  SEARCH_CATEGORY_INPUT_PLACEHOLDER,
  CATEGORY_NAME_TABLE_HEADER_LABEL,
  CATEGORY_TYPE_TABLE_HEADER_LABEL,
  CATEGORY_TYPE_LABEL,
  SUBCATEGORY_TYPE_LABEL,
  CREATE_CATEGORY_PAGE_NAME,
  EDIT_CATEGORY_PAGE_NAME,
  CATEGORY_NAME_INPUT_LABEL,
  SUBCATEGORY_NAME_INPUT_LABEL,
  ADD_SUBCATEGORY_BUTTON_LABEL,
  DELETE_CATEGORY_BUTTON_LABEL,
  SUCCESS_CREATE_CATEGORY_MESSAGE_TEXT,
  SUCCESS_EDIT_CATEGORY_MESSAGE_TEXT,
  SUCCESS_CREATE_EMAIL_MESSAGE_TEXT,
  SUCCESS_EDIT_EMAIL_MESSAGE_TEXT,
  SUCCESS_CREATE_SHIPPINGCOST_MESSAGE_TEXT,
  SUCCESS_EDIT_SHIPPINGCOST_MESSAGE_TEXT,
  DELETE_CATEGORY_CONFIRM_MESSAGE,
  DELETE_SUB_CATEGORY_CONFIRM_MESSAGE,
  SUCCESS_DELETE_CATEGORY_MESSAGE_TEXT,
};
