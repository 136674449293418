import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const etaList = async ({
  page, sort, size, name, nameDestination,
}) => {
  try {
    const filter = {
      page, size,
    };
    if (name) {
      filter.originalLocation = name;
    } else {
      delete filter.originalLocation;
    }
    if (nameDestination) filter.destinationLocation = nameDestination;
    if (sort) {
      filter.sort = sort;
    } else {
      filter.sort = 'createdAt,desc';
    }
    const res = await axios.get(
      `${urls.etaList}?${querystring.stringify(filter)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const etaCreate = async (form, etaId) => {
  try {
    let res;
    if (!etaId) {
      res = await axios.post(urls.etaList, form);
    } else {
      res = await axios.patch(`${urls.etaCreateEdit(etaId)}`, form);
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getLocationList = async ({ page = 0, size, name }) => {
  try {
    const filters = {
      size, page,
    };
    if (name) filters.name = `qlike(${name})`;
    const res = await axios.get(`${urls.locationList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const etaDetail = async (id) => {
  try {
    const res = await axios.get(`${urls.etaDetail}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  etaList,
  etaCreate,
  getLocationList,
  etaDetail,
};
