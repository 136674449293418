export default {
  PAGE_TITLE: 'P2P Lending',
  CREATE_NEW: 'Buat Baru',
  DETAIL_P2P_LENDING: 'Detail P2P Lending',
  NO_P2P_LENDING: 'No P2P Lending',
  TRANSPORTER_NAME: 'Nama Transporter',
  INVOICE: 'Invoice',
  NO_INVOICE: 'No Invoice',
  INITIAL_INVOICE: 'Invoice (Awal)',
  INVOICE_P2P_LENDING: 'Invoice (P2P Lending)',
  CREATED_AT: 'Tanggal Dibuat',
  INVOICE_DATE: 'Tanggal Invoice',
  RECEIVED_INVOICE_DATE: 'Tanggal Invoice Diterima',
  DUE_DATE: 'Tanggal Jatuh Tempo',
  PAYMENT_DATE: 'Tanggal Pembayaran',
  CREATED_BY: 'Dibuat Oleh',
  STATUS: 'Status',
  ACTION: 'Aksi',
  LIST_P2P_LENDING: 'Daftar P2P Lending',
  CREATE_P2P_LENDING: 'Buat P2P Lending',
  TOTAL_P2P_LENDING: 'Total P2P Lending',
  DELETE_INVOICE: 'Hapus Invoice',
  PERIOD_ORDER: 'Periode Pesanan',
  TOTAL_BILL: 'Total Tagihan',
  TOTAL_INVOICE: 'Total Invoice',
  TOP_P2P_LENDING: 'Term Of Payment - P2P Lending',
  TOP_P2P_LENDING_2: 'TOP - P2P Lending',
  CALCULATOR: 'Kalkulator',
  TOTAL_PAYMENT_TO_3PL: 'Total Pembayaran ke 3PL',
  PAYMENT_INFO: 'Info Pembayaran',
  TOP: 'Term Of Payment',
  ADMIN_FEES: 'Biaya Admin',
  INTEREST_COST: 'Biaya Bunga',
  INITIAL_TOP: 'TOP Awal',
  UPLOAD_INVOICE: 'Upload Invoice',
  UPLOAD_TRAVEL_LETTER: 'Upload Surat Jalan',
  UPLOAD_BAST: 'Upload BAST',
  UPLOAD_ADDITIONAL_DOCUMENT: 'Upload Dokumen Tambahan',
  PROOF_OF_PAYMENT: 'Bukti Pembayaran',
  UPLOAD_PROOF_OF_PAYMENT: 'Upload Dokumen Bukti Pembayaran',
  ATTACHMENT_DOCUMENT: 'Lampirkan Bukti Pembayaran',
  UPLOAD_DOCUMENT: 'Upload Dokumen',
  INITAL_INVOICE_DUE_DATE: 'Invoice Due Date Awal',
  P2P_LENDING_DUE_DATE: 'P2P Lending Due Date',
  DOCUMENT_SUPPORT: 'Dokumen Pendukung',
  SELECT_INVOICE: 'Pilih Invoice',
  NEXT: 'Lanjutkan',
  SAVE: 'Simpan',
  SUBMIT: 'Submit',
  P2P_LENDING_APPROVAL: 'Persetujuan P2P Lending',
  STATUS_PAYMENT: 'Status Pembayaran',
  NOTE: 'Catatan',
  CONFIRMATION: 'Konfirmasi',
  LIST_STATUS_P2P_LENDING: {
    NEED_APPROVAL: 'Menunggu Persetujuan',
    APPROVED: 'Disetujui',
    REJECTED: 'Ditolak',
  },
  ERROR_MESSAGE_DOCUMENTS: 'Terjadi Kesalahan Saat Memuat Dokumen',
  UPDATE_PAYMENT_CONFIRM_MESSAGE: 'Anda Yakin Untuk Melakukan Pembayaran',
};
