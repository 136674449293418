const PAGE_NAME = 'Pengaturan Tipe Kendaraan';
const CREATE_TRANSPORTTYPES_PAGE_NAME = 'Buat tipe kendaraan baru';
const EDIT_TRANSPORTTYPES_PAGE_NAME = 'Ubah tipe kendaraan';

const CREATE_TRANSPORTTYPES_BUTTON_LABEL = 'Tambah tipe kendaraan';
const SEARCH_TRANSPORTTYPES_INPUT_PLACEHOLDER = 'Cari nama tipe kendaraan';

const NAME_TABLE_HEADER_LABEL = 'Nama Tipe Kendaraan';
const PHOTO_TABLE_HEADER_LABEL = 'Foto';
const DIMENSION_TABLE_HEADER_LABEL = 'Dimensi';
const VOLUME_TABLE_HEADER_LABEL = 'Volume';
const WEIGHT_TABLE_HEADER_LABEL = 'Bobot';

const NAME_INPUT_LABEL = 'Nama Tipe Kendaraan';
const LENGTH_INPUT_LABEL = 'Panjang (inner)';
const WIDTH_INPUT_LABEL = 'Lebar (inner)';
const HEIGHT_INPUT_LABEL = 'Tinggi (inner)';
const VOLUME_INPUT_LABEL = 'Volume (inner)';
const MAXWEIGHT_INPUT_LABEL = 'Bobot Maksimum';
const AVGSPEED_INPUT_LABEL = 'Kecepatan rata-rata';
const IMAGE_INPUT_LABEL = 'Gambar';

const SUCCESS_CREATE_TRANSPORTTYPES_MESSAGE_TEXT = 'Berhasil membuat tipe kendaraan';
const SUCCESS_EDIT_TRANSPORTTYPES_MESSAGE_TEXT = 'Berhasil mengubah tipe kendaraan';
const SUCCESS_EDIT_STATUS_MESSAGE_TEXT = 'Berhasil mengubah status tipe kendaraan';

export default {
  PAGE_NAME,
  CREATE_TRANSPORTTYPES_PAGE_NAME,
  EDIT_TRANSPORTTYPES_PAGE_NAME,
  CREATE_TRANSPORTTYPES_BUTTON_LABEL,
  SEARCH_TRANSPORTTYPES_INPUT_PLACEHOLDER,
  PHOTO_TABLE_HEADER_LABEL,
  NAME_TABLE_HEADER_LABEL,
  DIMENSION_TABLE_HEADER_LABEL,
  VOLUME_TABLE_HEADER_LABEL,
  WEIGHT_TABLE_HEADER_LABEL,
  NAME_INPUT_LABEL,
  LENGTH_INPUT_LABEL,
  WIDTH_INPUT_LABEL,
  HEIGHT_INPUT_LABEL,
  VOLUME_INPUT_LABEL,
  MAXWEIGHT_INPUT_LABEL,
  AVGSPEED_INPUT_LABEL,
  IMAGE_INPUT_LABEL,
  SUCCESS_CREATE_TRANSPORTTYPES_MESSAGE_TEXT,
  SUCCESS_EDIT_TRANSPORTTYPES_MESSAGE_TEXT,
  SUCCESS_EDIT_STATUS_MESSAGE_TEXT,
};
