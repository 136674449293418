const PAGE_NAME_SHIPPER = 'Kontrak';

export default {
  label: {
    PAGE_NAME_SHIPPER: 'Kontrak Shipper',
    PAGE_NAME_3PL: 'Kontrak Rekanan 3PL',
    PAGE_NAME_TERMINATION: 'Pengakhiran Kontrak',
    PAGE_NAME_ADDENDUM: 'Addendum',
    SEARCHING_PLACEHOLDER: 'Cari Nama Shipper',
    SEARCHING_3PL_PLACEHOLDER: 'Cari Nama Rekanan 3PL',
    ADD_CONTRACT: 'Tambah Kontrak',
    DOWNLOAD_CONTRACT: 'Unduh Template Kontrak',
    SHIPPER_CONTRACT: 'Kontrak Shipper',
    MOU_NUMBER: 'Nomor MoU',
    EFFECTIVE_PERIOD: 'Periode Efektif',
    UNTIL: 'Sampai',
    SIGNED: 'Penandatanganan',
    SIGNERS_NAME: 'Nama Penandatangan',
    SIGNING_POSITION: 'Jabatan Penandatangan',
    CLOSE: 'Tutup',
    ADD_CONTRACT_SHIPPER: 'Tambah Kontrak Shipper',
    VIEW_CONTRACT_SHIPPER: 'Kontrak Shipper',
    ADD_CONTRACT_3PL: 'Tambah Kontrak Rekanan 3PL',
    VIEW_CONTRACT_3PL: 'Kontrak Rekanan 3PL',
    ATTACHMENT: 'Lampiran',
    CONTRACT_FORMAT: 'Kontrak Format',
    FASTLOG_CONTRACT_FORMAT: 'Kontrak Format Fastlog',
    SHIPPER_FORMAT: 'Format Shipper',
    UPLOAD_ATTACHMENT: 'Unggah Lampiran',
    BACK: 'Kembali',
    NEXT: 'Selanjutnya',
    SAVE: 'Simpan',
    UPLOAD_CONTRACT: 'Unggah Kontrak',
    UPLOAD_CONTRACT_TERMINATION: 'Unggah Pengakhiran Kontrak',
    UPLOAD_CONTRACT_ADDENDUM: 'Unggah Addendum Kontrak',
    CONTRACT_PARTNER_3PL: 'Kontrak Rekanan 3PL',
    APPROVAL_HISTORY: 'Riwayat Persetujuan',
    UPLOAD: 'Unggah',
    CANCEL: 'Batal',
  },
  header: {
    CONTRACT_NUMBER: 'Nomor Kontrak',
    COMPANY_NAME: 'Nama Perusahaan',
    DURATION_CONTRACT: 'Durasi Kontrak',
    EXPIRED_CONTRACT: 'Expired Kontrak',
    CREATED_BY: 'Dibuat Oleh',
    CONTRACT_STATUS: 'Status Kontrak',
    STATUS: 'Status',
    APPROVAL_STATUS: 'Status Persetujuan',
    ACTION: 'Aksi',
    NUMBER: 'No',
    DOCUMENT_ATTACHMENT_NAME: 'Nama Dokumen Lampiran',
    DOCUMENT_NAME: 'Nama Dokumen',
    NO: 'No',
  },
  headerApproval: {
    NO: 'No',
    POSITION: 'Jabatan',
    NAME: 'Nama',
    ACTION: 'Aksi',
    ACTION_TIME: 'Waktu Aksi',
    NOTE: 'Catatan',
  },
  PAGE_NAME_SHIPPER,
  status: [
    { key: 'CONTRACT', value: 'Pengajuan Kontrak Baru' },
    { key: 'ADDENDUM', value: 'Pengajuan Addendum' },
    { key: 'EARLY_TERMINATION', value: 'Pemutusan Kontrak' },
    { key: 'FULL_TERMINATION', value: 'Pengakhiran Kontrak' },
  ],
  statusApproval: [
    { key: 'MENUNGGU_PERSETUJUAN', value: 'Menunggu Persetujuan' },
    { key: 'DISETUJUI', value: 'Disetujui' },
    { key: 'DITOLAK', value: 'Ditolak' },
    { key: 'REVISI', value: 'Revisi' },
    { key: 'DRAFT', value: 'Draft' },
  ],
  statusWorkflow: [
    { key: 'SUBMIT', value: 'Submit' },
    { key: 'APPROVE', value: 'Disetujui' },
    { key: 'REVISE', value: 'Direvisi' },
    { key: 'REJECT', value: 'Ditolak' },
  ],
  allowedExtension: ['JPG', 'JPEG', 'PNG', 'PDF', 'DOCX', 'DOC'],
  tooltips: {
    VIEW_DETAIL: 'Lihat detail',
    DOWNLOAD: 'Unduh',
    UPLOAD: 'Unggah',
    DOCUMENT: 'Dokumen',
    REMOVE: 'Hapus',
  },
};
