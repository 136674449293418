export default {
  statusOrderCancel: 'Dibatalkan',
  statusOrderConfirm: 'Terkonfirmasi',
  orderNo: 'No Kiriman',
  vehicle: 'Kendaraan',
  vehicleNumber: 'No. Kendaraan',
  label3PL: 'Rekanan 3PL',
  lastLocation: 'Lokasi Terakhir',
  status: 'Status',
};
