export default {
  headerNoKargo: 'No Kargo',
  headerRekanan3PL: 'Rekanan 3PL',
  headerNamaPengirim: 'Nama Pengirim',
  headerStatus: 'Status',
  headerAksi: 'Aksi',
  headerTipeDokumen: 'Tipe Dokumen',
  headerNamaDokumen: 'Nama Dokumen',
  headerCatatan: 'Catatan',
  headerUploadDokumen: 'Upload Dokumen',
  fotoDokumen: 'Foto Dokumen',
  headerRevise: 'Revisi',
  headerRiwayatCatatan: 'Riwayat Catatan',
  headerLokasi: 'Lokasi',
  headerNoPesanan: 'Nomor Pesanan',
  deleteEtaConfirmMsg: 'Apakah Anda yakin ingin menghapus data ini?',
  PAGE_NAME: 'Daftar Dokumen Hard Copy POD',
  pageHeader: 'Dokumen Hard Copy POD',
  pageHeaderEdit: 'Dokumen POD',
  buttonDokumen: 'Dokumen Lengkap',
  buttonDokumenTidakLengkap: 'Dokumen Tidak Lengkap',
  buttonKembali: 'Kembali',
  buttonSendDokumen: 'Kirim Dokumen Lengkap ke shipper',
  deleteDokumenType: 'Apakah anda ingin menghapus dokumen ini?',
  successDeleteDokumenType: 'Data dokumen berhasil dihapus',
  save: 'Simpan',
  update: 'Update',
  send: 'Kirim',
  cancel: 'Batal',
  successEditDokumenType: 'Berhasil mengubah dokumen',
  nameFormatLabel: 'Nama dokumen',
  dialogEdit: 'Edit Dokumen POD',
  dialogView: 'Detail Dokumen POD',
  pickupDate: 'Tanggal Bongkar',
  headerFlightCruise: 'No Pelayaran/ Penerbangan',
  latestStatusPod: [
    {
      text: 'Dokumen Lengkap',
      value: 'DOKUMEN_LENGKAP',
    },
    {
      text: 'Dokumen Tidak Lengkap',
      value: 'DOKUMEN_TIDAK_LENGKAP',
    },
    {
      text: 'Dokumen Terikirm',
      value: 'DOKUMEN_TERKIRIM',
    },
    {
      text: 'Belum Kirim Dokumen',
      value: 'BELUM_KIRIM_DOKUMEN',
    },
    {
      text: 'Dokumen Dikirim Ke Shipper',
      value: 'DOKUMEN_DIKIRIM_KE_SHIPPER',
    },
  ],
  UPLOAD_DOCUMENT: 'Upload Dokumen',
  DOCUMENT_TYPE: 'Tipe Dokumen',
  NUMBER_ORDER: 'Nomor Pesanan',
  LOCATION: 'Lokasi',
  REMOVE_IMAGE: 'Hapus Foto',
};
