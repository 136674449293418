export default {
  label: {
    PAGE_NAME: 'List Addendum',
    ADENDUM: 'Addendum',
    ADDEDUM_SUBMISSIONS: 'Pengajuan Addendum',
    DOWNLOAD_TEMPLATE_ADENDUM: 'Unduh Template Addendum',
    SEARCHING_PLACEHOLDER: 'Cari Nama Shipper/Rekanan 3PL',
    FILTER_BASED: 'Filter Berdasarkan',
    CONTRACT_FORMAT: 'Kontrak Format',
    COMPANY_NAME: 'Nama Perusahaan',
    CONTRACT_NUMBER: 'Nomor Kontrak',
    MOU_NUMBER: 'Nomor MoU',
    ADENDUM_SIGNED: 'Addendum Ditandatangan',
    ADDENDUM_EFFECTIVE: 'Addendum Efektif Berlaku',
    PT_FLI: 'PT.Fastana Logistik Indonesia',
    ADDENDUM_SIGNER: 'Penandatangan Addendum',
    NAME: 'Nama',
    ROLE: 'Jabatan',
    POWER_OF_ATTORNEY_NUMBER: 'Nomor Surat Kuasa',
    SIGNED_DATE_POWER_OF_ATTORNEY_NUMBER: 'Tanggal Ttd Surat Kuasa',
    SHIPPER: 'Shipper',
    TRANSPORTER: 'Rekanan 3PL',
    PARTNER_TYPE: 'Jenis Mitra Kerja',
    UPLOAD_ADDENDUM: 'Unggah Addendum',
    DOCUMENT_NAME: 'Nama Dokumen',
    CHOOSE_FILE: 'Pilih File',
    CLOSE: 'Tutup',
    UPLOAD: 'Unggah',
  },
  header: {
    CONTRACT_NUMBER: 'Nomor Kontrak',
    COMPANY_NAME: 'Nama Perusahaan',
    PARTNER: 'Mitra Kerja',
    CONTRACT_DURATION: 'Durasi Kontrak',
    CREATED_BY: 'Dibuat Oleh',
    CONTRACT_STATUS: 'Status Kontrak',
    STATUS: 'Status',
    APPROVAL_STATUS: 'Status Persetujuan',
    ACTION: 'Aksi',
    NUMBER: 'Nomor',
    DOCUMENT_NAME: 'Nama Dokumen',
  },
};
