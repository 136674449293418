export default {
  // update
  TITLE: 'Master Sales',
  CODE: 'Kode Sales',
  NAME: 'Name Sales',
  STATUS: 'Status',
  REFRESH: 'Refresh Data Sales',
  SEARCH_TEXT: 'Cari Sales',
  FILTER_LIST: [
    {
      value: '',
      text: 'Semua Pajak',
    },
    {
      value: 'qistrue',
      text: 'Kena Pajak',
    },
    {
      value: 'qisfalse',
      text: 'Tidak Kena Pajak',
    },
  ],
};
