export default {
  etaDatabase: 'ETA',
  searchTextAsal: 'Cari Lokasi Asal',
  searchTextTujuan: 'Cari Lokasi Tujuan',
  photo: 'Foto',
  createEta: 'Tambah ETA',
  editEta: 'Ubah ETA',
  username: 'Nama Sopir',
  email: 'Email',
  phone: 'Nomor Telepon',
  deleteEtaConfirmMsg: 'Apakah Anda yakin ingin menghapus data ini?',
  PAGE_NAME: 'Daftar Eta',
  originLocationLabel: 'Lokasi asal',
  destinationLocationLabel: 'Lokasi tujuan',
  duration: 'Lama perjalanan',
  pageName: 'Daftar Master ETA',
};
