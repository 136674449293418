/* eslint-disable import/prefer-default-export */
import { skipEmptyStringObject } from '@/helper/commonHelpers';
import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const saveMouInformation = async (mouBy, data) => {
  try {
    const res = await axios.post(`${urls.mou}/${mouBy}/draft`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const updateMouInformation = async (mouBy, mouId, data) => {
  try {
    const res = await axios.post(`${urls.mou}/${mouBy}/${mouId}/draft`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const updateAddendumMouInformation = async (mouBy, mouId, data) => {
  try {
    const res = await axios.post(`${urls.mou}/${mouBy}/${mouId}/addendum`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const reviseMouInformatioin = async (mouBy, workflowId, workflowDetailId, data) => {
  try {
    const res = await axios.patch(`${urls.mou}/${mouBy}/revise/${workflowId}/${workflowDetailId}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const saveMouAsDraft = async (mouBy, mouId, data) => {
  try {
    const res = await axios.post(`${urls.mou}/${mouBy}/draft`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const saveMou = async (mouBy, mouId) => {
  try {
    const res = await axios.post(`${urls.mou}/${mouBy}/${mouId}/submit`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const saveMouRevise = async (mouBy, workflowId, workflowDetailId, data) => {
  try {
    const res = await axios.patch(`${urls.mou}/${mouBy}/revise/${workflowId}/${workflowDetailId}/submit`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchMouShipper = async (filters) => {
  try {
    const res = await axios.get(`${urls.listMouShipper}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};
const fetchMouTransporter = async (filters) => {
  try {
    const res = await axios.get(`${urls.listMouTransporter}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchMouLocation = async ({ page, size, name }) => {
  try {
    const filters = {
      page, size,
    };
    if (name) filters.name = `qlike(${name})`;
    const res = await axios.get(`${urls.locationList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchMouTransportType = async ({ page, size, name }) => {
  try {
    const filters = { page, size, isActive: 'qistrue' };
    if (name) {
      filters.name = `qlike(${name})`;
    }
    const res = await axios.get(`${urls.transportTypeList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchMouShipperRoute = async (id, serviceType, filters) => {
  try {
    const res = await axios.get(`${urls.mouShipperRoute}/${id}/route/${serviceType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchMouTransporterRoute = async (id, serviceType, filters) => {
  try {
    const res = await axios.get(`${urls.mouTransporterRoute}/${id}/route/${serviceType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchDetailMouShipper = async (mouId) => {
  try {
    const res = await axios.get(`${urls.detailMouShipper(mouId)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchDetailMouTransporter = async (mouId) => {
  try {
    const res = await axios.get(`${urls.detailMouTransporter(mouId)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const fetchHistoryWorkFlow = async (workflowId) => {
  try {
    const res = await axios.get(`${urls.contractHistory}/${workflowId}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const deleteMouShipper = async (mouId) => {
  try {
    const res = await axios.delete(`${urls.mouShipperRoute}/${mouId}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const deleteMouTransporter = async (mouId) => {
  try {
    const res = await axios.delete(`${urls.mouTransporterRoute}/${mouId}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getDetailWorkflowMou = async (mouId) => {
  try {
    const res = await axios.get(`${urls.detailWorkflowMou(mouId)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const approveInProcessMou = async (data) => {
  try {
    const res = await axios.post(`${urls.inboxAppoveMou}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const reviseInProcessMou = async (data) => {
  try {
    const res = await axios.post(`${urls.inboxReviseMou}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};
const rejectInProcessMou = async (data) => {
  try {
    const res = await axios.post(`${urls.inboxRejectMou}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const reviseMou = async (mouBy, workflowId, workflowDetailId, data) => {
  try {
    const res = await axios.patch(`${urls.reviseMou(mouBy, workflowId, workflowDetailId)}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const extendMouShipper = async ({ extendedDate, notes, id }) => {
  try {
    const form = {
      extendedDate,
      notes,
    };
    const res = await axios.patch(`${urls.mouShipper}/extend/${id}`, form);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const extendMouTransporter = async ({ extendedDate, notes, id }) => {
  try {
    const form = {
      extendedDate,
      notes,
    };
    const res = await axios.patch(`${urls.mouTransporter}/extend/${id}`, form);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const checkMou = async (companyId) => {
  try {
    const res = await axios.get(urls.checkMou(companyId));
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const listShipperCompanyCanCreateMou = async (companyName) => {
  try {
    const res = await axios.get(`${urls.listCompanyAlreadyMou}/shipper?${querystring.stringify(companyName)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const listTransporterCompanyCanCreateMou = async (companyName) => {
  try {
    const res = await axios.get(`${urls.listCompanyAlreadyMou}/transporter?${querystring.stringify(companyName)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const listShipperCompanyIsReadyMou = async (filters) => {
  try {
    const { companyName } = filters;
    if (companyName) {
      filters.name = `qlike(${companyName})`;
    }
    filters.isReadyMou = 'qistrue';
    filters.status = 'qlike(active)';
    filters.type = 'qlike(shipper)';
    const res = await axios.get(`${urls.contractCompanyList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const listTransporterCompanyIsReadyMou = async (filters) => {
  try {
    const { companyName } = filters;
    if (companyName) {
      filters.name = `qlike(${companyName})`;
    }
    filters.isReadyMou = 'qistrue';
    filters.status = 'qlike(active)';
    filters.type = 'qlike(transporter)';
    const res = await axios.get(`${urls.contractCompanyList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const listCompanyAlreadyMou = async (companyType, companyName) => {
  try {
    const res = await axios.get(`${urls.listCompanyAlreadyMou}/${companyType}?companyName=${companyName}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const parametersTop = async (filters) => {
  try {
    const { paramValue } = filters;
    filters.isActive = 'qistrue';
    filters.sort = 'paramValue,asc';
    if (paramValue) {
      filters.paramValue = `qlike(${paramValue})`;
    }
    const res = await axios.get(`${urls.parameterList}/top/filter?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const downloadMouShipper = async (mouId, config) => {
  try {
    const res = await axios.get(urls.downloadMouShipper(mouId), config);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const downloadMouTransporter = async (mouId, config) => {
  try {
    const res = await axios.get(urls.downloadMouTransporter(mouId), config);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const downloadMouTempShipper = async (mouId, config) => {
  try {
    const res = await axios.get(urls.downloadMouTempShipper(mouId), config);
    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

const downloadMouTempTransporter = async (mouId, config) => {
  try {
    const res = await axios.get(urls.downloadMouTempTransporter(mouId), config);
    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getListTransportTypeLimitTransporter = async (mouId, transportTypeLimitId, filters, source) => {
  try {
    const res = await axios.get(`${urls.mouTransporter}/${mouId}/transport-type-limit/${transportTypeLimitId}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getListTransportTypeLimitShipper = async (mouId, transportTypeLimitId, filters, source) => {
  try {
    const res = await axios.get(`${urls.mouShipper}/${mouId}/transport-type-limit/${transportTypeLimitId}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getListOldTransportTypeLimit = async (mouBy, mouId, filter) => {
  try {
    const res = await axios.get(`${urls.mouOldTranportTypeLimit(mouBy, mouId)}?${querystring.stringify(filter)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getListLincesePlate = async (filters, source) => {
  try {
    const res = await axios.get(`${urls.transport}/fixed-price?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const saveTransportLimitShipper = async (mouId, transportTypeLimitId, form, source) => {
  try {
    const res = await axios.post(`${urls.mouShipper}/${mouId}/transport-type-limit/${transportTypeLimitId}`, form, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const mouUpload = async (type, mouId, form, config) => {
  try {
    const url = type === 'shipper' ? urls.mouShipper : urls.mouTransporter;
    const res = await axios.patch(`${url}/${mouId}/upload`, form, config);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouLocationByServiceType = async (filters, { mouBy, mouId, serviceType }) => {
  try {
    const res = await axios.get(`${urls.mou}/${mouBy}/${mouId}/route/${serviceType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const createMouNewLocation = async (mouBy, mouId, locationId) => {
  try {
    const data = {
      locationId,
    };
    const res = await axios.post(`${urls.mou}/${mouBy}/${mouId}/location`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const deleteMouLocation = async (mouBy, mouId, locationId) => {
  try {
    const res = await axios.delete(`${urls.mou}/${mouBy}/${mouId}/location/${locationId}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const createMouNewTrasportTypes = async (mouBy, mouId, transportTypeId) => {
  try {
    const data = {
      transportTypeId,
    };
    const res = await axios.post(`${urls.mou}/${mouBy}/${mouId}/transport-type`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const deleteMouTransportTypes = async (mouBy, mouId, transportTypeId) => {
  try {
    const res = await axios.delete(`${urls.mou}/${mouBy}/${mouId}/transport-type/${transportTypeId}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const createMouNewTrasportTypeLimit = async (mouBy, mouId, data) => {
  try {
    const res = await axios.post(`${urls.mou}/${mouBy}/${mouId}/transport-type-limit`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const updateMouTransportTypeLimit = async (mouBy, mouId, id, data) => {
  try {
    const res = await axios.patch(`${urls.mou}/${mouBy}/${mouId}/transport-type-limit/${id}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const deleteMouTrasportTypeLimit = async (mouBy, mouId, id) => {
  try {
    const res = await axios.delete(`${urls.mou}/${mouBy}/${mouId}/transport-type-limit/${id}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouRoute = async (filters, { mouBy, mouId, serviceType }, controller) => {
  try {
    let signalController = {};
    if (controller) {
      signalController = {
        cancelToken: controller.token,
      };
    }
    const res = await axios.get(`${urls.mou}/${mouBy}/${mouId}/route/${serviceType}?${querystring.stringify(filters)}`, signalController);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getOldMouRoute = async (mouBy, mouId, serviceType, filter) => {
  try {
    const res = await axios.get(`${urls.mou}/${mouBy}/${mouId}/route/${serviceType}/old?${querystring.stringify(filter)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouLocation = async (filters, { mouBy, mouId, isDelete }) => {
  try {
    let filter = filters;
    if (typeof isDelete === 'boolean') {
      filter = {
        ...filters,
        isDelete,
      };
    }
    const res = await axios.get(`${urls.mou}/${mouBy}/${mouId}/location?${querystring.stringify(filter)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouTransportType = async (filters, { mouBy, mouId, isDelete }) => {
  try {
    let filter = filters;
    if (typeof isDelete === 'boolean') {
      filter = {
        ...filters,
        isDelete,
      };
    }
    const res = await axios.get(`${urls.mou}/${mouBy}/${mouId}/transport-type?${querystring.stringify(filter)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getMouTransportTypeLimit = async (filters, { mouBy, mouId }) => {
  try {
    const res = await axios.get(`${urls.mou}/${mouBy}/${mouId}/transport-type-limit?${querystring.stringify(filters)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

// CRUD MOU ROUTE
const createMouRoute = async (mouBy, mouId, serviceType, data) => {
  try {
    const res = await axios.post(`${urls.mou}/${mouBy}/${mouId}/route/${serviceType}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const updateMouRoute = async (mouBy, mouId, routeId, serviceType, data) => {
  try {
    const res = await axios.patch(`${urls.mou}/${mouBy}/${mouId}/route/${serviceType}/${routeId}`, data);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const deleteMouRoute = async (mouBy, mouId, routeId) => {
  try {
    const res = await axios.delete(`${urls.mou}/${mouBy}/${mouId}/route/${routeId}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const uploadCsvMouFixedPrice = async (formData, mouId, serviceType, mouBy) => {
  let form;
  for (const pair of formData.entries()) {
    form = pair;
  }
  const _urls = mouBy === 'shipper' ? urls.uploadCsvMouShipper : urls.uploadCsvMouTransporter;
  try {
    const res = await axios.post(
      `${_urls}/${mouId}/fixed-price`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const uploadCsvMouRoute = async (formData, mouId, serviceType, mouBy) => {
  let form;
  for (const pair of formData.entries()) {
    form = pair;
  }
  const _urls = mouBy === 'shipper' ? urls.uploadCsvMouShipper : urls.uploadCsvMouTransporter;
  try {
    const res = await axios.post(
      `${_urls}/${mouId}/route/${serviceType}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchCsvTemplateMou = async (dataType, mouBy) => {
  try {
    const _urls = mouBy === 'shipper' ? urls.csvTemplateMouShipper : urls.csvTemplateMouTransporter;
    const res = await axios.get(`${_urls}/${dataType}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const mouOriginRoute = async (filters,
  {
    mouId,
    serviceType,
    transportTypeId,
    isDelete = false,
  }) => {
  try {
    const res = await axios.get(
      `${urls.mouShipperRoute}/${mouId}/route/${serviceType}?${querystring.stringify({
        ...filters,
        isDelete,
        transportTypeId,
      })}`,
    );
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export {
  saveMouInformation,
  reviseMouInformatioin,
  updateMouInformation,
  updateAddendumMouInformation,
  saveMouAsDraft,
  saveMou,
  saveMouRevise,
  fetchMouShipper,
  fetchMouTransporter,
  fetchMouLocation,
  fetchMouTransportType,
  fetchDetailMouShipper,
  fetchDetailMouTransporter,
  fetchHistoryWorkFlow,
  deleteMouShipper,
  deleteMouTransporter,
  getDetailWorkflowMou,
  approveInProcessMou,
  reviseInProcessMou,
  rejectInProcessMou,
  reviseMou,
  checkMou,
  extendMouShipper,
  extendMouTransporter,
  listCompanyAlreadyMou,
  parametersTop,
  downloadMouShipper,
  getListOldTransportTypeLimit,
  getOldMouRoute,
  getListTransportTypeLimitTransporter,
  getListTransportTypeLimitShipper,
  getListLincesePlate,
  saveTransportLimitShipper,
  mouUpload,
  downloadMouTransporter,
  downloadMouTempShipper,
  downloadMouTempTransporter,
  fetchMouShipperRoute,
  fetchMouTransporterRoute,
  listShipperCompanyCanCreateMou,
  listTransporterCompanyCanCreateMou,
  listShipperCompanyIsReadyMou,
  listTransporterCompanyIsReadyMou,
  getMouRoute,
  getMouLocationByServiceType,
  getMouLocation,
  getMouTransportType,
  getMouTransportTypeLimit,
  createMouNewLocation,
  deleteMouLocation,
  createMouNewTrasportTypes,
  deleteMouTransportTypes,
  createMouNewTrasportTypeLimit,
  updateMouTransportTypeLimit,
  deleteMouTrasportTypeLimit,
  createMouRoute,
  updateMouRoute,
  deleteMouRoute,
  uploadCsvMouRoute,
  uploadCsvMouFixedPrice,
  fetchCsvTemplateMou,
  mouOriginRoute,
};
