export default {
  headerTahunPajak: 'Tahun Pajak',
  headerMasaPajak: 'Masa Pajak',
  headerNPWP: 'NPWP',
  headerCustomer: 'Customer',
  headerAlamatLengkap: 'Alamat Lengkap',
  headerNoInvoice: 'No Invoice',
  headerTotalPengiriman: 'Total Pengiriman',
  headerNilaiDpp: 'Nilai DPP',
  headerNilaiPPN: 'Nilai PPN',
  headerNilaiTotal: 'Nilai Total',
  pageName: 'Daftar Masalah',
  searchNo: 'Cari No. Kiriman',
  customer: 'Customer',
  filterCustomer: 'Semua Customer',
};
