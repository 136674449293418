import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const dokumenPodList = async (filter) => {
  try {
    const res = await axios.get(
      `${urls.dokumenPodList}?${querystring.stringify(filter)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const dokumenPodDetail = async ({
  page, sort, size, cargoId,
}) => {
  try {
    const filter = {
      page, size,
    };
    if (sort) {
      filter.sort = sort;
    } else {
      filter.sort = 'createdAt,desc';
    }
    const res = await axios.get(
      `${urls.dokumenPodDetail}/${cargoId}?${querystring.stringify(filter)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const dokumenPodCreate = async (id) => {
  try {
    const res = await axios.get(`${urls.dokumenPodDetail}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const typeDokumenPodDetail = async ({
  cargoId, documentId,
}) => {
  try {
    const res = await axios.get(
      `${urls.typeDokumenPodDetail}/${cargoId}/detail/${documentId}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteTypeDokumenPod = async ({
  cargoId, documentId,
}) => {
  try {
    const res = await axios.delete(`${urls.typeDokumenPodDetail}/${cargoId}/detail/${documentId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const editTypeDokumenPod = async (
  cargoId, documentId, form,
) => {
  try {
    const res = await axios.patch(`${urls.typeDokumenPodEdit(cargoId, documentId)}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateStatusDocumentPod = async (cargoId, status, form) => {
  try {
    const res = await axios.patch(`${urls.typeDokumenPodDetail}/${cargoId}/${status}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const uploadDocument = async (cargoId, data) => {
  try {
    const res = await axios.post(`${urls.dokumenPodList}/${cargoId}/detail/upload`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  dokumenPodList,
  dokumenPodCreate,
  dokumenPodDetail,
  typeDokumenPodDetail,
  deleteTypeDokumenPod,
  editTypeDokumenPod,
  updateStatusDocumentPod,
  uploadDocument,
};
