import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

/**
 * fetch Location list
 * @param {Object} filters
 *  @param {Number} filters.page Page number
 *  @param {Number} filters.size list size
 *  @param {String} filters.name search text
 *  @param {String} filters.sort sorting format: field;ASC|DESC
 */
const fetchLocationList = async ({
  page, size, name, sort, address, province, city, district, locationType,
}) => {
  try {
    const filters = {
      page, size,
    };
    if (name) filters.name = `qlike(${name})`;
    if (address) filters.address = `qlike(${address})`;
    if (province) filters.province = province;
    if (city) filters.city = city;
    if (district) filters.district = district;
    if (locationType) filters.locationType = locationType;
    if (sort) {
      filters.sort = sort;
    } else {
      filters.sort = 'createdAt,desc';
    }
    const res = await axios.get(`${urls.locationList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * fetch a location's detail
 * @param {Number} id Location Id
 */
const fetchLocationDetail = async (id) => {
  try {
    const res = await axios.get(`${urls.locationDetail}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * create and edit a location detail
 * @param {Object} form location detail form
   * @param {String} form.name
   * @param {String} form.address
   * @param {Number} form.latitude
   * @param {Number} form.longitude
   * @param {Number} form.province province object
   * @param {Number} form.city city object
   * @param {Number} form.district district object
   * @param {Number} form.subDistrict sub district object
   * @param {Number} form.radiusCalculation location reach radius for app
   * @param {Number} form.openingTime location's opening time
   * @param {Number} form.closingTime locations's closing time
   * @param {Number} form.locationNote location's location note ie: "Maksimum yang bisa masuk mobil CDD"
 * @param {Number} [locationId] Location id for edit/update
 */
const createEditLocation = async (form, locationId) => {
  try {
    let res;
    const {
      name,
      address,
      latitude,
      longitude,
      radiusCalculation,
      openingTime,
      closingTime,
      locationNote,
      locationType,
    } = form;
    const data = {
      name,
      address,
      latitude,
      longitude,
      radiusCalculation,
      openingTime,
      closingTime,
      locationNote,
      provinceId: form.province.id,
      cityId: form.city.id,
      districtId: form.district.id,
      subDistrictId: form.subDistrict.id,
      locationType,
    };
    if (locationId) {
      res = await axios.put((`${urls.locationDetail}/${locationId}`), data);
      return res.data;
    }
    res = await axios.post(urls.locationDetail, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const provinces = async (filters = {}) => {
  try {
    if (filters.name) filters.name = `qlike(${filters.name})`;
    const res = await axios.get(`${urls.provinceList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get city list (kodya)
 * @param {String} input city name search
 * @param {Number} provinceId province id to get cities from
 */
const cities = async (input, provinceId) => {
  try {
    const filters = {
      page: 0,
      size: 250,
    };
    if (input) filters.name = `qlike(${input})`;
    const res = await axios.get(`${urls.cityList(provinceId)}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const listCity = async (filters, { provinceId }) => {
  try {
    if (filters.name) filters.name = `qlike(${filters.name})`;
    const res = await axios.get(`${urls.cityList(provinceId)}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get districts list (kecamatan)
 * @param {String} input districts name search
 * @param {Number} cityId city id to get districts from
 */
const districts = async (input, cityId) => {
  try {
    const filters = {
      page: 0,
      size: 250,
      cityId,
    };
    if (input) filters.name = `qlike(${input})`;
    const res = await axios.get(`${urls.districtList(cityId)}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const listDistricts = async (filters, { cityId }) => {
  try {
    if (filters.name) filters.name = `qlike(${filters.name})`;
    const res = await axios.get(`${urls.districtList(cityId)}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get subDistricts list (kelurahan)
 * @param {String} input subDistricts name search
 * @param {Number} districtId district id to get subDistricts from
 */
const subDistricts = async (input, districtId) => {
  try {
    const filters = {
      page: 0,
      size: 250,
      districtId,
    };
    if (input) filters.name = `qlike(${input})`;
    const res = await axios.get(`${urls.subDistrictList(districtId)}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * fetch csv template for locations
 * @returns response data
 */
const fetchCsvTemplateData = async () => {
  try {
    const res = await axios.get(`${urls.csvTemplateDataLocation}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * upload csv for locations
 * @param {FormData} formData csv data
 */
const uploadCsv = async (formData, type) => {
  try {
    const res = await axios.post(
      urls.uploadCsvLocation,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const reverseGeo = async (latitude, longitude) => {
  try {
    const res = await axios.get(`http://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&sensor=true&key=AIzaSyAUMvRAOumZMjEq_sUU_oVtrNVbcjPfIC8`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const locationDownload = async ({
  name, address, province, city, district, locationType,
}) => {
  try {
    const filters = {};
    if (name) filters.name = `qlike(${name})`;
    if (address) filters.address = `qlike(${address})`;
    if (province) filters.province = province;
    if (city) filters.city = city;
    if (district) filters.district = district;
    if (locationType) filters.locationType = locationType;
    const res = await axios.get(`${urls.downloadLocation}?${querystring.stringify(filters)}`, { responseType: 'blob' });
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getZipCode = async (provinceName, cityName, districtName, subDistrictName) => {
  try {
    const res = await axios.get(`${urls.master}/zipcode/${provinceName}/${cityName}/${districtName}/${subDistrictName}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchLocationList,
  fetchLocationDetail,
  createEditLocation,
  provinces,
  cities,
  listCity,
  districts,
  listDistricts,
  subDistricts,
  fetchCsvTemplateData,
  uploadCsv,
  reverseGeo,
  locationDownload,
  getZipCode,
};
