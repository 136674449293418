export default {
  ORACLE_INTERNAL: 'Biaya Oracle Internal',
  ORACLE_EXTERNAL: 'Biaya Oracle Eksternal',
  ADDITIONAL_COST_DATABASE: 'Database Biaya Angkut',
  SEARCH_TEXT: 'Cari Biaya Angkut',
  ITEM: 'Item',
  EDIT: 'Ubah Entri Biaya Angkut',
  CREATE: 'Buat Entri Biaya Angkut',
  CREATE_DESC: 'Buat Deskripsi Biaya Angkut',
  ADD_TEXT: 'Dikenakan Pajak',
  CREATED_AT: 'Dibuat Oleh',
  ADD: 'Tambah Biaya Angkut',
  VALUE: 'Deskripsi',
  TAX: 'Pajak',
  FLI: 'FLI',
  FIXED_PRICE: 'Fixed Price',
  ON_CALL: 'On Call',
  SUCCES_EDIT_STATUS: 'Berhasil mengubah status',
  FILTER_LIST: [
    {
      value: '',
      text: 'Semua Pajak',
    },
    {
      value: 'qistrue',
      text: 'Kena Pajak',
    },
    {
      value: 'qisfalse',
      text: 'Tidak Kena Pajak',
    },
  ],
};
