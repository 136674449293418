/* eslint-disable import/no-cycle */
import Vue from 'vue';
import { VAlert } from 'vuetify/lib';
import { auth } from '../../providers/services';
import router from '../../router';

const state = {
  access_token: localStorage.getItem('access_token') || '',
  menu: localStorage.getItem('menu') || [],
  defaultRoute: localStorage.getItem('defaultRoute') || '/main/dashboard',
};

const getters = {
  isLoggedIn: (_state) => !!_state.access_token && !!_state.menu.length > 0,
  menu: (_state) => _state.menu,
  defaultRoute: (_state) => _state.defaultRoute,
};

const mutations = {
  set_token(store, data) {
    store.access_token = data;
    localStorage.setItem('access_token', data);
  },
  set_menu(store, data) {
    const patternView = /VIEW_/;
    const patternCanCreate = /CREATE_|ADD_/;
    const patternCanUpdate = /EDIT_|UPDATE_/;
    const patternCanDelete = /DELETE_/;
    const checkViewPermission = (menuFunc = []) => menuFunc.map((curr) => curr.accessMenu).find((y) => patternView.test(y));
    const checkCanCreatePermission = (menuFunc = []) => menuFunc.map((curr) => curr.accessMenu).find((y) => patternCanCreate.test(y));
    const checkCanUpdatePermission = (menuFunc = []) => menuFunc.map((curr) => curr.accessMenu).find((y) => patternCanUpdate.test(y));
    const checkCanDeletePermission = (menuFunc = []) => menuFunc.map((curr) => curr.accessMenu).find((y) => patternCanDelete.test(y));
    const filterMenuByPermissionView = [];
    data.forEach((m) => {
      const child = [];
      if (m.child && m.child.length) {
        m.child.forEach((mc) => {
          if (checkViewPermission(mc.menuFunctions)) {
            child.push({
              ...mc,
              canCreate: !!checkCanCreatePermission(mc.menuFunctions),
              canUpdate: !!checkCanUpdatePermission(mc.menuFunctions),
              canDelete: !!checkCanDeletePermission(mc.menuFunctions),
            });
          }
        });
        if (child.length) {
          filterMenuByPermissionView.push({
            ...m,
            url: '',
            child,
          });
        }
      } else if (checkViewPermission(m.menuFunctions)) {
        filterMenuByPermissionView.push({
          ...m,
          canCreate: !!checkCanCreatePermission(m.menuFunctions),
          canUpdate: !!checkCanUpdatePermission(m.menuFunctions),
          canDelete: !!checkCanDeletePermission(m.menuFunctions),
        });
      }
    });
    const menu = JSON.stringify(filterMenuByPermissionView);
    store.menu = menu;
    if (filterMenuByPermissionView.length) localStorage.setItem('menu', menu);
  },
  set_defaultRoute(store, data) {
    store.defaultRoute = data;
    localStorage.setItem('defaultRoute', data);
  },
  logout(store) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('menu');
    localStorage.removeItem('defaultRoute');
    store.access_token = '';
    store.menu = '';
  },
};

const actions = {
  async login({ commit }, data) {
    try {
      const loginRes = await auth.login(data);
      if (loginRes.data.menu && loginRes.data.menu.length === 0) {
        return Vue.prototype.$dialog.notify.error('terjadi kesalahan. menu tidak ditemukan silahkan hubungi admin', {
          timeout: 5000,
        });
      }
      commit('set_menu', loginRes.data.menu);
      const menu = JSON.parse(state.menu);
      if (!menu.length) {
        VAlert.prototype.$dialog.notify.error('Menu tidak ditemukan', { timeout: 5000 });
        throw new Error('Menu Not Allowed');
      }
      if (menu[0].child.length > 0) {
        commit('set_defaultRoute', menu[0].child[0].url);
      } else {
        commit('set_defaultRoute', menu[0].url);
      }
      commit('set_token', loginRes.data.token);
      return loginRes;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async logout({ commit }, responseStatus) {
    commit('logout');
    commit('user/reset_my_user_info', null, { root: true });
    commit('user/set_increment_count_auth_guard', 0, { root: true });
    if (responseStatus === 403) return router.go('/welcome/login');
    if (router.currentRoute.path !== '/welcome/login') {
      router.replace('/welcome');
    }
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
