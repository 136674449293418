/* eslint-disable import/no-cycle */
import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';
import store from '../../store';

/**
 * get user admin list
 * @param {Object} filter list filter parameters
 * @param {Number} filter.page page number
 * @param {Number} filter.size page size
 * @param {String} filter.name page size
 * @param {String} filter.name page size
 * @param {String} filter.name page size
 */
const fetchList = async (filters) => {
  try {
    const res = await axios.get(
      `${urls.userAdminList}?${querystring.stringify(filters)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * fetch a user admin's detail
 * @param {Number} companyId user's company id
 * @param {Number} userId user's id
 * @returns response data
 */
const fetchDetail = async (companyId, userId) => {
  try {
    const res = await axios.get(urls.userAdminDetail(userId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * create and edit UserAdmin
 * @param {Object} form submitted form
 * @param {String} form.companyId user admin clientId
 * @param {String} form.email user admin email
 * @param {String} form.rolesId user admin role
 * @param {String} form.msisdn user admin phone
 * @param {String} form.imageUrl user admin imageUrl
 * @param {Number} [userId] UserAdmin Id for edit detail
 */
const createEdit = async (form, userId) => {
  try {
    let res;
    if (!userId) {
      res = await axios.post(urls.userAdminCreate, form);
    } else {
      res = await axios.put(`${urls.userAdminDetail(userId)}`, form);
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * reset user's password
 * @param {Object} user user object
 * @param {String} user.companyId user companyId
 * @param {String} user.companyUserId user companyUserId
 * @returns Promise
 */
const resetPassword = async (user) => {
  try {
    const { companyUserId } = user;
    const res = await axios.get(urls.userAdminReset(companyUserId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * reset user's password
 * @param {Object} user user object
 * @param {String} user.companyId user companyId
 * @param {String} user.companyUserId user companyUserId
 * @returns Promise
 */
const deleteUser = async (user) => {
  try {
    const { companyUserId } = user;
    const res = await axios.delete(urls.userAdminDelete(companyUserId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get role list
 * @returns response data
 */
const roles = async () => {
  try {
    const res = await axios.get(urls.roleList);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const resendEmail = async (user) => {
  try {
    const { companyUserId } = user;
    const res = await axios.post(urls.userAdminResend(companyUserId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const accountInfo = async (id) => {
  try {
    const res = await axios.get(urls.getAccountInfo(id));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateAccountInfo = async (form, id) => {
  try {
    const {
      photoProfileUrl,
    } = form;
    const data = {
      photoProfileUrl,
    };
    const res = await axios.patch(`${urls.getAccountInfo(id)}`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchList,
  fetchDetail,
  createEdit,
  resetPassword,
  deleteUser,
  roles,
  resendEmail,
  accountInfo,
  updateAccountInfo,
};
