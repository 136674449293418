export default {
  ORDER_DETAIL: 'Detail Kiriman',
  GROUP_TITLE_NUMBER: 'No Kargo',
  ORDER_NO: 'No Kiriman',
  DELIVERY_DATE: 'Tanggal Pengiriman',
  CREATE_DATE: 'Tanggal Buat',
  ORIGIN: 'Lokasi Asal',
  DESTINATION: 'Lokasi Tujuan Akhir',
  ETA: 'ETA',
  DESC_TARIF: 'Ket Tarif',
  ORDER_NUMBER: 'No Pesanan',
  SERVICE_TYPE: 'Jenis Tarif',
  CUSTOMER_NAME: 'Nama PIC Pengirim',
  MULTIPICK: 'Multipick',
  MULTIPICK_LOCATION: 'Lokasi Multipick',
  MULTIDROP_LOCATION: 'Lokasi Multidrop',
  MULTIDROP: 'Multidrop',
  NO_GRUP_CARGO: 'No Grup Kargo',
  NO_CARGO: 'No Kargo',
  TRANSPORTER: '3PL',
  TRANSPORTER_NAME: 'Nama 3PL',
  DELIVERY_DATE_ORIGIN: 'Tgl Pengiriman',
  DELIVERY_DATE_DESTINATION: 'Tgl Berangkat',
  NO_AVIATION_SERVICE: 'No. Pelayanan Penerbangan',
  SHIPPER: 'Shipper',
  SHIPPER_INFO: 'Informasi Shipper',
  TRIP_INFO: 'Informasi Perjalanan',
  ROUTE_INFO: 'Informasi Rute',
  VEHICLE_INFO: 'Informasi Kendaraan',
  MULTIMODA_INFO: 'Informasi Multi Moda',
  VEHICLE: 'Kendaraan',
  VEHICLE_NUMBER: 'No. Kendaraan',
  LABEL3PL: 'Rekanan 3PL',
  LAST_LOCATION: 'Lokasi Terakhir',
  STATUS: 'Status',
  SEARCHNO: 'Cari No. Kiriman/Pesanan/Cargo',
  FILTER: 'Filter Berdasarkan',
  PAGE_NAME: 'Daftar Pesanan',
  MERGE: 'Gabungkan',
  HAVE_NOT_BEEN_ASSIGNED: 'Belum diassign satupun',
  PARTIALLY_ASSIGNED: 'Ada sebagian yang belum diassign',
  ASSIGN1: 'Assign / Ubah 3PL',
  CHANGE_ROUTE: 'Ubah Rute',
  ADD_RUTE: 'Tambah Rute',
  SEND_DATE: 'Tanggal Pengiriman',
  TYPE_VEHICLE: 'Tipe Kendaraan',
  PICK_UP_LOCATION: 'Lokasi Penjemputan',
  LOCATION_IN_ACCEPTED: 'Lokasi Bongkar',
  MERGE_ORDER_LCL: 'Gabungkan Pesanan LCL',
  COMBINED_ORDERS: 'Pesanan yang sudah digabungkan',
  NOT_COMBINED_ORDERS: 'Pesanan yang belum digabungkan',
  TONASE: 'Tonase',
  KUBIKASI: 'Kubikasi',
  FILTER_PROVINSI_ORIGIN: 'Filter Provinsi Asal',
  FILTER_PROVINSI_DESTINATION: 'Filter Provinsi Tujuan',
  SELECT: 'Pilih',
  CANCEL_ORDER: 'Batalkan Pesanan',
  RESEND_TO_ORACLE: 'Kirim Ulang ke Orcale',
  ACTION: 'Aksi',
  ASSIGN_MULTI_MODA: 'Assign Rekanan 3PL - Multi Moda',
  ASSIGN_3PL: 'Assign Rekanan 3PL',
  PLEASE_SELECT: 'Silahkan pilih terlebih dahulu',
  UPDATE_STATUS: 'Update Status',
  CHANGE_TRANSPORTER: 'Ubah Rekanan 3PL & Data Multi Moda',
  ALERT_DELETE_MULTIMODA: 'Apakah anda yakin ingin membatalkan Kiriman ini ?',
  NOTE: 'Catatan',
  STATUS_NOW: 'Status Saat Ini',
  NEW_STATUS: 'Status Baru',
  BACKHAULING: 'Backhauling',
  ACCEPT_ORDER: 'Terima Pesanan',
  REJECT_ORDER: 'Tolak Pesanan',
  LIST_COST: 'List biaya ongkos kirim',
  UPDATE_INFORMATION: 'Ubah / Update Informasi',
  TYPE_MODA: 'Tipe Moda',
  LUMPSUM: 'Lumpsum',
  CRUISE_NUMBER: 'No.Pelayaran/Penerbangan',
  RITASE: 'Ritase',
  KILO: 'Kilo',
  ONCALL: 'Oncall',
  BOX: 'Box',
  QUANTITY_INFO: 'Informasi Quantity',
  DRIVER_NAME: 'Nama Sopir',
  CAR_TYPE: 'Tipe Mobil',
  NO_TRANSPORTER: 'No Kendaraan',
  REPORT_THE_PROBLEM: 'Laporkan Masalah',
  ACCIDENT_DATE: 'Tanggal Laporan',
  BAST_DATE: 'Tanggal Serah Terima',
  REPORT_DATE: 'Tanggal Laporan',
  CONTACT_DRIVER: 'Kontak Sopir',
  FILTER_PROVINCE: 'Filter Provinsi',
  TARIF_INFO: 'Keterangan Tarif',
  SHIPPER_NAME: 'Nama Shipper',
  NAME_SENDER: 'Nama Pengirim',
  CATEGORY: 'Kategori',
  SUB_CATEGORY: 'Sub Kategori',
  PHONE_NUMBER_SENDER: 'No Telepon Pengirim',
  LABEL_STATUS_ORDER: 'Status Pesanan',
  DOCUMENTATION: 'Dokumentasi',
  COMPLETED_DOCUMENT: 'Dokumen Lengkap',
  FOTO_LATTER: 'Foto Surat Jalan',
  ADD_DOCUMENT_FOTO: 'Tambah Foto Dokumen',
  LOCATION_MAP: 'Peta Lokasi Asal dan Tujuan Pesanan',
  COMPLETE_THE_ORDER: 'Selesaikan Pesanan',
  COMPLATE_THE_CARGO: 'Selesaikan Kargo',
  CANCEL_THE_ORDER: 'Batalkan Pesanan',
  CONFIRMED: 'Terkonfirmasi',
  CARGO_STATUS: 'Status Kargo',
  CALCULATE_COST: 'Hitung Biaya',
  MAIN_COST: 'Biaya Utama',
  ADDITIONAL_COST: 'Biaya Tambahan',
  AMOUNT_OF_COSTS: 'Jumlah Biaya (Rp)',
  DESC_COSTS: 'Keterangan',
  TOTAL_COST: 'Total Biaya (Rp)',
  TOTAL_BILL: 'Total Tagihan (Rp)',
  LABEL_TOTAL_BILL: 'Total Tagihan',
  GOODS_RECEIVED_WAREHOUSE: 'Barang Diterima Gudang',
  CALCULATE: 'Hitung',
  CHANGE_QUANTITY_ORDER: 'Mengubah quantity untuk order',
  NO_ROAD_LETTER: 'No Surat Jalan',
  QUANTITY: 'Quantity',
  UNIT: 'Unit',
  COMPLATED_THE_ORDER: 'Menyelesaikan Pesanan',
  LABEL_CANCEL_ORDER: 'Apakah anda yakin ingin membatalkan Pesanan ini?',
  LABEL_DELETE_RUTE: 'Apakah anda yakin untuk menghapus rute?',
  OVERWRITE_CONFIRMATION_SHIPPER: 'Overwrite konfirmasi Shipper',
  LABEL_GOODS_RECEIVED_WAREHOUSE: 'Apakah anda yakin barang diterima Gudang?',
  UPLOAD_FOTO_DOCUMENTATION: 'Unggah Foto Dokumentasi',
  DOCUMENT_TYPE: 'Tipe Dokumen',
  DOCUMENT_PHOTOS: 'Dokumen Photo',
  MASSA: 'Tonase(kg)',
  CUBIC: 'Kubikasi(m3)',
  BOX_CARTON: 'Karton(ktn)',
  TONNAGE: 'Tonase',
  LOADING_TEXT: 'Loading... Please wait',
  CALCULATE_QTY: 'Hitung quantity',
  CALCULATION_PROCESS: 'Proses Hitung',
  SAVE_AND_CONTINUE: 'Simpan dan Lanjutkan',
  CREATE_BAST: 'Berita Acara Serah Terima',
  GOODS_HANDOVER: 'Pernyataan Serah Terima Barang',
  CONFIRMATION_DATE: 'Tanggal Konfirmasi',
  CARGO_NUMBER: 'No Kargo',
  REPORT_DELIVERY_PROBLEMS: 'Laporkan Masalah Pengiriman',
  LATE_CAUSE: 'Yang Menyebabkan Pengiriman Terlambat',
  ADDRESS_LOCATION: 'Lokasi Alamat',
  POSITION: 'Posisi',
  PROOF_OF_PROBLEM: 'Bukti Masalah',
  UPDATE_MULTIMODA: 'Update Informasi - Multi Moda',
  NO_CONTAINER: 'No. Kontainer',
  NO_CRUISE_FLIGHTS: 'No Pelayaran / Penerbangan',
  SHIP_NAME: 'Nama Kapal / Pesawat',
  CRUISE_NAME_MASKAPAI: 'Nama Pelayaran / Maskapai',
  ESTIMATION_OF_DEPARTURE: 'Estimasi Keberangkatan',
  ESTIMATION_ARRIVED: 'Estimasi Tiba',
  DEPATURE_REALIZATION: 'Realisasi Keberangkatan',
  REALIZATION_ARRIVES: 'Realisasi Tiba',
  CURRENT_STATUS: 'Status saat ini',
  STATUS_ORDER: {
    CANCEL: 'Dibatalkan',
    CONFIRM: 'Terkonfirmasi',
  },
  STATUS_LIST_WAITING: [
    {
      text: 'Pesanan Dibuat',
      value: 'PESANAN_DIBUAT',
    },
    {
      text: 'Menunggu Konfirmasi',
      value: 'MENUNGGU_KONFIRMASI',
    },
    {
      text: 'Waktu Habis',
      value: 'WAKTU_HABIS',
    },
  ],
  STATUS_LIST_PROCESS: [
    {
      text: 'Terkonfirmasi',
      value: 'TERKONFIRMASI',
    },
    {
      text: 'Penjemputan',
      value: 'PENJEMPUTAN',
    },
    {
      text: 'Di Lokasi Penjemputan',
      value: 'DI_LOKASI_PENJEMPUTAN',
    },
    {
      text: 'Pengiriman',
      value: 'PENGIRIMAN',
    },
    {
      text: 'Di Lokasi Tujuan',
      value: 'DI_LOKASI_TUJUAN',
    },
    {
      text: 'Menunggu Muat Barang',
      value: 'MENUNGGU_MUAT_BARANG',
    },
    {
      text: 'Muat Barang',
      value: 'MUAT_BARANG',
    },
    {
      text: 'Menyebrang Pengiriman',
      value: 'MENYEBRANG_PENGIRIMAN',
    },
    {
      text: 'Dalam Penerbangan',
      value: 'DALAM_PENERBANGAN',
    },
    {
      text: 'Dalam Penyebrangan',
      value: 'DALAM_PENYEBRANGAN',
    },
    {
      text: 'Sampai Bandara',
      value: 'SAMPAI_BANDARA',
    },
    {
      text: 'Sampai Pelabuhan',
      value: 'SAMPAI_PELABUHAN',
    },
    {
      text: 'Bongkar Muatan',
      value: 'BONGKAR_MUATAN',
    },
    {
      text: 'BAP - Konfirmasi Shipper',
      value: 'BA_PENERIMAAN_KONFIRMASI_SHIPPER',
    },
  ],
  STATUS_LIST_COMPLETED: [
    {
      text: 'Diterima Utuh',
      value: 'BARANG_DITERIMA_UTUH',
    },
    {
      text: 'Diterima Tidak Utuh',
      value: 'BARANG_DITERIMA_TIDAK_UTUH',
    },
    {
      text: 'Dibatalkan',
      value: 'DIBATALKAN',
    },
    {
      text: 'Ditolak',
      value: 'DITOLAK',
    },
    {
      text: 'Kedaluwarsa',
      value: 'KEDALUWARSA',
    },
    {
      text: 'BAP - Diterima Gudang',
      value: 'BA_PENERIMAAN_DITERIMA_GUDANG',
    },
    {
      text: 'BAP - Kembali ke Gudang Asal',
      value: 'BA_PENERIMAAN_KEMBALI_KE_GUDANG_ASAL',
    },
    {
      text: 'BAP - Ganti Lokasi Tujuan',
      value: 'BA_PENERIMAAN_GANTI_LOKASI_TUJUAN',
    },
  ],
  NEED_CONFIRMATION: 'Butuh Konfirmasi',
  IN_PROCESS: 'Dalam Proses',
  COMPLETED: 'Selesai',
  INPUT_ORDER_NO: 'Masukkan nomor pesanan',
  PICKUP_DATE: 'Tanggal Pengambilan Barang',
  ADDITIONAL_INFORMATION: 'Informasi Tambahan',
  EXPIRED: 'Kedaluwarsa',
  VIEW: 'Lihat',
  VEHICLE_PLATE: 'Nomor Kendaraan',
  DRIVER_CONTACT: 'Kontak Sopir',
  BASP_LOCATION: 'Lokasi BASP',
  BASP_NOTE: 'Catatan BASP',
  BASP_TITLE: 'Alasan BASP',
  SENDER_NAME: 'Nama Pengirim',
  SENDER_NOTE: 'Catatan Pengiriman',
  REASON_CANCELLATION: 'Alasan Pembatalan',
  CONTACT_NUMBER: 'Nomor Telepon',
  STATUS_HISTORY: 'Status History',
  RETURN_NOTE: 'Catatan Pengembalian',
  CONTINUE_SHIPPING: 'Lanjutkan Pengiriman',
  MAKE_CARGO_GROUP: 'Jadikan Group Kargo',
  MAKE_LUMPSUM: 'Jadikan Lumpsum',
  SELECT_3PL: 'Pilih Rekanan 3PL',
  IS_AN_ADVANCED_ROUTE: 'Merupakan Rute lanjutan',
  REASON_CANCEL_ORDER: 'Silahkan pilih alasan pembatalan anda',
  BASP_REASON: 'Alasan BASP',
  EXPLANATION_OF_REASON: 'Keterangan Alasan',
  EXPLANATION_OF_REASON_BASP: 'Alasan Keterangan BASP',
  TYPE_OF_GOODS: 'Jenis Barang',
  DESCRIPTION: 'Keterangan',
  PROOF_OF_DIFFERENCE_IN_ACCEPTANCE: 'Bukti Selisih Penerimaan',
  GOODS_CATEGORY: 'Kategori Barang',
  GOODS_NOTE: 'Catatan Barang',
  GOODS_SUB_CATEGORY: 'Sub Kategori Barang',
  RECEPIENTS_NAME: 'Nama Penerima',
  PHONE_NO_RECIPIENT: 'Nomor Telepon Penerima',
  RECIPIENT_NOTE: 'Catatan Penerimaan',
  SIGNATURE: 'Tanda Tangan',
  DELIVERY_COMPLETE: 'Penerimaan Selesai',
  DAMAGE_REPORT: 'Laporan Kerusakan / Selisih',
  REPORT_DIFFERENCE: 'Laporkan Selisih',
  CHANGE: 'Ubah',
  DELETE_ROUTE: 'Hapus Rute',
  VIEW_STATUS_CARGO: 'Status Kargo',
  ADDITIONAL_NOTE: 'Catatan Tambahan',
  ORDER_TAB: {
    WAITING: 'Menunggu',
    PROCESS: 'Dalam Proses',
    COMPLETED: 'Selesai',
    TRANSPORTER_CONFIRMATION: 'Konfirmasi 3PL',
    DRIVER_CONFIRMATION: 'Konfirmasi Driver',
  },
  SEND_BASP: 'Kirim BASP',
  UPLOAD_ITEMS: 'Upload Barang',
  CHECK_ITEMS: 'Cek Barang',
};
