export default {
  headerCatatan: 'Catatan',
  headerNamaCustomer: 'Nama Shipper',
  headerNoPesanan: 'No Pesanan',
  headerRekanan3PL: 'Rekanan 3PL',
  headerKendaraan: 'Kendaraan',
  headerSopir: 'Sopir',
  pageName: 'Daftar Pengiriman Rusak dan Selisih',
  headerPage: 'Kerusakan Dan Selisih',
};
