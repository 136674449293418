import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import urls from './urls';

const login = async function login({ email, password }) {
  try {
    const data = {
      email,
      password,
      fcm: store.state.user.fcmToken,
    };
    const res = await axios.post(urls.auth, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const logout = async function logout() {
  try {
    const res = await axios.delete(urls.logout);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  login,
  logout,
};
