import axios from 'axios';
import querystring from 'query-string';
import { skipEmptyStringObject } from '../../helper/commonHelpers';
import urls from './urls';

const getListMasterCustomer = async (filter) => {
  try {
    const res = await axios.get(`${urls.masterCustomerList}?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getPayload = async (id) => {
  try {
    const res = await axios.get(`${urls.getPayload(id)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const resendOracleCode = async (id, source = {}) => {
  try {
    const res = await axios.post(`${urls.resendToOracle(id)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getListMasterCustomer, resendOracleCode, getPayload,
};
