<template>
  <v-row>
    <v-col cols="auto" sm="6" v-if="label">{{label}}</v-col>
    <v-col cols="auto" sm="6" class="d-flex pb-0">
      <v-menu
        ref="menuDate"
        v-model="menuDate"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            outlined
            :rules="requiredDate ? [v => !!v || $_strings.messages.validation.REQUIRED('Tanggal')] : []"
            class="caption"
            placeholder="Tanggal"
            :value="tools[date] ? dayjs(tools[date]).format('DD-MM-YYYY') : ''"
            prepend-inner-icon="mdi-calendar"
            readonly
            :disabled="!requiredDate"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="tools[date]"
          no-title
          :disabled="!requiredDate"
          scrollable
          @input="menuDate = false"
          locale="id"
        >
        </v-date-picker>
      </v-menu>
      <v-text-field
        label="Jam"
        v-mask="{'alias': 'time', rightAlign: false}"
        outlined
        dense
        prepend-inner-icon="mdi-clock-time-four-outline"
        class="caption ml-2"
        v-model="tools[time]"
        :rules="requiredTime ? rules.time : []"
        :disabled="!requiredTime"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'date-and-time',
  props: {
    tools: {
      typeof: Object,
      default: () => {},
    },
    requiredDate: {
      typeof: Boolean,
      default: false,
    },
    requiredTime: {
      typeof: Boolean,
      default: false,
    },
    label: {
      typeof: String,
      default: '',
    },
    date: {
      typeof: String,
      default: '',
    },
    time: {
      typeof: String,
      default: '',
    },
  },
  data() {
    return {
      menuDate: false,
      menuTime: false,
      rules: {
        time: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Jam'),
          (value) => {
            const pattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]?$/;
            return pattern.test(value) || 'Format jam salah';
          },
        ],
      },
    };
  },
  methods: {
    dayjs,
  },
};
</script>
