<template>
  <v-text-field
    ref="textfield"
    v-model="displayValue"
    v-bind="$attrs"
    v-mask="{'alias': 'idr-currency', rightAlign: false}"
  />
</template>

<script>
export default {
  props: {
    value: {},
  },
  computed: {
    displayValue: {
      get() {
        return this.formatAsCurrency(this.value);
      },
      set(val) {
        if (+String(val).charAt(0) === 0) {
          this.$emit('input', +String(val).charAt(1));
          return;
        }
        const newValue = val.replace(/[A-Za-z]/g, 0).replace(/[^0-9]/g, '');
        this.$emit('input', +newValue);
      },
    },
  },
  methods: {
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
  },
};
</script>
