/* eslint-disable import/no-cycle */
import axios from 'axios';
import urls from './urls';
import store from '../../store';

/**
 * get legal document list
 */
const getLegalDocumentList = async () => {
  try {
    const res = await axios.get(urls.legalDocumentList);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * get attachment list
 * @param {Number} documentSupportId document support id
 */
const getListAttachment = async (documentSupportId) => {
  try {
    const res = await axios.get(urls.legalAttachmentList(documentSupportId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * save an attachment
 * @param {Number} refId ref id
 * @param {String} url attachment id
 * @param {string} name attachment name
 * @param {Number} size file size
 * @param {String} type file type
 */
const saveAttachment = async (data) => {
  try {
    const { companyId } = store.state.user.myUserInfo;
    const res = await axios.post(urls.legalAttachmentSave(companyId), data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * delete an attachment
 * @param {Number} fileAttachmentId file attachment id
 */
const deleteAttachment = async (fileAttachmentId) => {
  try {
    const { companyId } = store.state.user.myUserInfo;
    const res = await axios.delete(urls.legalAttachmentDelete(companyId, fileAttachmentId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getLegalDocumentList,
  getListAttachment,
  saveAttachment,
  deleteAttachment,
};
