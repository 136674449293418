import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const getListTransporter = async (companyName = '', source) => {
  try {
    const filters = {
      page: 0,
      size: 20,
      sort: 'companyName,asc',
    };
    if (companyName) {
      filters.companyName = companyName;
    }
    const res = await axios.get(`${urls.listTransporter}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListShipper = async (companyName = '', source) => {
  try {
    const filters = {
      page: 0,
      size: 20,
      sort: 'companyName,asc',
    };
    if (companyName) {
      filters.companyName = companyName;
    }
    const res = await axios.get(`${urls.listShipper}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListTransporterAndCustomer = async (source) => {
  try {
    const resListTransporter = await getListTransporter('', source);
    const resListShipper = await getListShipper('', source);
    return Promise.all([resListTransporter, resListShipper]);
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListCalender = async ({
  date,
  shipperId,
  transporterId,
}, source) => {
  try {
    const filters = {
      date, shipperId, transporterId, size: 10,
    };
    if (shipperId) {
      filters.shipperId = shipperId;
    } else {
      delete filters.shipperId;
    }
    if (transporterId) {
      filters.transporterId = transporterId;
    } else {
      delete filters.transporterId;
    }
    const res = await axios.get(`${urls.listCalender}?${querystring.stringify(filters)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListExpandCalender = async (shipmentId) => {
  try {
    const res = await axios.get(`${urls.listCalender}/${shipmentId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export {
  getListTransporter,
  getListShipper,
  getListTransporterAndCustomer,
  getListCalender,
  getListExpandCalender,
};
