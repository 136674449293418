import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const getServiceType = async (companyId, filters) => {
  try {
    const res = await axios.get(
      `${urls.getServiceType(companyId)}?${querystring.stringify(filters)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getTransportType = async (companyId, serviceType, filters) => {
  try {
    const res = await axios.get(
      `${urls.getTransportType(companyId, serviceType)}?${querystring.stringify(filters)}`,
    );
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// const getOriginLocation = async (filters, { mouId, serviceType }) => {
//   try {
//     const res = await axios.get(
//       `${urls.mouShipperRoute}/${mouId}/route/${serviceType}?${querystring.stringify(filters)}`,
//     );
//     return res.data;
//   } catch (e) {
//     return Promise.reject(e);
//   }
// };

const getOriginLocation = async (filter, { mouId, serviceType }) => {
  try {
    const res = await axios.get(`${urls.mouShipperRoute}/${mouId}/route/${serviceType}/origin?${querystring.stringify(filter)}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getRoute = async (companyId, serviceType, filters) => {
  try {
    const res = await axios.get(
      `${urls.getRouteManualOrder(companyId, serviceType)}?${querystring.stringify(filters)}`,
    );
    return Promise.resolve(res.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const getRouteForAutoComplete = async (
  filters,
  {
    companyId,
    serviceType,
    pickupDate,
    originId,
    transportTypeId,
  },
) => {
  try {
    const res = await axios.get(
      `${urls.getRouteManualOrder(companyId, serviceType)}?${querystring.stringify({
        ...filters,
        pickupDate,
        originId,
        transportTypeId,
      })}`,
    );
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getRouteBackhauling = async (companyId, backhaulingId, filters) => {
  try {
    const res = await axios.get(
      `${urls.getBackhauling(companyId, backhaulingId)}?${querystring.stringify(filters)}`,
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getMultishipper = async (filters) => {
  try {
    const res = await axios.get(`${urls.getMultishipper}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const createShipments = async (shipperId, statusDatetimeTz, shipments) => {
  try {
    const form = {
      shipperId,
      statusDatetimeTz,
      shipments,
    };
    const res = await axios.post(urls.createShipment, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getServiceType,
  getTransportType,
  getRoute,
  createShipments,
  getMultishipper,
  getRouteBackhauling,
  getOriginLocation,
  getRouteForAutoComplete,
};
