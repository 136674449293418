/* eslint-disable import/no-cycle */
import axios from 'axios';
import interceptorSetup from './interceptors';

import * as auth from './auth';
import * as user from './user';
import * as account from './account';
import * as parameter from './parameter';
import * as masterData from './masterData';
import * as categories from './categories';
import * as uploads from './uploads';
import * as locations from './locations';
import * as userAdmins from './userAdmins';
import * as transportTypes from './transportTypes';
import * as order from './order';
import * as manualOrders from './manualOrders';
import * as contract from './contract';
import * as addendum from './addendum';
import * as additionalCost from './additionalCost';
import * as legalDocument from './legalDocument';
import * as approval from './approval';
import * as companyRegistration from './companyRegistration';
import * as terminationContract from './terminationContract';
import * as mou from './mou';
import * as invoiceData from './invoiceData';
import * as eta from './eta';
import * as dokumenPod from './dokumenPod';
import * as shippingCost from './shippingCost';
import * as email from './email';
import * as dashboard from './dashboard';
import * as invoice from './invoice';
import * as masterShipper from './masterShipper';
import * as masterPatner from './masterPatner';
import * as vaccine from './vaccine';
import * as areaCode from './areaCode';
import * as shippingProblem from './shippingProblem';
import * as notifications from './notifications';
import * as schedule from './schedule';
import * as damageAndMissing from './damageAndMissing';
import * as termOfPayment from './termOfPayment';
import * as report from './report';
import * as wording from './wording';
import * as tax from './tax';
import * as p2pLending from './p2pLending';
import * as holiday from './holiday';
import * as closePeriod from './closePeriod';
import * as oracleCost from './oracleCost';
import * as masterSales from './masterSales';
import * as masterCustomer from './masterCustomer';
import * as invoiceCustomerCancel from './invoiceCustomerCancel';
import * as invoiceFixedPriceCustomerCancel from './invoiceFixedPriceCustomerCancel';
import * as oracleData from './oracleData';
import * as role from './role';
import * as menu from './menu';
import * as rejectedOrders from './rejectedOrders';
import * as transport from './transport';
import * as driver from './driver';

if (process.env.VUE_APP_API_TARGET === 'mock') {
  console.log('TCL: services index VUE_APP_API_TARGET', process.env.VUE_APP_API_TARGET);
  // eslint-disable-next-line global-require
  require(/* webpackChunkName: "mock-services" */ '../mock-services'); // import mock adapter if env is mock
}

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
interceptorSetup();

export {
  auth,
  user,
  account,
  parameter,
  masterData,
  categories,
  uploads,
  locations,
  userAdmins,
  transportTypes,
  order,
  manualOrders,
  contract,
  addendum,
  additionalCost,
  legalDocument,
  approval,
  companyRegistration,
  terminationContract,
  mou,
  invoiceData,
  eta,
  dokumenPod,
  shippingCost,
  email,
  dashboard,
  invoice,
  invoiceCustomerCancel,
  invoiceFixedPriceCustomerCancel,
  masterShipper,
  masterPatner,
  masterSales,
  vaccine,
  areaCode,
  shippingProblem,
  notifications,
  schedule,
  damageAndMissing,
  termOfPayment,
  report,
  wording,
  tax,
  p2pLending,
  holiday,
  closePeriod,
  oracleCost,
  oracleData,
  masterCustomer,
  role,
  menu,
  rejectedOrders,
  transport,
  driver,
};
