import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

const transportList = async (filters) => {
  try {
    const res = await axios.get(`${urls.transportList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * create and edit Transport
 * @param {Object} form submitted form
 * @param {String} form.name transport name
 * @param {Number} form.length transport length
 * @param {Number} form.width transport width
 * @param {Number} form.height transport height
 * @param {Number} form.volume transport volume
 * @param {Number} form.maxWeight transport maxWeight
 * @param {String} form.imageUrl transport imageUrl
 * @param {Number} [transportId] Transport Type Id
 */
const createEditTransport = async (form, transportId) => {
  try {
    let res;
    const {
      kirExpiredDate,
      stnkExpiredDate,
      companyDriverId,
      imageUrl,
      isActive,
      note,
      companyId,
    } = form;
    const data = {
      kirExpiredDate,
      stnkExpiredDate,
      companyDriverId,
      imageUrl,
      isActive,
      note,
      companyId,
    };
    if (!transportId) {
      res = await axios.post(`${urls.transportCreate}`, { ...form });
    } else {
      res = await axios.patch(`${urls.transportDetail(transportId)}`, data);
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const changeStatus = async (form, id) => {
  try {
    const { isActive, companyId } = form;
    const data = {
      companyId,
      isActive,
    };
    const res = await axios.patch(`${urls.transportDetail(id)}`, data);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const driverList = async (filters) => {
  try {
    const res = await axios.get(`${urls.driver}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const driver = async () => {
  try {
    const res = await axios.get(urls.driverTransport);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const typeTransportSearch = async (val) => {
  try {
    const filters = {
      size: 20, name: `qlike(${val})`,
    };
    const res = await axios.get(`${urls.transportType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const transportTypes = async (filters) => {
  try {
    const { name } = filters;
    if (name) filters.name = `qLike(${name})`;
    filters.isActive = 'qistrue';
    const res = await axios.get(`${urls.transportType}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const transportDetail = async (transportId) => {
  try {
    const res = await axios.get(urls.transportDetail(transportId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchCsvTemplateData = async () => {
  try {
    const res = await axios.get(`${urls.csvTemplateTransport}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const uploadCsv = async (formData) => {
  try {
    const res = await axios.post(
      urls.csvUploadTransport,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const deleteTransport = async (transportId) => {
  try {
    const res = await axios.delete(urls.transportDetail(transportId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  transportList,
  createEditTransport,
  driver,
  driverList,
  transportTypes,
  transportDetail,
  fetchCsvTemplateData,
  uploadCsv,
  typeTransportSearch,
  changeStatus,
  deleteTransport,
};
