import axios from 'axios';
import urls from './urls';
import { querystring } from '../../helper/commonHelpers';

const fetchRejectedOrders = async ({ filter }) => {
  try {
    const res = await axios.get(`${urls.shipmentOrder}/REJECT?${querystring(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateRejectedOrder = async ({ form, groupId, transportTypeId }) => {
  try {
    const res = await axios.patch(`${urls.shipmentOrder}/reject/${groupId}/${transportTypeId}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchRejectedOrders,
  updateRejectedOrder,
};
