import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

/**
 * fetch driver list
 * @param {Object} filter
 *  @param {Number} filter.page Page number
 *  @param {Number} filter.size list size
 *  @param {String} filter.sort sort by (<field>, Asc|Desc)
 *  @param {String} filter.name search text
 */
const driverList = async (filters) => {
  try {
    const res = await axios.get(`${urls.driverList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const driverDetail = async (driverId) => {
  try {
    const res = await axios.get(urls.driverDetail(driverId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * create and edit Driver
 * @param {Object} form submitted form
 * @param {String} form.name driver name
 * @param {Number} form.email driver email
 * @param {Number} form.phoneNo driver phoneNo
 * @param {Number} form.licenseNo driver licenseNo
 * @param {Number} form.licenseExp driver licenseExp
 * @param {String} form.profileImageUrl driver profileImageUrl
 * @param {String} form.licenseImageUrl driver licenseImageUrl
 * @param {Number} [driverId] Location id for edit/update
 */
const driverCreateEdit = async (form, driverId) => {
  try {
    let res;
    if (!driverId) {
      res = await axios.post(`${urls.driverCreateEdit}`, form);
    } else {
      res = await axios.patch(`${urls.driverList}/${driverId}`, form);
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * delete driver
 * @param {Number} driverId driverId
 */

const deleteDriver = async (driverId, companyId) => {
  try {
    const res = await axios.delete(urls.deleteDriver(driverId, companyId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const resetPassword = async (driverId) => {
  try {
    const res = await axios.put(urls.resetPassword(driverId));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * fetch csv template for driver
 * @returns response data
 */
const fetchCsvTemplateData = async () => {
  try {
    const res = await axios.get(`${urls.csvTemplateDataDriver}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * upload csv for drriver
 * @param {FormData} formData csv data
 */
const uploadCsv = async (formData, type) => {
  try {
    const res = await axios.post(
      urls.uploadCsvDriver,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const vaksin = async () => {
  try {
    const res = await axios.get(`${urls.listVaksinDriver}?size=20&sort=name,asc&isActive=qistrue`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const driverListOptions = async (filter, optionalFilter) => {
  try {
    const filters = {
      ...filter,
      ...optionalFilter,
    };
    const res = await axios.get(`${urls.driverList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  driverList,
  driverCreateEdit,
  driverDetail,
  deleteDriver,
  resetPassword,
  fetchCsvTemplateData,
  uploadCsv,
  vaksin,
  driverListOptions,
};
