<template>
  <v-row>
    <v-col>
      <p class="mb-0 grey--text">Legend :</p>
      <p class="mb-0 grey--text">File size max 2MB</p>
      <p class="mb-0 grey--text">File format PDF, DOC, JPG, PNG</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'upload-legend',
};
</script>
