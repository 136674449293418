import axios from 'axios';
import querystring from 'query-string';
import { skipEmptyStringObject } from '../../helper/commonHelpers';
import urls from './urls';

const getListMasterShipper = async (filters, optionalFilter) => {
  try {
    if (optionalFilter) {
      filters = skipEmptyStringObject({
        ...filters,
        ...optionalFilter,
      });
    }
    if (!filters.sort) {
      filters = {
        ...filters,
        sort: 'companyName,asc',
      };
    }
    const res = await axios.get(`${urls.masterShipperList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListShipperCategory = async (filters) => {
  try {
    const res = await axios.get(`${urls.categoryShipperList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListShipperType = async (filters) => {
  try {
    const res = await axios.get(`${urls.typeShipperList}?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchDetailCustomer = async (companyId) => {
  try {
    const res = await axios.get(`${urls.detailMasterShipper}/${companyId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchInformationCooperation = async (mouId) => {
  try {
    const res = await axios.get(`${urls.fetchDetailMasterShipper}/${mouId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchCustomerHistory = async (companyId, page, size) => {
  try {
    const filter = {
      page, size,
    };
    const res = await axios.get(`${urls.customerHistory}/${companyId}/filter`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchAdminDetail = async (companyId) => {
  try {
    const res = await axios.get(`${urls.fetchAdminDetail}/${companyId}/admin`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const fetchDataLocation = async (companyId) => {
  try {
    const res = await axios.get(`${urls.fetchDetailMasterShipper}/${companyId}/location`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
const fetchDataTransporter = async (companyId) => {
  try {
    const res = await axios.get(`${urls.fetchDetailMasterShipper}/${companyId}/transport-type`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchFixedPrice = async (companyId) => {
  try {
    const res = await axios.get(`${urls.fetchDetailMasterShipper}/${companyId}/transport-type/limit`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchSetPrice = async (companyId) => {
  try {
    const res = await axios.get(`${urls.fetchDetailMasterShipper}/${companyId}/route/LCL`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchSetPriceBackhauling = async (companyId) => {
  try {
    const res = await axios.get(`${urls.fetchDetailMasterShipper}/${companyId}/route/FCL_BACKHAULING`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchSetPriceFCL = async (companyId) => {
  try {
    const res = await axios.get(`${urls.fetchDetailMasterShipper}/${companyId}/route/FCL`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateMasterShipper = async (companyId, name) => {
  try {
    const res = await axios.patch(`${urls.detailMasterShipper}/${companyId}?abbreviation=${name}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const generateOpenAPI = async (companyId) => {
  try {
    const res = await axios.patch(`${urls.openApi}/${companyId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateStatusApi = async (companyId, status) => {
  try {
    const res = await axios.patch(`${urls.masterShipper}/${companyId}/openapi?isOpenApi=${status}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateOracleIntegration = async ({ companyId, form }) => {
  try {
    const res = await axios.patch(`${urls.masterShipper}/${companyId}/oracle-integration`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateShipperCompanyInformation = async ({ companyId, form }) => {
  try {
    const res = await axios.patch(`${urls.masterShipper}/${companyId}/detail`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateEmailMasterShipper = async (userId, email) => {
  try {
    const res = await axios.patch(`${urls.fetchAdminDetail}/${userId}/admin?email=${email}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateStatusCompany = async (companyId, status) => {
  try {
    const res = await axios.patch(`${urls.detailMasterShipper}/${companyId}/status?status=${status}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateSettingOracle = async (companyId, form) => {
  try {
    const res = await axios.patch(`${urls.detailMasterShipper}/${companyId}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateInvoice = async (companyId, isInvoiceGrouped) => {
  try {
    const res = await axios.patch(`${urls.detailMasterShipper}/${companyId}/invoice-grouped?isInvoiceGrouped=${isInvoiceGrouped}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getListMasterShipper,
  getListShipperCategory,
  getListShipperType,
  fetchDetailCustomer,
  fetchCustomerHistory,
  fetchAdminDetail,
  fetchInformationCooperation,
  fetchDataLocation,
  fetchDataTransporter,
  fetchFixedPrice,
  fetchSetPrice,
  fetchSetPriceBackhauling,
  fetchSetPriceFCL,
  updateMasterShipper,
  generateOpenAPI,
  updateStatusApi,
  updateEmailMasterShipper,
  updateStatusCompany,
  updateSettingOracle,
  updateOracleIntegration,
  updateShipperCompanyInformation,
  updateInvoice,
};
