import axios from 'axios';
import { querystring } from '../../helper/commonHelpers';
import urls from './urls';

const fetchMenu = async ({ filter, appType }) => {
  try {
    const res = await axios.get(`${urls.manageMenu}/filter/${appType}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateMenu = async ({ form, menuId }) => {
  try {
    const res = await axios.put(`${urls.manageMenu}/${menuId}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchMenu,
  updateMenu,
};
