export default {
  SEARCHNO: 'Cari Nomor Invoice',
  TITLE_PAGE: 'Invoice Rekanan 3PL',
  INVOICE_SHIPPER_CANCEL: 'Invoice Customer Cancel',
  ORDER_PERIOD: 'Periode Pesanan',
  TOTAL_BILL: 'Total Tagihan',
  TYPE: 'Tipe',
  ADD_ORDER: 'Tambah Pesanan',
  TITLE_PAGE_SHIPPER: 'Invoice Customer',
  TITLE_CREATE: 'Buat Invoice',
  TITLE_EDIT: 'Perbarui Invoice',
  ADD_TRANSPORT: 'Kendaraan',
  CSV_UPLOAD: 'Upload CSV',
  CSV_TEMP: 'Template CSV',
  STATUS_UPLOAD: 'Status data yang sudah diupload',
  INVOICE_NAME: 'Invoice',
  INVOICE_NO: 'Nomor Invoice',
  ORDER_NO: 'No Pesanan',
  CARGO_NUMBER: 'No Kargo',
  TRANSPORTER_NAME: 'Nama Rekanan 3PL',
  SHIPPER_NAME: 'Nama Shipper',
  CUSTOMER_NAME: 'Nama Customer',
  ORDER_PERIODE: 'Periode Pesanan',
  INVOICE_TOTAL: 'Total Tagihan',
  INVOICE_TYPE: 'Tipe',
  CREATED_BY: 'Dibuat Oleh',
  STATUS: 'Status',
  ACTION: 'Aksi',
  FILTER_STATUS: 'Semua Status',
  FILTER_TYPE: 'Semua Tipe',
  FILTER_3PL: 'Semua Transporter',
  FILTER_SHIPPER: 'Semua Shipper',
  SUCCESS_MESSAGE_TEXT: 'Data berhasil dibuat',
  EDIT_MESSAGE_TEXT: 'Data berhasil diubah',
  KOSONG: '.',
  REKANAN_3PL: 'Rekanan 3PL',
  SHIPPER: 'Shipper',
  TRANSPORTER: 'Transporter',
  DATE: 'Tanggal',
  ADD_TEXT: 'Tambah',
  ORDER_LIST: 'Daftar Pesanan',
  PRICE: 'Harga',
  INVOICE_DOCUMENT: 'Kelengkapan Dokumen',
  SELECT: 'Pilih',
  SUCCESS_INVOICE_MESSAGE_TEXT: 'Invoice berhasil dibuat',
  FILTER: 'Filter Berdasarkan',
  CHOOSE_STATUS: 'Pilih Status',
  CHOOSE_TYPE: 'Pilih Tipe',
  QUANTITY: 'Quantity',
  UNIT_PRICE: 'Harga Per Unit',
  COMPANY_NAME: 'Nama Perusahaan',
  PAYMENT_PERIOD: 'Periode Pembayaran',
  PERIOD: 'Periode',
  EDIT_STATUS_MESSAGE_TEXT: 'Status invoice berhasil diubah',
  TRANSPORT_TYPE: 'Kendaraan',
  DETAIL_INVOICE: 'Detail Invoice',
  FILTER_STATUS_LIST: [
    {
      text: 'Proforma',
      value: 'PROFORMA',
    },
    {
      text: 'Sudah Ke Oracle',
      value: 'SEND_ORACLE',
    },
    {
      text: 'Belum dicetak',
      value: 'ISSUED',
    },
    {
      text: 'Sudah dicetak',
      value: 'PRINTED',
    },
    {
      text: 'Sudah dikirim',
      value: 'SENT',
    },
    {
      text: 'Sudah diterima',
      value: 'RECEIVED',
    },
    {
      text: 'Dibayar sebagian',
      value: 'HALF_PAID',
    },
    {
      text: 'Sudah dibayar',
      value: 'PAID',
    },
  ],
  FILTER_TYPE_LIST: [
    {
      text: 'Pengiriman',
      value: 'SHIPMENT',
    },
    {
      text: 'Biaya',
      value: 'COST',
    },
    {
      text: 'Biaya dikenakan pajak',
      value: 'COST_TAXED',
    },
  ],
  INVOICE_LIST: 'Daftar Invoice',
};
