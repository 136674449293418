export default {
  label: {
    PAGE_NAME: 'Pengakhiran Kontrak',
    FILTER_BASED: 'Filter Berdasarkan',
    SEARCHING_PLACEHOLDER: 'Cari Nama Shipper/Rekanan 3PL',
    CONTRACT: 'Kontrak',
    ATTACHMENT: 'Lampiran',
    ADDITIONAL_EVIDENCE: 'Bukti Pendukung',
    TYPE_OF_TERMINATION_CONTRACT: 'Jenis Pengakhiran Kontrak',
    EXPIRATION_OF_THE_PERIOD: 'Berakhirnya Jangka Waktu',
    TERMINATION_CONTRACT_BEFORE_EXPIRATION_PERIOD: 'Diakhirinya Perjanjian Sebelum Berakhirnya Jangka Waktu',
    TYPE_OF_PARTNER: 'Jenis Mitra Kerja',
    SHIPPER: 'Shipper',
    PARTNER_3PL: 'Rekanan 3PL',
    MOU_NUMBER: 'Nomor MoU',
    TERMINATION_LETTER_NUMBER: 'Nomor Surat Pengakhiran',
    TERMINATION_REASON_CONTRACT: 'Alasan Pemutusan Kontrak',
    DOWNLOAD: 'Unduh',
    UPLOAD: 'Unggah',
    UPLOAD_TERMINATION_CONTRACT: 'Unggah Pengakhiran Kontrak',
    CLOSE: 'Tutup',
    NEXT: 'Selanjutnya',
    BACK: 'Kembali',
    CONTRACT_ATTACHMENT: 'Lampiran Kontrak',
    DOCUMENT_ATTACHMENT_NAME: 'Nama Dokumen Lampiran',
    NO: 'No',
    UPLOAD_ADDITIONAL_EVIDENCE: 'Unggah Bukti Pendukung',
    EFFECTIVE_TERMINATION_CONTRACT: 'Pemutusan Kontrak',
    TIME_TERMINATION_CONTRACT: 'Berakhir pada jam 23:59 WIB',
  },
  header: {
    CONTRACT_NUMBER: 'Nomor Kontrak',
    COMPANY_NAME: 'Nama Perusahaan',
    PARTNER: 'Mitra Kerja',
    CONTRACT_DURATION: 'Durasi Kontrak',
    CREATED_BY: 'Dibuat Oleh',
    CONTRACT_STATUS: 'Status Kontrak',
    STATUS: 'Status Pengakhiran Kontrak',
    APPROVAL_STATUS: 'Status Persetujuan',
    ACTION: 'Aksi',
    NUMBER: 'Nomor',
    DOCUMENT_NAME: 'Nama Dokumen',
  },
};
