import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';
/**
 * fetch selection list
 * @param {Object} filters selection list filter
 *  @param {Number} filters.page list pagination page number
 *  @param {Number} filters.size list pagination entry count
 *  @param {String} filters.value list pagination entry count
 */
const fetchSelectionsInfos = async (filters) => {
  try {
    const res = await axios.get(`${urls.selectionInfo}/filter?${querystring.stringify(filters)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * edit or create new entry for a selection list
 * @param {Object} form Selection Item form
 *  @param {String} form.type Selection list type
 *  @param {Number} [form.id] Selection item id for updating
 *  @param {Boolean} [form.needConfirm] if value needs to be confirmed
 */
const createEditSelectionInfo = async ({
  type, id, needConfirm, isFreeText, isActive, reason,
}) => {
  try {
    let res;
    const data = {
      reason,
      type,
      needConfirm,
      isFreeText,
      isActive,
    };
    if (!id) {
      res = await axios.post(urls.selectionInfo, data);
    } else {
      res = await axios.put(`${urls.selectionDetail(id)}`, data);
    }
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchDetail = async (id) => {
  try {
    const res = await axios.get(urls.selectionDetail(id));
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const additionalAddEdit = async (form, reasonId) => {
  try {
    let res;
    if (reasonId) {
      res = await axios.put(`${urls.selectionDetail(reasonId)}`, form);
      return res.data;
    }
    res = await axios.post(`${urls.selectionInfo}`, form);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchAdditionalCost = async (reasonId) => {
  try {
    const res = await axios.get(`${urls.selectionDetail(reasonId)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const selectionInfoDownload = async ({
  name, type, needConfirm, isFreeText, isActive,
}) => {
  try {
    const filters = {};
    if (name) filters.name = `qlike(${name})`;
    if (type) filters.type = `qIn(${type})`;
    if (needConfirm) filters.needConfirm = needConfirm;
    if (isFreeText) filters.isFreeText = isFreeText;
    if (isActive) filters.isActive = isActive;
    const res = await axios.get(`${urls.selectionInfo}/export?${querystring.stringify(filters)}`, { responseType: 'blob' });
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

const listOracleCost = async () => {
  try {
    const res = await axios.get(`${urls.listOracleCost}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListOfCompanies = async (filter) => {
  try {
    if (filter.companyName) {
      filter = {
        ...filter,
        name: `qlike(${filter.companyName})`,
      };
      delete filter.companyName;
    }
    const res = await axios.get(`${urls.contractCompanyList}?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  fetchSelectionsInfos,
  createEditSelectionInfo,
  fetchDetail,
  additionalAddEdit,
  fetchAdditionalCost,
  selectionInfoDownload,
  listOracleCost,
  getListOfCompanies,
};
