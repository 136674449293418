export default {
  LIST_PAGE: 'List Biaya Oracle',
  PAGE: 'Biaya Oracle',
  COST: 'Biaya',
  STATUS: 'Status',
  CODE_COST: 'Kode Biaya',
  SEARCH_TEXT: 'Cari Biaya Oracle',
  REFRESH_DATA_ORACLE: 'Refresh Data Biaya Oracle',
  SUCCES_REFRESH_ORACLE_COST: 'Berhasil refresh data',
  FAILED_REFRESH_ORACLE_COST: 'Gagal refresh data',
  ID: 'Cust Trx Id',
  TYPE: 'Cust Trx Type',
  DESC: 'Cust Trx Description',
};
