export default {
  button: {
    SHIPPER_REGISTRATION: 'Shipper Registration',
    TRANSPORTER_REGISTRATION: 'Transporter Registration',
  },
  label: {
    DESCRIPTION: 'Klik Link berikut untuk mendaftar sebagai <b>Shipper</b> atau <b>Rekanan Transporter</b>',
    INFO: '*Anda akan diarahkan ke Web Shipper / Transporter untuk melakukan Registrasi',
  },
};
